import { IVideoMedia } from '@typesApp/cmsPlacement/Media'
import { notUndefined } from '@utils/common'
import clsx from 'clsx'
import { getMediaPictureUris, getVideoURL } from '../CmsMedia/helpers'
import { useVideo } from './hooks/useVideo'
import styles from './styles/index.module.scss'
import { FC, useEffect, useState } from 'react'

type VideoPlayer = {
  lazyload?: boolean
  shouldLoad?: boolean
  media: IVideoMedia[] | undefined
  onSetPlayingId?: (id?: string) => void
  playingId?: string
  canVideoBeClickable?: boolean
  isInView?: boolean
}

export const VideoPlayer: FC<VideoPlayer> = ({ media }) => {
  const hasPictures = Boolean(getMediaPictureUris(media ?? []).filter(notUndefined).length)
  const { video: selectedVideo } = useVideo(media)

  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    setTitle(document.title.split('|')?.[0])
  }, [])

  return (
    <div
      className={clsx('video-wrapper-sentinel', 'video-player', styles['video-player'], {
        'has-preview-picture': hasPictures,
      })}
    >
      <iframe
        className={styles['iframe-sizing']}
        frameBorder={0}
        allowFullScreen
        allowTransparency
        src={getVideoURL(selectedVideo)}
        title={title}
      />
    </div>
  )
}
