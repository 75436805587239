import { useFrameGenius } from '@hooks/useFrameGenius'
import { useToggleNewsletterDrawer } from './useToggleNewsletterDrawer'
import { toggleWunderkindOverlay } from '@components/Wunderkind/actions'

export const useExternalsActions = (): {
  [action: string]: (eventKey?: React.MouseEvent<HTMLButtonElement, MouseEvent> | string) => void
} => {
  const { openFrameAdvisor, openSizeAdvisor } = useFrameGenius()
  const { toggleNewsletterDrawer } = useToggleNewsletterDrawer()

  const mappedActions = {
    'frame-advisor': openFrameAdvisor,
    'size-advisor': openSizeAdvisor,
    'newsletter-signup-overlay': toggleNewsletterDrawer,
    'wunderkind-overlay': toggleWunderkindOverlay,
  }

  return mappedActions
}
