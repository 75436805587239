import { Typography } from '@mui/material'
import { isContactLenses } from '@utils/product'
import { getProductInfo } from '../helpers'
import config from '@configs/index'
import { TProduct } from '@utils/productNew'
import clsx from 'clsx'
import styles from '../styles/index.module.scss'
import { StyledSuggestedProductTileFooter } from '../SuggestedProductTile.style'
import ProductTilePrice from './ProductTilePrice'
import useProductPrice from '@hooks/useProductPrice'

const siteName = config.name

type TProductTileFooter = {
  product: TProduct
  clusters: TProduct[]
  priorityBadgeText: string
  mocoLink: string
  tileDataElementId: string
}

export const ProductTileFooter: React.FC<TProductTileFooter> = ({
  priorityBadgeText,
  tileDataElementId,
  product,
  clusters,
}) => {
  const { productType, name, brand, modelCode } = getProductInfo(product)

  const clusterLength = clusters.length ?? 0

  // todo confirm if this is required (clusters with CL products)
  // * prices (from x_price) via hclDTO conversion
  const prices = isContactLenses(productType)
    ? (clusterLength > 0 && clusters[0].prices) || product?.prices
    : product?.prices

  const { currentPrice } = useProductPrice(prices) || {}

  return (
    <StyledSuggestedProductTileFooter
      data-element-id={tileDataElementId}
      data-description={`${siteName}_${name}_${modelCode}`}
    >
      <div
        className={clsx(styles['product-tile-description'], 'product-tile-description', {
          'contact-lenses': productType === 'contact-lenses',
        })}
      >
        <Typography variant="button">{name}</Typography>
      </div>
      {!isContactLenses(productType) && <div className={clsx(styles['product-tile-brand-name'])}>{brand}</div>}

      <ProductTilePrice price={currentPrice} productType={productType} />
    </StyledSuggestedProductTileFooter>
  )
}
