import { IVideoMedia } from '@typesApp/cmsPlacement/Media'
import qs from 'qs'
import queryString from 'query-string'
import config from '../configs'
import { cmsImageCrops, cmsImageCropsImproved } from '../constants/ui'
import { STORE_URL_MAP } from '@constants/common'
import { replaceLocaleInUrl } from './locale'

type IResponsiveImage = {
  crop: string
  width: number
  height?: number
  sizes?: { density: '1x' | '2x'; width: number; height: number }[]
}

export const formatPathWithBase = (path: string, basePath: string): string => {
  return basePath + (path.startsWith('/') ? path : `/${path}`)
}

/**
 * Update URL query string values
 * @returns {string}
 */
export const updateUrlParameter = (
  url: string,
  key: string,
  value: number | boolean | string | undefined | null
): string => {
  const [baseurl, search] = url.split('?')
  const querystring = qs.parse(search)
  querystring[key] = value == null ? '' : value.toString()

  return `${baseurl}?${qs.stringify(querystring)}`
}

export const replaceUrl = url => {
  window.history.replaceState({}, '', url)
}

export const formattedParams = params => {
  return queryString.stringifyUrl(
    {
      url: '',
      query: params,
    },
    {
      encode: false,
    }
  )
}

export const fromUrlParamsToObject = (url: string, decode = false) => {
  return queryString.parse(url, { decode })
}

export const getImageFromCMS = (
  hostnameUrls: Record<string, string>,
  url: string,
  crop?: string,
  width?: number | string
) => {
  const imageHostname = hostnameUrls.cmsImageServerUrl || config.cmsImageServerUrl

  if (!url) {
    return ''
  }
  if (crop) {
    const rep = url.replace('/{cropName}/{width}', `/${crop || 'NOTFOUND'}/${width || 'NOTFOUND'}`)
    return `${imageHostname}${rep}`
  }
  return undefined
}

export const getSrcSetFromCMS = (
  hostnameUrls: Record<string, string>,
  url: string,
  responsiveImage: IResponsiveImage
): string => {
  const imageHostname = hostnameUrls.cmsImageServerUrl ?? config.cmsImageServerUrl
  if (!url || !responsiveImage) {
    return ''
  }

  const { crop, sizes } = responsiveImage

  return (sizes ?? []).reduce((acc, size, index) => {
    const rep = url.replace('/{cropName}/{width}', `/${crop || 'NOTFOUND'}/${size.width || 'NOTFOUND'}`)
    const src = `${imageHostname}${rep} ${size.density}`
    return index > 0 ? `${acc}, ${src}` : src
  }, '')
}

export const getCmsImageCrops = (type: string) => {
  const bannerCropOption = cmsImageCrops[type]
  const bannerCropOptionImproved = cmsImageCropsImproved[type]
  const { isCmsRefactoredContentEnabled } = config
  const { mobile, deskL, deskS, tabletL, tabletP } =
    (isCmsRefactoredContentEnabled ? bannerCropOptionImproved : bannerCropOption) ?? {}
  return {
    mobile,
    deskL,
    deskS,
    tabletL,
    tabletP,
  }
}

export const getSrcSetsImageFromCms = (type: string, url: string, hostnameUrls: Record<string, string>) => {
  const bannerCropOption = cmsImageCrops[type]
  const bannerCropOptionImproved = cmsImageCropsImproved[type]
  const { isCmsRefactoredContentEnabled } = config

  const { desk, mobile, tablet, deskL, deskS, tabletL, tabletP, xxl, xl, lg, md, sm, xs } =
    (isCmsRefactoredContentEnabled ? bannerCropOptionImproved : bannerCropOption) ?? {}

  return {
    mobile: getImageFromCMS(hostnameUrls, url, mobile?.crop || '', mobile?.width),
    deskL: getImageFromCMS(hostnameUrls, url, deskL?.crop || '', deskL?.width),
    deskS: getImageFromCMS(hostnameUrls, url, deskS?.crop || '', deskS?.width),
    tabletL: getImageFromCMS(hostnameUrls, url, tabletL?.crop || '', tabletL?.width),
    tabletP: getImageFromCMS(hostnameUrls, url, tabletP?.crop || '', tabletP?.width),
    desk: getImageFromCMS(hostnameUrls, url, desk?.crop || '', desk?.width),
    tablet: getImageFromCMS(hostnameUrls, url, tablet?.crop || '', tablet?.width),

    ...(xxl ? { xxl: getSrcSetFromCMS(hostnameUrls, url, xxl) } : undefined),
    ...(xl ? { xl: getSrcSetFromCMS(hostnameUrls, url, xl) } : undefined),
    ...(lg ? { lg: getSrcSetFromCMS(hostnameUrls, url, lg) } : undefined),
    ...(md ? { md: getSrcSetFromCMS(hostnameUrls, url, md) } : undefined),
    ...(sm ? { sm: getSrcSetFromCMS(hostnameUrls, url, sm) } : undefined),
    ...(xs ? { xs: getSrcSetFromCMS(hostnameUrls, url, xs) } : undefined),
    ...(xs ? { src: getImageFromCMS(hostnameUrls, url, xs?.crop || '', xs?.width) } : undefined),
  }
}

export const getVideoFromCMS = (video: IVideoMedia): string => {
  const url = video.data?.uri || video.dataUrl
  const uri = url.startsWith('/') ? url : `/${url}`
  return `${config.cmsImageServerUrl}${uri}`
}

/**
 * removeStoreSegment remove the store segment of the url
 * retrieved from CMS
 * @param  url the url to parse
 *
 */
export const removeStoreSegment = url => {
  return url
    .split('/')
    .filter(item => item.length > 0)
    .join('/')
    .replace('//', '/')
}

export const seoUrlConcat = (langCode: string, seoHref: string, absoluteUrl = true) => {
  let url = !langCode.startsWith('/') && absoluteUrl ? `/${langCode}` : langCode
  let divider = '/'

  if (url && url.endsWith('/')) {
    url = url.slice(0, -1)
  }

  if (seoHref && seoHref.startsWith('/')) {
    divider = ''
  }

  return [url, seoHref].join(divider)
}

export const getDomainFromHost = (hostname: string): string => {
  const parts = hostname.split('.')
  if (parts.length <= 2) {
    return hostname
  }
  return parts
    .slice(-3)
    .join('.')
    .match(/\.co\.uk$/)
    ? parts.slice(-3).join('.')
    : parts.slice(-2).join('.')
}

export const replaceSEODomain = ({
  currentDomain,
  lang,
  storeName,
}: {
  currentDomain: string
  lang: string
  storeName: string
}) => {
  const domainRegex = new RegExp(`/${storeName}(.+)$/`)
  const replacement = STORE_URL_MAP[lang]

  return domainRegex.exec(currentDomain)
    ? currentDomain.replace(domainRegex, `${storeName}${replacement}`)
    : currentDomain
}

//! temporary work around , will be removed once we have the correct url from the backend
export const createRedirectUrl = (
  locale: string,
  isLocaleDomain: boolean,
  locationOrigin: string,
  path: string | undefined,
  fragment?: string
) => {
  if (!path) {
    return ''
  }
  const parts = path.split('/')
  parts[1] = locale
  let newPath = parts.join('/')

  if (fragment) {
    newPath = `${newPath}#${fragment}`
  }

  if (!isLocaleDomain) {
    newPath = `${locationOrigin}${newPath}`
  }

  return replaceLocaleInUrl({
    href: newPath,
    locale,
    isLocaleDomain,
    locationOrigin,
    isRelative: false,
  })
}

export const getUrlPathNoLangCode = (url?: string) => {
  const path = url ?? window.location.pathname
  return path.split('/').slice(2)?.join('/')
}
