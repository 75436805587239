import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled'
import { css, styled } from '@mui/material/styles'
import React from 'react'
import { StyledLoader } from '../../Loader'

export interface GenericButtonProps {
  theme?: any
  labelText?: string
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  fillType?: 'fill' | 'primary' | undefined
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outlined'
  fullWidth?: boolean
  size?: 'medium' | 'big'
  loading?: boolean
}

export type ButtonProps = GenericButtonProps & ButtonUnstyledProps

export const variantMap = (
  theme: ButtonProps['theme'],
  variant: ButtonProps['variant'] = 'primary',
  fillType: ButtonProps['fillType'] = 'fill'
) => {
  const primaryFill = {
    background: theme.palette.primary.main,
    hoverBackground: theme.palette.primary.tones[50],
    color: theme.palette.common.white,
    colorHover: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    borderColorhover: theme.palette.primary.tones[50],
  }
  const secondaryFill = {
    background: theme.palette.success.main,
    hoverBackground: theme.palette.success.tones[70],
    color: theme.palette.common.white,
    colorHover: theme.palette.common.white,
    borderColor: theme.palette.success.main,
    borderColorhover: theme.palette.success.main,
  }
  const tertiaryFill = {
    background: theme.palette.background.default,
    hoverBackground: theme.palette.background.secondary,
    color: theme.palette.primary.main,
    colorHover: theme.palette.secondary,
    borderColor: theme.palette.background.default,
    borderColorhover: theme.palette.background.neutral,
  }
  const primaryOutline = {
    background: 'transparent',
    hoverBackground: theme.palette.primary.tones[95],
    color: theme.palette.primary.main,
    colorHover: theme.palette.primary.main,
    borderColor: theme.palette.neutral.tones[60],
    borderColorhover: theme.palette.neutral.tones[60],
  }
  const secondaryOutline = {
    background: 'transparent',
    hoverBackground: theme.palette.success.tones[99],
    color: theme.palette.success.tones[40],
    colorHover: theme.palette.success.tones[40],
    borderColor: theme.palette.neutral.tones[60],
    borderColorhover: theme.palette.neutral.tones[60],
  }

  return {
    fill: {
      primary: primaryFill,
      secondary: secondaryFill,
      tertiary: tertiaryFill,
    },
    primary: {
      outlined: primaryOutline,
      secondary: secondaryOutline,
    },
  }[fillType][variant]
}

export const cssButton = ({ theme, variant, fillType, fullWidth }: ButtonProps) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    appearance: 'none',
    fontFamily: 'inherit',
    fontWeight: 600,
    fontSize: 14,
    padding: theme.spacing(4, 8),
    width: '100%',
    borderRadius: 8,
    lineHeight: 1.43,
    cursor: 'pointer',
    textDecoration: 'none',
    letterSpacing: 'normal',
    color: variantMap(theme, variant, fillType)?.color,
    background: variantMap(theme, variant, fillType)?.background,
    border: `1px solid ${variantMap(theme, variant, fillType)?.borderColor}`,

    '&:hover': {
      background: variantMap(theme, variant, fillType)?.hoverBackground || 'inherit',
      border: `1px solid ${variantMap(theme, variant, fillType)?.borderColorhover || 'inherit'}`,
    },

    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },

    svg: {
      rect: { fill: variantMap(theme, variant, fillType)?.color || 'inherit' },
    },

    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      width: fullWidth ? '100%' : 'auto',
    },
  })

/**
 * @deprecated Use new version instead: src\components\common\UI\Button\index.tsx
 */
export const Button = styled(
  ({ labelText, startIcon, endIcon, children, loading, disabled, ...props }: ButtonProps) => (
    <ButtonUnstyled {...props} disabled={loading || disabled}>
      {loading ? <StyledLoader size={16} /> : startIcon}
      {labelText || children}
      {endIcon}
    </ButtonUnstyled>
  ),
  {
    shouldForwardProp: prop => prop !== 'fillType' && prop !== 'fullWidth',
  }
)(cssButton)
