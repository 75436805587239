import { RootReducerState } from '@redux/reducers'
import { createSelector } from '@reduxjs/toolkit'

const seoSelector = (state: RootReducerState) => {
  return state.seo
}

export const seoDataSelector = (state: RootReducerState) => {
  return state.seo.seoData
}

const currentPageSeoDataSelector = () => createSelector(seoDataSelector, result => result)

export { seoSelector, currentPageSeoDataSelector }
