import styled from '@mui/material/styles/styled'
import { ITeaserBackGround } from '@typesApp/cmsPlacement/LXTeaser'
import { cmsModuleLateralMargin, cmsModuleVerticalMargin, teaserBackGroundColorToBg } from '@utils/placements'

export const StyledPlacementContainer = styled('div', {
  name: 'PlacementContainer',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'marginLateral' && prop !== 'marginVertical',
})<{
  bgcolor: React.CSSProperties['backgroundColor'] | ITeaserBackGround
  marginVertical: string
  marginLateral: boolean
}>(({ bgcolor, theme, marginLateral, marginVertical }) => ({
  //display: 'flex',
  // for terms and condition panel
  position: 'relative',
  ...teaserBackGroundColorToBg(bgcolor, theme),
  ...cmsModuleVerticalMargin(marginVertical, theme),
  ...cmsModuleLateralMargin(marginLateral, theme),

  padding: bgcolor ? theme.spacing(8) : theme.spacing(10, 0),

  [theme.breakpoints.up('md')]: {
    padding: bgcolor ? theme.spacing(12) : theme.spacing(10, 0),
  },
}))
