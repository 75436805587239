import { Theme } from '@mui/material'
import { CmsImageCrops, CtaFill, CtaStyle, CtaBG } from '@typesApp/common'
import config from '@configs/index'
import { CmsImageCropsImproved } from '@components/Cms/CMSContent/components/CmsMedia/Picture'

export const APP_MAIN_CONTAINER_CLASSNAME = `${config.name}-app__main-container`
export const APP_MAIN_CONTAINER_ID = `${config.name}-app__main`
export const PROMOBAR_WRAPPER_CLASSNAME = 'promobar-animation-wrapper'
export const KEYSELLINGBAR_WRAPPER_CLASSNAME = 'keyselling-bar-wrapper'

export const XS_MOBILE_BREAKPOINT = 375
export const MODAL_INFO_ARTICLE_CROP_TYPE = 'MODAL_INFO_ARTICLE'

export const ctaStylesMap: CtaStyle = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
}

export const ctaFillMap: CtaFill = {
  fill: 'fill',
  outline: 'outline',
}

export const cmsImageCrops: CmsImageCrops = {
  FULL_WIDTH_BANNER: {
    deskL: {
      crop: 'landscape_ratio64x29',
      width: 2880,
      height: 1304,
    },
    deskS: {
      crop: 'landscape_ratio64x29',
      width: 2560,
      height: 1304,
    },
    tabletL: {
      crop: 'landscape_ratio64x29',
      width: 1843,
      height: 1304,
    },
    tabletP: {
      crop: 'landscape_ratio96x65',
      width: 1382,
      height: 936,
    },
    mobile: {
      crop: 'portrait_ratio75x104',
      width: 675,
      height: 936,
    },
  },
  LANDSCAPE_BANNER: {
    deskL: {
      crop: 'landscape_ratio40x13',
      width: 2880,
      height: 936,
    },
    deskS: {
      crop: 'landscape_ratio40x13',
      width: 2560,
      height: 936,
    },
    tabletL: {
      crop: 'landscape_ratio32x13',
      width: 1843,
      height: 749,
    },
    tabletP: {
      crop: 'landscape_ratio192x71',
      width: 1382,
      height: 511,
    },
    mobile: {
      crop: 'landscape_ratio375x284',
      width: 675,
      height: 511,
    },
  },
  TOP_PAGE_BANNER: {
    deskL: {
      crop: 'landscape_ratio9x2',
      width: 2880,
      height: 640,
    },
    deskS: {
      crop: 'landscape_ratio320x71',
      width: 2560,
      height: 568,
    },
    tabletL: {
      crop: 'landscape_ratio64x29',
      width: 1843,
      height: 1304,
    },
    tabletP: {
      crop: 'landscape_ratio192x71',
      width: 1382,
      height: 511,
    },
    mobile: {
      crop: 'landscape_ratio374x284',
      width: 675,
      height: 511,
    },
  },
  SQUAT_BANNER: {
    deskL: {
      crop: 'landscape_ratio6x1',
      width: 2880,
      height: 480,
    },
    deskS: {
      crop: 'landscape_ratio16x3',
      width: 2560,
      height: 480,
    },
    tabletP: {
      crop: 'landscape_ratio64x15',
      width: 1843,
      height: 432,
    },
    tabletL: {
      crop: 'landscape_ratio16x5',
      width: 1382,
      height: 432,
    },
    mobile: {
      crop: 'portrait_ratio125x126',
      width: 675,
      height: 680,
    },
  },
  BOX_WITH_MARGIN_BANNER: {
    deskL: {
      crop: 'landscape_ratio1x1',
      width: 1376,
      height: 1376,
    },
    deskS: {
      crop: 'landscape_ratio1x1',
      width: 1376,
      height: 1376,
    },
    tabletL: {
      crop: 'landscape_ratio1x1',
      width: 749,
      height: 1376,
    },
    tabletP: {
      crop: 'landscape_ratio176x163',
      width: 1267,
      height: 1174,
    },
    mobile: {
      crop: 'landscape_ratio1x1',
      width: 617,
      height: 1376,
    },
  },
  BOARD_WITH_FIELDS_2_ITEMS: {
    deskL: {
      crop: 'landscape_ratio326x181',
      width: 1304,
      height: 724,
    },
    deskS: {
      crop: 'landscape_ratio286x181',
      width: 1144,
      height: 724,
    },
    tabletL: {
      crop: 'landscape_ratio238x181',
      width: 857,
      height: 652,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    mobile: {
      crop: 'portrait_ratio343x362',
      width: 617,
      height: 652,
    },
  },
  BOARD_WITH_FIELDS_3_ITEMS: {
    deskL: {
      crop: 'landscape_ratio216x181',
      width: 864,
      height: 724,
    },
    deskS: {
      crop: 'landscape_ratio379x362',
      width: 758,
      height: 724,
    },
    tabletL: {
      crop: 'portrait_ratio315x362',
      width: 567,
      height: 652,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
  },
  BOARD_WITH_FIELDS_4_ITEMS: {
    deskL: {
      crop: 'landscape_ratio200x181',
      width: 800,
      height: 724,
    },
    deskS: {
      crop: 'landscape_ratio200x181',
      width: 800,
      height: 724,
    },
    tabletL: {
      crop: 'landscape_ratio204x181',
      width: 734,
      height: 652,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
  },
  SQUARE_BOARDS_WITH_SPLIT: {
    deskL: {
      crop: 'landscape_ratio1x1',
      width: 1280,
      height: 1280,
    },
    deskS: {
      crop: 'landscape_ratio1x1',
      width: 1120,
      height: 1120,
    },
    tabletL: {
      crop: 'landscape_ratio1x1',
      width: 835,
      height: 835,
    },
    tabletP: {
      crop: 'landscape_ratio1x1',
      width: 605,
      height: 605,
    },
    mobile: {
      crop: 'landscape_ratio1x1',
      width: 675,
      height: 675,
    },
  },
  SQUARE_BOARDS_WITHOUT_SPLIT: {
    deskL: {
      crop: 'landscape_ratio1x1',
      width: 1440,
      height: 1376,
    },
    deskS: {
      crop: 'landscape_ratio1x1',
      width: 1280,
      height: 1376,
    },
    tabletL: {
      crop: 'landscape_ratio1x1',
      width: 922,
      height: 1376,
    },
    tabletP: {
      crop: 'landscape_ratio1x1',
      width: 691,
      height: 1376,
    },
    mobile: {
      crop: 'landscape_ratio1x1',
      width: 675,
      height: 1376,
    },
  },
  COMBO_MINI_SLIDER_SMALL: {
    deskL: {
      crop: 'portrait_ratio18x19',
      width: 720,
      height: 760,
    },
    deskS: {
      crop: 'portrait_ratio18x19',
      width: 720,
      height: 760,
    },
    tabletL: {
      crop: 'portrait_ratio187x197',
      width: 337,
      height: 355,
    },
    tabletP: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
    mobile: {
      crop: 'landscape_ratio1x1',
      width: 675,
      height: 1376,
    },
  },
  COMBO_MINI_SLIDER_BIG: {
    deskL: {
      crop: 'portrait_ratio43x44',
      width: 1376,
      height: 1408,
    },
    deskS: {
      crop: 'landscape_ratio1x1',
      width: 1216,
      height: 1376,
    },
    tabletL: {
      crop: 'portrait_ratio56x61',
      width: 806,
    },
    tabletP: {
      crop: 'landscape_ratio128x127',
      width: 1382,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
  },
  PLP_ONE_TILE: {
    deskL: {
      crop: 'landscape_ratio863x416',
      width: 1726,
      height: 832,
    },
    deskS: {
      crop: 'landscape_ratio755x361',
      width: 1510,
      height: 722,
    },
    tabletL: {
      crop: 'landscape_ratio317x149',
      width: 1141,
      height: 536,
    },
    tabletP: {
      crop: 'portrait_ratio343x359',
      width: 617,
      height: 646,
    },
    mobile: {
      crop: 'portrait_ratio343x345',
      width: 617,
      height: 621,
    },
  },
  PLP_TWO_TILES: {
    deskL: {
      crop: 'landscape_ratio863x416',
      width: 1726,
      height: 832,
    },
    deskS: {
      crop: 'landscape_ratio755x361',
      width: 1510,
      height: 722,
    },
    tabletL: {
      crop: 'landscape_ratio317x149',
      width: 1141,
      height: 536,
    },
    tabletP: {
      crop: 'portrait_ratio343x359',
      width: 617,
      height: 646,
    },
    mobile: {
      crop: 'portrait_ratio343x345',
      width: 617,
      height: 621,
    },
  },
  CART_PROMO_BANNER: {
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  AVATAR_MEGA_MENU: {
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 96,
      height: 740,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 96,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 96,
      height: 740,
    },
  },
  BIG_MENU_BANNER: {
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 1296,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  QUERY_LIST: {
    deskL: {
      crop: 'portrait_ratio1x1',
      width: 832,
      height: 740,
    },
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 416,
      height: 740,
    },
    tabletL: {
      crop: 'portrait_ratio1x1',
      width: 416,
      height: 740,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 416,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio11x12',
      width: 327,
      height: 356,
    },
  },
  CMS_CTA_MODAL: {
    deskL: {
      crop: 'landscape_ratio40x13',
      width: 2880,
      height: 936,
    },
    deskS: {
      crop: 'landscape_ratio40x13',
      width: 2560,
      height: 936,
    },
    tabletL: {
      crop: 'landscape_ratio32x13',
      width: 1843,
      height: 749,
    },
    tabletP: {
      crop: 'landscape_ratio192x71',
      width: 1382,
      height: 511,
    },
    mobile: {
      crop: 'landscape_ratio192x71',
      width: 1382,
      height: 511,
    },
  },
}

export const cmsImageCropsImproved: CmsImageCropsImproved = {
  FULL_WIDTH_BANNER_DUAL: {
    deskL: {
      crop: 'landscape_ratio48x35',
      height: 1120,
      width: 1536,
    },
    deskS: {
      crop: 'landscape_ratio48x35',
      width: 1280,
      height: 933,
    },
    tabletL: {
      crop: 'landscape_ratio48x35',
      width: 1280,
      height: 933,
    },
    tabletP: {
      crop: 'landscape_ratio48x35',
      width: 1280,
      height: 933,
    },
    mobile: {
      crop: 'landscape_ratio35x18',
      width: 572,
      height: 294,
    },
  },
  BOX_WITH_MARGIN_BANNER: {
    deskL: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    tabletL: {
      crop: 'portrait_ratio1x1',
      width: 1296,
      height: 1296,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio17x18',
      width: 343,
      height: 363,
    },
  },
  MODAL_INFO_ARTICLE: {
    deskL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    deskS: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletL: {
      crop: 'landscape_ratio16x7',
      width: 1024,
      height: 448,
    },
    tabletP: {
      crop: 'landscape_ratio33x18',
      width: 764,
      height: 417,
    },
    mobile: {
      crop: 'landscape_ratio48x35',
      width: 576,
      height: 420,
    },
  },
  FULL_WIDTH_BANNER_L: {
    xxl: {
      crop: 'landscape_ratio2x1',
      width: 2048,
      height: 1024,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 1024,
        },
        {
          density: '2x',
          width: 3840,
          height: 1920,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio2x1',
      width: 1536,
      height: 768,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 768,
        },
        {
          density: '2x',
          width: 2880,
          height: 1440,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 560,
        },
        {
          density: '2x',
          width: 2560,
          height: 1120,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio33x18',
      width: 1024,
      height: 559,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 559,
        },
        {
          density: '2x',
          width: 2048,
          height: 1117,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1280,
          height: 1486,
        },
      ],
    },
  },
  LANDSCAPE_BANNER_M: {
    xxl: {
      crop: 'landscape_ratio32x13',
      width: 2048,
      height: 832,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 832,
        },
        {
          density: '2x',
          width: 3840,
          height: 1560,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio32x13',
      width: 1536,
      height: 624,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 624,
        },
        {
          density: '2x',
          width: 2880,
          height: 1170,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 560,
        },
        {
          density: '2x',
          width: 2560,
          height: 1260,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio2x1',
      width: 1024,
      height: 512,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 512,
        },
        {
          density: '2x',
          width: 2048,
          height: 1024,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1280,
          height: 1486,
        },
      ],
    },
    deskL: {
      crop: 'landscape_ratio32x13',
      width: 1280,
      height: 504,
    },
    deskS: {
      crop: 'landscape_ratio32x13',
      width: 1280,
      height: 504,
    },
    tabletL: {
      crop: 'landscape_ratio16x7',
      width: 1024,
      height: 448,
    },
    tabletP: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    mobile: {
      crop: 'portrait_ratio31x36',
      width: 468,
      height: 543,
    },
  },
  TOP_PAGE_BANNER_S: {
    xxl: {
      crop: 'landscape_ratio32x13',
      width: 2048,
      height: 832,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 832,
        },
        {
          density: '2x',
          width: 3840,
          height: 1560,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio32x13',
      width: 1536,
      height: 624,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 624,
        },
        {
          density: '2x',
          width: 2880,
          height: 1170,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 560,
        },
        {
          density: '2x',
          width: 2592,
          height: 1134,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio2x1',
      width: 1024,
      height: 512,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 512,
        },
        {
          density: '2x',
          width: 2048,
          height: 1024,
        },
      ],
    },
    sm: {
      crop: 'landscape_ratio35x18',
      width: 768,
      height: 395,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 395,
        },
        {
          density: '2x',
          width: 1536,
          height: 790,
        },
      ],
    },
    xs: {
      crop: 'landscape_ratio35x18',
      width: 576,
      height: 296,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 296,
        },
        {
          density: '2x',
          width: 1280,
          height: 658,
        },
      ],
    },
  },
  SQUAT_BANNER_XS: {
    xxl: {
      crop: 'landscape_ratio32x13',
      width: 2048,
      height: 832,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 832,
        },
        {
          density: '2x',
          width: 3840,
          height: 1560,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio32x13',
      width: 1536,
      height: 624,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 624,
        },
        {
          density: '2x',
          width: 2880,
          height: 1170,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 560,
        },
        {
          density: '2x',
          width: 2592,
          height: 1134,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio2x1',
      width: 1024,
      height: 512,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 512,
        },
        {
          density: '2x',
          width: 2048,
          height: 1024,
        },
      ],
    },
    sm: {
      crop: 'landscape_ratio35x18',
      width: 768,
      height: 395,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 395,
        },
        {
          density: '2x',
          width: 1536,
          height: 790,
        },
      ],
    },
    xs: {
      crop: 'landscape_ratio35x18',
      width: 576,
      height: 296,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 296,
        },
        {
          density: '2x',
          width: 1280,
          height: 658,
        },
      ],
    },
  },
  FULL_WIDTH_BANNER_PROMO_L: {
    deskL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    deskS: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletL: {
      crop: 'landscape_ratio16x7',
      width: 1024,
      height: 448,
    },
    tabletP: {
      crop: 'portrait_ratio31x36',
      width: 468,
      height: 543,
    },
    mobile: {
      crop: 'portrait_ratio31x36',
      width: 468,
      height: 543,
    },
  },
  LANDSCAPE_BANNER_PROMO_M: {
    xxl: {
      crop: 'landscape_ratio16x7',
      width: 2048,
      height: 896,
      sizes: [
        {
          density: '1x',
          width: 2048,
          height: 896,
        },
        {
          density: '2x',
          width: 3840,
          height: 1680,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio16x7',
      width: 1536,
      height: 672,
      sizes: [
        {
          density: '1x',
          width: 1536,
          height: 672,
        },
        {
          density: '2x',
          width: 2880,
          height: 1260,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio2x1',
      width: 1280,
      height: 640,
      sizes: [
        {
          density: '1x',
          width: 1280,
          height: 640,
        },
        {
          density: '2x',
          width: 2592,
          height: 1280,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio2x1',
      width: 1024,
      height: 512,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 512,
        },
        {
          density: '2x',
          width: 2048,
          height: 1024,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1280,
          height: 1486,
        },
      ],
    },
  },
  TOP_PAGE_BANNER_PROMO_S: {
    deskL: {
      crop: 'landscape_ratio16x7',
      width: 1980,
      height: 866,
    },
    deskS: {
      crop: 'landscape_ratio16x7',
      width: 2592,
      height: 1134,
    },
    tabletL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletP: {
      crop: 'landscape_ratio16x5',
      width: 1536,
      height: 480,
    },
    mobile: {
      crop: 'landscape_ratio16x5',
      width: 768,
      height: 240,
    },
  },
  G1_W_2_ITEMS: {
    xxl: {
      crop: 'landscape_ratio33x18',
      width: 1024,
      height: 559,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 559,
        },
        {
          density: '2x',
          width: 2048,
          height: 1117,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio33x18',
      width: 1024,
      height: 559,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 559,
        },
        {
          density: '2x',
          width: 2048,
          height: 1117,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio33x18',
      width: 768,
      height: 419,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 419,
        },
        {
          density: '2x',
          width: 1536,
          height: 838,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio33x18',
      width: 768,
      height: 419,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 419,
        },
        {
          density: '2x',
          width: 1536,
          height: 838,
        },
      ],
    },
    sm: {
      crop: 'landscape_ratio33x18',
      width: 576,
      height: 314,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 314,
        },
        {
          density: '2x',
          width: 1280,
          height: 698,
        },
      ],
    },
    xs: {
      crop: 'landscape_ratio33x18',
      width: 576,
      height: 314,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 314,
        },
        {
          density: '2x',
          width: 1280,
          height: 698,
        },
      ],
    },
  },
  G1_W_3_ITEMS: {
    xxl: {
      crop: 'landscape_ratio48x35',
      width: 768,
      height: 560,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 560,
        },
        {
          density: '2x',
          width: 1536,
          height: 1120,
        },
      ],
    },
    xl: {
      crop: 'landscape_ratio48x35',
      width: 576,
      height: 420,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 420,
        },
        {
          density: '2x',
          width: 1024,
          height: 747,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio33x18',
      width: 576,
      height: 314,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 314,
        },
        {
          density: '2x',
          width: 1024,
          height: 559,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio33x18',
      width: 576,
      height: 314,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 314,
        },
        {
          density: '2x',
          width: 1024,
          height: 559,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1024,
          height: 1189,
        },
      ],
    },
  },
  G1_W_4_ITEMS: {
    xxl: {
      crop: 'portrait_ratio11x12',
      width: 1024,
      height: 1117,
      sizes: [
        {
          density: '1x',
          width: 1024,
          height: 1117,
        },
        {
          density: '2x',
          width: 2048,
          height: 2234,
        },
      ],
    },
    xl: {
      crop: 'portrait_ratio11x12',
      width: 576,
      height: 628,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 628,
        },
        {
          density: '2x',
          width: 576,
          height: 1117,
        },
      ],
    },
    lg: {
      crop: 'landscape_ratio35x18',
      width: 576,
      height: 296,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 296,
        },
        {
          density: '2x',
          width: 1024,
          height: 527,
        },
      ],
    },
    md: {
      crop: 'landscape_ratio35x18',
      width: 576,
      height: 296,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 296,
        },
        {
          density: '2x',
          width: 1024,
          height: 527,
        },
      ],
    },
    sm: {
      crop: 'portrait_ratio31x36',
      width: 768,
      height: 892,
      sizes: [
        {
          density: '1x',
          width: 768,
          height: 892,
        },
        {
          density: '2x',
          width: 1536,
          height: 1784,
        },
      ],
    },
    xs: {
      crop: 'portrait_ratio31x36',
      width: 576,
      height: 669,
      sizes: [
        {
          density: '1x',
          width: 576,
          height: 669,
        },
        {
          density: '2x',
          width: 1024,
          height: 1189,
        },
      ],
    },
  },
  PRODUCT_4_ITEMS: {
    deskL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    deskS: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletP: {
      crop: 'landscape_ratio29x18',
      width: 468,
      height: 390,
    },
    mobile: {
      crop: 'landscape_ratio29x18',
      width: 468,
      height: 390,
    },
  },
  GRID_OF_PRODUCTS: {
    deskL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    deskS: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletP: {
      crop: 'landscape_ratio29x18',
      width: 468,
      height: 390,
    },
    mobile: {
      crop: 'landscape_ratio19x18',
      width: 380,
      height: 360,
    },
  },
  G1_W_INSTA: {
    deskL: {
      crop: 'landscape_ratio10x9',
      width: 400,
      height: 360,
    },
    deskS: {
      crop: 'landscape_ratio10x9',
      width: 400,
      height: 360,
    },
    tabletL: {
      crop: 'landscape_ratio10x9',
      width: 400,
      height: 360,
    },
    tabletP: {
      crop: 'landscape_ratio10x9',
      width: 400,
      height: 360,
    },
    mobile: {
      crop: 'portrait_ratio11x12',
      width: 327,
      height: 357,
    },
  },
  G5_CAROUSEL_MIX: {
    deskL: {
      crop: 'portrait_ratio18x19',
      width: 360,
      height: 380,
    },
    deskS: {
      crop: 'portrait_ratio18x19',
      width: 360,
      height: 380,
    },
    tabletL: {
      crop: 'portrait_ratio17x20',
      width: 240,
      height: 282,
    },
    tabletP: {
      crop: 'portrait_ratio19x20',
      width: 285,
      height: 300,
    },
    mobile: {
      crop: 'portrait_ratio19x20',
      width: 285,
      height: 300,
    },
  },
  G5_BOX_ALL_FIELDS: {
    deskL: {
      crop: 'landscape_ratio25x22',
      width: 1440,
      height: 1267,
    },
    deskS: {
      crop: 'portrait_ratio17x20',
      width: 1280,
      height: 1505,
    },
    tabletL: {
      crop: 'portrait_ratio15x23',
      width: 1024,
      height: 1570,
    },
    tabletP: {
      crop: 'portrait_ratio15x23',
      width: 1024,
      height: 1570,
    },
    mobile: {
      crop: 'portrait_ratio3x4',
      width: 375,
      height: 500,
    },
  },
  BOARD_WITH_FIELDS_2_ITEMS: {
    deskL: {
      crop: 'landscape_ratio326x181',
      width: 1304,
      height: 724,
    },
    deskS: {
      crop: 'landscape_ratio286x181',
      width: 1144,
      height: 724,
    },
    tabletL: {
      crop: 'landscape_ratio238x181',
      width: 857,
      height: 652,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    mobile: {
      crop: 'portrait_ratio343x362',
      width: 617,
      height: 652,
    },
  },
  BOARD_WITH_FIELDS_3_ITEMS: {
    deskL: {
      crop: 'landscape_ratio216x181',
      width: 864,
      height: 724,
    },
    deskS: {
      crop: 'landscape_ratio379x362',
      width: 758,
      height: 724,
    },
    tabletL: {
      crop: 'portrait_ratio315x362',
      width: 567,
      height: 652,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
  },
  BOARD_WITH_FIELDS_4_ITEMS: {
    deskL: {
      crop: 'landscape_ratio200x181',
      width: 800,
      height: 724,
    },
    deskS: {
      crop: 'landscape_ratio200x181',
      width: 800,
      height: 724,
    },
    tabletL: {
      crop: 'landscape_ratio204x181',
      width: 734,
      height: 652,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
      height: 652,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
      height: 652,
    },
  },
  SQUARE_BOARDS: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1296,
      height: 1296,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  PLAIN_SLIDER: {
    deskL: {
      crop: 'landscape_ratio35x18',
      width: 704,
      height: 362,
    },
    deskS: {
      crop: 'landscape_ratio35x18',
      width: 704,
      height: 362,
    },
    tabletL: {
      crop: 'landscape_ratio29x18',
      width: 572,
      height: 355,
    },
    tabletP: {
      crop: 'landscape_ratio35x18',
      width: 572,
      height: 294,
    },
    mobile: {
      crop: 'portrait_ratio31x36',
      width: 315,
      height: 366,
    },
  },
  BOX_WITH_2_PRODUCTS: {
    deskL: {
      crop: 'landscape_ratio121x85',
      width: 968,
      height: 680,
    },
    deskS: {
      crop: 'landscape_ratio121x85',
      width: 847,
      height: 603,
    },
    tabletL: {
      crop: 'landscape_ratio121x85',
      width: 654,
      height: 602,
    },
    tabletP: {
      crop: 'landscape_ratio121x85',
      width: 704,
      height: 528,
    },
    mobile: {
      crop: 'portrait_ratio343x528',
      width: 343,
      height: 528,
    },
  },
  BOX_WITH_4_PRODUCTS: {
    deskL: {
      crop: 'landscape_ratio1x1',
      width: 675,
      height: 675,
    },
    deskS: {
      crop: 'landscape_ratio64x29',
      width: 572,
      height: 577,
    },
    tabletL: {
      crop: 'portrait_ratio343x528',
      width: 343,
      height: 528,
    },
    tabletP: {
      crop: 'landscape_ratio755x361',
      width: 755,
      height: 361,
    },
    mobile: {
      crop: 'portrait_ratio343x528',
      width: 343,
      height: 528,
    },
  },
  LANDSCAPE_BANNER: {
    deskL: {
      crop: 'landscape_ratio40x13',
      width: 2880,
      height: 936,
    },
    deskS: {
      crop: 'landscape_ratio40x13',
      width: 1280,
      height: 416,
    },
    tabletL: {
      crop: 'landscape_ratio32x13',
      width: 1024,
      height: 416,
    },
    tabletP: {
      crop: 'landscape_ratio48x17',
      width: 1536,
      height: 544,
    },
    mobile: {
      crop: 'landscape_ratio25x19',
      width: 468,
      height: 356,
    },
  },
  ONE_ITEM_PRODUCT_CAROUSEL: {
    mobile: {
      crop: 'landscape_ratio16x5',
      width: 468,
      height: 146,
    },
  },
  PDP_BANNER: {
    deskL: {
      crop: 'landscape_ratio48x17',
      width: 1536,
      height: 544,
    },
    deskS: {
      crop: 'landscape_ratio40x13',
      width: 1280,
      height: 416,
    },
    tabletL: {
      crop: 'landscape_ratio35x18',
      width: 704,
      height: 362,
    },
    tabletP: {
      crop: 'landscape_ratio16x5',
      width: 768,
      height: 240,
    },
    mobile: {
      crop: 'landscape_ratio29x18',
      width: 468,
      height: 390,
    },
  },
  BANNER_W_SIDE_FIELDS: {
    desk: {
      crop: 'landscape_ratio5x4',
      width: 1900,
      height: 1520,
    },
    tablet: {
      crop: 'portrait_ratio23x29',
      width: 1480,
      height: 2510,
    },
    mobile: {
      crop: 'portrait_ratio23x29',
      width: 740,
      height: 933,
    },
  },
  BANNER_W_SIDE_FIELDS_RELATED: {
    desk: {
      crop: 'landscape_ratio5x4',
      width: 1900,
      height: 1520,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  BLURRED_SLIDER: {
    desk: {
      crop: 'landscape_ratio9x4',
      width: 2880,
      height: 675,
    },
    tablet: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    mobile: {
      crop: 'landscape_ratio2x1',
      width: 920,
      height: 460,
    },
  },
  NARROW_BANNER: {
    desk: {
      crop: 'landscape_ratio9x4',
      width: 2880,
      height: 675,
    },
    tablet: {
      crop: 'portrait_ratio23x29 ',
      width: 1840,
      height: 2320,
    },
    mobile: {
      crop: 'portrait_ratio23x29',
      width: 920,
      height: 1160,
    },
  },
  MOBILE_BANNER: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1440,
      height: 1440,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 1440,
      height: 1440,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  BOX_1: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1440,
      height: 1440,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 1440,
      height: 1440,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  CART_PROMO_BANNER: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  AVATAR_MEGA_MENU: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 96,
      height: 96,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 96,
      height: 96,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 96,
      height: 96,
    },
  },
  BIG_MENU_BANNER: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1296,
      height: 1296,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    tabletL: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  PLP_ONE_TILE: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  PLP_TWO_TILES: {
    deskL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    deskS: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletP: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    tabletL: {
      crop: 'landscape_ratio2x1',
      width: 1840,
      height: 920,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
  PLP_THREE_TILES: {
    deskL: {
      crop: 'landscape_ratio16x7',
      width: 2880,
      height: 1260,
    },
    deskS: {
      crop: 'landscape_ratio16x7',
      width: 2880,
      height: 1260,
    },
    tabletP: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
    },
    tabletL: {
      crop: 'landscape_ratio16x7',
      width: 1280,
      height: 560,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
      height: 740,
    },
  },
} as const

export type ImageCropTypesImproved = keyof typeof cmsImageCropsImproved

export const getDesktopThemeBreakpoint = (theme: Theme) => theme.breakpoints.up('md')

//grid
export const gridColumns = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 12,
  xl: 12,
}

//zIndex
//zIndex
export const Z_INDEX_LV8 = 100002
export const Z_INDEX_LV7 = 9999
export const Z_INDEX_LV6 = 9998
export const Z_INDEX_LV5 = 1300
export const Z_INDEX_LV4 = 4
export const Z_INDEX_LV3 = 3
export const Z_INDEX_LV2 = 2
export const Z_INDEX_LV1 = 1

export const DEFAULT_DIALOG_Z_INDEX = Z_INDEX_LV5
export const Z_INDEX_DIALOG_LEVEL_FOR_ROX_CONFIGURATOR = Z_INDEX_LV8 //to make sure it is higher than the ROX configurator index

//Icons
export const ICON_ADDRESS = `${config.storePrefix}-icon--address`
export const ICON_AFTER_CARE = `${config.storePrefix}-icon--after_care`
export const ICON_BABY_SMILE = `${config.storePrefix}-icon--baby_smile`
export const ICON_CALENDAR = `${config.storePrefix}-icon--calendar`
export const ICON_CHAT = `${config.storePrefix}-icon--chat`
export const ICON_CHOOSE = `${config.storePrefix}-icon--choose`
export const ICON_CLOCK = `${config.storePrefix}-icon--clock`
export const ICON_CONTACT_LENS = `${config.storePrefix}-icon--contact_lens`
export const ICON_CONTACT_LENS_FINGER = `${config.storePrefix}-icon--contact_lens_finger`
export const ICON_COMFORTABLE_LENS = `${config.storePrefix}-icon--comfortable-lens`
export const ICON_CREDIT_CARD = `${config.storePrefix}-icon--credit_card`
export const ICON_DISPLAY = `${config.storePrefix}-icon--display`
export const ICON_DROP = `${config.storePrefix}-icon--drop`
export const ICON_EYE = `${config.storePrefix}-icon--eye`
export const ICON_EYE_CARE = `${config.storePrefix}-icon--eye_care`
export const ICON_EYEWEAR_RANGE = `${config.storePrefix}-icon--eyewear_range`
export const ICON_FACE_GLASSES_SMILE = `${config.storePrefix}-icon--face_glasses_smile`
export const ICON_FACE_OVAL = `${config.storePrefix}-icon--face_oval`
export const ICON_FACE_OVAL_THICK = `${config.storePrefix}-icon--face_oval_thick`
export const ICON_FACE_RECTANGLE = `${config.storePrefix}-icon--face_rectangle`
export const ICON_FACE_RECTANGLE_SLIM = `${config.storePrefix}-icon--face_rectangle_slim`
export const ICON_FACE_RECTANGLE_THICK = `${config.storePrefix}-icon--face_rectangle_thick`
export const ICON_FACE_ROUND = `${config.storePrefix}-icon--face_round`
export const ICON_FACE_ROUND_THICK = `${config.storePrefix}-icon--face_round_thick`
export const ICON_FACE_SLIM = `${config.storePrefix}-icon--face_slim`
export const ICON_FACE_SQUARE = `${config.storePrefix}-icon--face_square`
export const ICON_FACE_TRIANGLE = `${config.storePrefix}-icon--face_triangle`
export const ICON_FRAME_ADVISOR = `${config.storePrefix}-icon--frame_advisor`
export const ICON_FREE_RETURN = `${config.storePrefix}-icon--free_return`
export const ICON_GLASSES = `${config.storePrefix}-icon--glasses`
export const ICON_GLASSES_CASE = `${config.storePrefix}-icon--glasses_case`
export const ICON_GLOBAL_CARE = `${config.storePrefix}-icon--global_care`
export const ICON_GREEN_SHIPPING = `${config.storePrefix}-icon--green_shipping`
export const ICON_HEART_EMPTY = `${config.storePrefix}-icon--heart_empty`
export const ICON_HEART_FILLED = `${config.storePrefix}-icon--heart_filled`
export const ICON_INFO = `${config.storePrefix}-icon--info`
export const ICON_LENS_CLEANER = `${config.storePrefix}-icon--lens_cleaner`
export const ICON_LENS_GLASS = `${config.storePrefix}-icon--lens_glass`
export const ICON_LENS_GLASS_LASER = `${config.storePrefix}-icon--lens_glass_laser`
export const ICON_LENS_GLASS_PHOTOCHROMIC = `${config.storePrefix}-icon--lens_glass_photochromic`
export const ICON_LENS_SUN = `${config.storePrefix}-icon--lens_sun`
export const ICON_LENS_SUN_OAKLEY = `${config.storePrefix}-icon--lens_sun_oakley`
export const ICON_LENS_SUN_RAY_BAN = `${config.storePrefix}-icon--lens_sun_ray_ban`
export const ICON_LENS_SUN_RX = `${config.storePrefix}-icon--lens_sun_rx`
export const ICON_LUXURY_GLASSES = `${config.storePrefix}-icon--luxury_glasses`
export const ICON_MAGNIFYING_GLASS = `${config.storePrefix}-icon--magnifying_glass`
export const ICON_MASK = `${config.storePrefix}-icon--mask`
export const ICON_OAKLEY_GLASS = `${config.storePrefix}-icon--oakley_glass`
export const ICON_OFFER = `${config.storePrefix}-icon--offer`
export const ICON_OPTICIAN = `${config.storePrefix}-icon--optician`
export const ICON_OPTOMETRIST = `${config.storePrefix}-icon--optometrist`
export const ICON_PADLOCK = `${config.storePrefix}-icon--padlock`
export const ICON_PILOT_DARK_GLASSES = `${config.storePrefix}-icon--pilot_dark_glasses`
export const ICON_PILOT_GLASSES_METER = `${config.storePrefix}-icon--pilot_glasses_meter`
export const ICON_PRESCRIPTION = `${config.storePrefix}-icon--prescription`
export const ICON_QUESTION = `${config.storePrefix}-icon--question`
export const ICON_RAYBAN_GLASS = `${config.storePrefix}-icon--rayban_glass`
export const ICON_REPLACE = `${config.storePrefix}-icon--replace`
export const ICON_RETINAL_SCREENING = `${config.storePrefix}-icon--retinal_screening`
export const ICON_ROUND_GLASSES = `${config.storePrefix}-icon--round_glasses`
export const ICON_SEARCH = `${config.storePrefix}-icon--search`
export const ICON_SHOPPING = `${config.storePrefix}-icon--shopping`
export const ICON_SMOOTH_RECTANGLE_GLASSES = `${config.storePrefix}-icon--smooth_rectangle_glasses`
export const ICON_SOCIAL_DISTANCE = `${config.storePrefix}-icon--social_distance`
export const ICON_STORE_MANAGER = `${config.storePrefix}-icon--store_manager`
export const ICON_STORES = `${config.storePrefix}-icon--stores`
export const ICON_TEMPLE_SIZE = `${config.storePrefix}-icon--temple_size`
export const ICON_TEST = `${config.storePrefix}-icon--test`
export const ICON_TICK = `${config.storePrefix}-icon--tick`
export const ICON_TRIANGLE_DANGER = `${config.storePrefix}-icon--triangle_danger`
export const ICON_CHECKMARK_ROUNDED = `${config.storePrefix}-icon--checkmark_rounded`
export const ICON_MOBILE = `${config.storePrefix}-icon--mobile`
export const ICON_ANTI_STATIC_COATING = `${config.storePrefix}-icon--anti_static_coating`
export const ICON_UV_PROTECTION = `${config.storePrefix}-icon--uv_protection`
export const ICON_BETTER_CLARITY = `${config.storePrefix}-icon--better_clarity`

// Animation
export const PLP_ANIMATION_DURATION = 500

// Redirects for empty cart without item.teaserLXCallToActionSettings
export const PLP_LINK_BRANDS = 'eyewear-all'
export const PLP_LINK_CONTACT_LENSES = 'contact-lenses'
export const DASH_CHARACTER = '-'
export const SLASH_CHARACTER = '/'
export const SEPARATOR_CHARACTER = '|'
export const COLON_CHARACTER = ':'
export const TIME_SEPARATOR = ':'

export const ctaBgMap: CtaBG = {
  light: 'light',
  dark: 'dark',
  darkVariant: 'darkVariant',
  lightVariant: 'lightVariant',
}
