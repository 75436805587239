import { ButtonProps } from '@typesApp/button'
import Link, { LinkProps as NextLinkProps } from 'next/link'
import { StyledButton, StyledChildrenWrapper } from './Button.style'
import { useRouter } from 'next/router'
import { getClientLocale, replaceLocaleInUrl } from '@utils/locale'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'

const fillTypeMap = {
  fill: 'contained',
  outline: 'outlined',
}

const sizeMap = {
  big: 'large',
}

const variantMap = {
  tertiary: 'secondary',
}

function Button(props: ButtonProps) {
  const { children, startIcon, endIcon, href, target, onClick, variant, fillType, size, labelText, color, ...rest } =
    props
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const internalHref = typeof href === 'string' ? href : String(href)
  const buttonVariant = fillTypeMap[fillType as string] ?? fillType
  const sizeVariant = sizeMap[size as string] ?? size
  const colorVariant = variantMap[variant as string] ?? variant ?? color

  const buttonProps = {
    startIcon,
    endIcon,
    ...(href
      ? {
          href: replaceLocaleInUrl({ isLocaleDomain, locationOrigin, locale, href: internalHref }),
          component: Link,
          children,
          ...(target ? { target } : {}),
        }
      : onClick
        ? { onClick, children }
        : { children }),
    ...rest,
    color: colorVariant,
    variant: buttonVariant,
    size: sizeVariant,
    locale: getClientLocale(isLocaleDomain, locale as string),
  }

  const renderLabel = (labelText: string | undefined, children: React.ReactNode) => {
    return <StyledChildrenWrapper>{labelText ?? children}</StyledChildrenWrapper>
  }

  return (
    <StyledButton disableRipple disableElevation {...buttonProps}>
      {renderLabel(labelText, children)}
    </StyledButton>
  )
}

export default Button
