import { GridItem } from '@components/UI/Grid'
import styled from '@mui/material/styles/styled'

export const ProductsWrapper = styled('div', {
  name: 'ProductGrid',
  slot: 'Wrapper',
})(() => ({}))

export const StyledProductsCount = styled('div', {
  name: 'ProductGrid',
  slot: 'ProductsCount',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(12),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(16),
  },
}))

export const StyledLoaderContainer = styled('div', {
  name: 'ProductGrid',
  slot: 'LoaderContainer',
})(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const StyledProductGridContainer = styled('div', {
  name: 'ProductGrid',
  slot: 'Container',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(9, 8),

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(21, 16),
  },
}))

export const StyledGridProductLayout = styled(GridItem, {
  name: 'ProductGrid',
  slot: 'Item',
  shouldForwardProp: prop => prop !== 'listingLayout',
})<{ listingLayout?: 'full' | 'compact' }>(() => ({
  display: 'block',
  position: 'relative',
}))

export const StyledProductWrap = styled('div', {
  name: 'ProductGrid',
  slot: 'Wrap',
})`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }
  /* for older browser's versions that don't supprt aspetc-ratio */
  @supports not (aspect-ratio: 1 / 1) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`

export const StyledProductLabelWrap = styled('div', {
  name: 'ProductLabelWrap',
})(() => ({
  position: 'absolute',
  aspectRatio: '1 / 1',
  top: 0,
  left: 0,
  width: '100%',
}))
