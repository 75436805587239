import React from 'react'
import { StyledLinearFlowContainer, StyledTransitionContainer } from './transition.style'
import { TransitionProps } from './transition.type'

export const Transition: React.FC<TransitionProps> = ({ size, type, color }: TransitionProps) => {
  const whiteCIcon = (
    <path
      transform="translate(14, 13)"
      d="M11.3143 21.9999H11.1533C9.86028 22.0057 8.5744 21.8118 7.34298 21.425C6.06919 20.9956 4.87141 20.3742 3.79274 19.5832C2.63718 18.6819 1.70436 17.5361 1.06401 16.2314C0.305421 14.6285 -0.056667 12.8727 0.0071931 11.1067C0.00157717 9.60157 0.309211 8.11118 0.911267 6.7267C1.50445 5.4085 2.34463 4.21116 3.38818 3.19682C4.43008 2.17928 5.67536 1.38406 7.04575 0.861122C9.31972 -0.0543463 11.8297 -0.245525 14.2205 0.314637C15.1176 0.516431 15.9817 0.839301 16.7883 1.27402C17.546 1.71203 18.1991 2.3042 18.7037 3.01062C19.2304 3.75953 19.5047 4.65132 19.4881 5.56086C19.496 5.88125 19.4391 6.20002 19.3208 6.49876C19.2025 6.7975 19.025 7.07033 18.7987 7.30152C18.2612 7.77063 17.5563 8.01219 16.8378 7.97349C16.2677 7.96836 15.7051 7.8456 15.1865 7.61321C14.9266 7.50166 14.708 7.31443 14.5603 7.07692C14.4126 6.83941 14.343 6.5631 14.3609 6.28546C14.3763 5.71696 14.4922 5.15539 14.7035 4.62577C14.8942 4.13373 15.0097 3.61675 15.0462 3.09158C15.0439 2.75605 14.943 2.42826 14.7555 2.14754C14.5681 1.86681 14.3021 1.64504 13.9894 1.50881C13.2133 1.11115 12.3465 0.914668 11.4712 0.938035C10.7859 0.938035 8.664 1.04328 7.26042 2.46819C6.62468 3.1442 6.12517 4.18454 5.72473 5.56086C5.26986 7.42254 5.06584 9.33466 5.11789 11.2483C5.10803 12.5335 5.19635 13.8176 5.38209 15.0899C5.53265 16.1712 5.85439 17.2229 6.3357 18.2069V18.2312C7.39252 20.3604 10.1997 20.66 11.789 20.66C13.4361 20.6252 15.0181 20.0234 16.2582 18.9599C17.4982 17.8964 18.3172 16.439 18.5716 14.843H20C18.9927 19.6885 15.6571 21.9472 11.3143 21.9999Z"
      fill="#FDFEFF"
    />
  )
  const blueBackground = (
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#1C4DA1"
    />
  )
  const redBackground = (
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#D51C29"
    />
  )
  const yellowBackground = (
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#FAA424"
    />
  )
  const blackBackground = (
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="black"
    />
  )

  const frameByFrame = (frameOrderNumber: number, totalFrames: number): string => {
    let values = ''
    for (let i = 0; i < totalFrames; i++) {
      values += i === frameOrderNumber - 1 ? 'visible;' : 'hidden;'
    }
    return values
  }

  const chooseColor = () => {
    switch (color) {
      case 'blue':
        return '#1C4DA1'
      case 'green':
        return '#005D30'
      case 'red':
        return '#D51C29'
      case 'yellow':
        return '#FDB930'
      default:
        return '#1C4DA1'
    }
  }

  switch (type) {
    case 'vertical-color-change':
      return (
        <StyledTransitionContainer>
          <svg
            className="svg"
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blackBackground}
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(1, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blueBackground}
            <path
              transform="translate(0, 8)"
              d="M24 40C37.2548 40 48 29.2548 48 16C48 4.80294 36 0 24 0C12 0 0 4.72125 0 16C0 29.2548 10.7452 40 24 40Z"
              fill="#010101"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(2, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blueBackground}
            <path
              transform="translate(0, 15)"
              d="M24 33C37.2548 33 48 22.2548 48 9C48 3 36 0 24 0C12 0 0 3 0 9C0 22.2548 10.7452 33 24 33Z"
              fill="#010101"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(3, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blueBackground}
            <path
              transform="translate(0, 24)"
              d="M24 24C37.2548 24 48 13.2548 48 0C48 0 37 6 24 6C11 6 0 0 0 0C0 13.2548 10.7452 24 24 24Z"
              fill="#010101"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(4, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blueBackground}
            <path
              transform="translate(0, 24)"
              d="M24 24C37.2548 24 48 13.2548 48 0C48 0 41.4285 16.5 24 16.5C6.5715 16.5 0 0 0 0C0 13.2548 10.7452 24 24 24Z"
              fill="#010101"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(5, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blueBackground}
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(6, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {redBackground}
            <path
              transform="translate(8, 0)"
              d="M40 24C40 10.7452 29.2548 -1.27877e-06 16 -6.99382e-07C4.80294 -2.09943e-07 -1.57361e-06 12 -1.04907e-06 24C-5.24537e-07 36 4.72125 48 16 48C29.2548 48 40 37.2548 40 24Z"
              fill="#1C4DA1"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(7, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {redBackground}
            <path
              transform="translate(15, 0)"
              d="M33 24C33 10.7452 22.2548 -9.7279e-07 9 -3.93402e-07C3 -1.31134e-07 -1.57361e-06 12 -1.04907e-06 24C-5.24537e-07 36 3 48 9 48C22.2548 48 33 37.2548 33 24Z"
              fill="#1C4DA1"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(8, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {redBackground}
            <path
              transform="translate(24, 0)"
              d="M24 24C24 10.7452 13.2548 -5.79387e-07 -2.09815e-06 0C-2.09815e-06 0 6 11 6 24C6 37 0 48 0 48C13.2548 48 24 37.2548 24 24Z"
              fill="#1C4DA1"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(9, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {redBackground}
            <path
              transform="translate(24, 0)"
              d="M24 24C24 10.7452 13.2548 -5.79387e-07 -2.09815e-06 0C-2.09815e-06 0 6 11 6 24C6 37 0 48 0 48C13.2548 48 24 37.2548 24 24Z"
              fill="#1C4DA1"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(10, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {redBackground}
            <path
              transform="translate(24, 0)"
              d="M24 24C24 10.7452 13.2548 -5.79387e-07 -2.09815e-06 0C-2.09815e-06 0 16.5 6.5715 16.5 24C16.5 41.4285 0 48 0 48C13.2548 48 24 37.2548 24 24Z"
              fill="#1C4DA1"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(11, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {redBackground}
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(12, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {yellowBackground}
            <path
              d="M24 2.09815e-06C10.7452 3.25692e-06 -2.55754e-06 10.7452 -1.39876e-06 24C-4.19886e-07 35.1971 12 40 24 40C36 40 48 35.2787 48 24C48 10.7452 37.2548 9.39372e-07 24 2.09815e-06Z"
              fill="#D51C29"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(13, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {yellowBackground}
            <path
              d="M24 2.09815e-06C10.7452 3.25692e-06 -1.94558e-06 10.7452 -7.86805e-07 24C-2.62268e-07 30 12 33 24 33C36 33 48 30 48 24C48 10.7452 37.2548 9.39372e-07 24 2.09815e-06Z"
              fill="#D51C29"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(14, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {yellowBackground}
            <path
              d="M24 5.91284e-06C10.7452 7.07162e-06 -1.15877e-06 10.7452 0 24C0 24 11 18 24 18C37 18 48 24 48 24C48 10.7452 37.2548 4.75407e-06 24 5.91284e-06Z"
              fill="#D51C29"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(15, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {yellowBackground}
            <path
              d="M24 5.91284e-06C10.7452 7.07162e-06 -1.15877e-06 10.7452 0 24C0 24 6.5715 7.5 24 7.5C41.4285 7.5 48 24 48 24C48 10.7452 37.2548 4.75407e-06 24 5.91284e-06Z"
              fill="#D51C29"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(16, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {yellowBackground}
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(17, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blackBackground}
            <path
              d="M-1.04907e-06 24C-1.62846e-06 37.2548 10.7452 48 24 48C35.1971 48 40 36 40 24C40 12 35.2787 -2.06372e-07 24 -6.99382e-07C10.7452 -1.27877e-06 -4.69686e-07 10.7452 -1.04907e-06 24Z"
              fill="#FAA424"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(18, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blackBackground}
            <path
              d="M-1.04907e-06 24C-1.62846e-06 37.2548 10.7452 48 24 48C30 48 33 36 33 24C33 12 30 -1.31134e-07 24 -3.93402e-07C10.7452 -9.7279e-07 -4.69686e-07 10.7452 -1.04907e-06 24Z"
              fill="#FAA424"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(19, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blackBackground}
            <path
              d="M-1.04907e-06 24C-1.62846e-06 37.2548 10.7452 48 24 48C30 48 33 36 33 24C33 12 30 -1.31134e-07 24 -3.93402e-07C10.7452 -9.7279e-07 -4.69686e-07 10.7452 -1.04907e-06 24Z"
              fill="#FAA424"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(20, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blackBackground}
            <path
              d="M2.76562e-06 24C2.18624e-06 37.2548 10.7452 48 24 48C24 48 18 37 18 24C18 11 24 0 24 0C10.7452 -5.79387e-07 3.34501e-06 10.7452 2.76562e-06 24Z"
              fill="#FAA424"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(21, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blackBackground}
            <path
              d="M2.76562e-06 24C2.18624e-06 37.2548 10.7452 48 24 48C24 48 7.5 41.4285 7.5 24C7.5 6.5715 24 0 24 0C10.7452 -5.79387e-07 3.34501e-06 10.7452 2.76562e-06 24Z"
              fill="#FAA424"
            />
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(22, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            className="svg"
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            {blackBackground}
            {whiteCIcon}
            <animate
              attributeName="visibility"
              values={frameByFrame(23, 23)}
              dur="2.3s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
        </StyledTransitionContainer>
      )
    case 'circular-flow':
      return (
        <StyledTransitionContainer>
          <svg
            width={size}
            height={size}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path
              d="M103.724 132.583H103.268C99.6041 132.6 95.9608 132.038 92.4718 130.917C88.8627 129.673 85.469 127.872 82.4128 125.58C79.1387 122.968 76.4957 119.648 74.6814 115.868C72.532 111.223 71.5061 106.135 71.687 101.017C71.6711 96.6561 72.5428 92.3373 74.2486 88.3255C75.9293 84.5057 78.3098 81.0361 81.2665 78.0969C84.2186 75.1483 87.7468 72.844 91.6296 71.3286C98.0725 68.6758 105.184 68.1219 111.958 69.7451C114.5 70.3298 116.948 71.2654 119.233 72.5251C121.38 73.7943 123.231 75.5103 124.661 77.5573C126.153 79.7274 126.93 82.3116 126.883 84.9472C126.905 85.8756 126.744 86.7993 126.409 87.665C126.074 88.5306 125.571 89.3212 124.93 89.9911C123.407 91.3505 121.409 92.0505 119.374 91.9383C117.758 91.9235 116.164 91.5678 114.695 90.8943C113.959 90.5711 113.339 90.0286 112.921 89.3403C112.502 88.6521 112.305 87.8514 112.356 87.0469C112.4 85.3995 112.728 83.7723 113.327 82.2376C113.867 80.8118 114.194 79.3137 114.298 77.7919C114.291 76.8196 114.005 75.8698 113.474 75.0563C112.943 74.2428 112.189 73.6002 111.303 73.2054C109.104 72.0531 106.648 71.4838 104.168 71.5515C102.227 71.5515 96.2147 71.8565 92.2378 75.9855C90.4366 77.9444 89.0213 80.959 87.8867 84.9472C86.5979 90.3418 86.0199 95.8826 86.1674 101.428C86.1394 105.152 86.3896 108.873 86.9159 112.56C87.3425 115.693 88.2541 118.741 89.6178 121.592V121.662C92.6121 127.832 100.566 128.7 105.069 128.7C109.736 128.6 114.218 126.856 117.731 123.774C121.245 120.692 123.565 116.469 124.286 111.844H128.333C125.479 125.885 116.029 132.43 103.724 132.583Z"
              fill="#F1F2F2"
            />
            <path
              d="M30.7166 172.111C48.6891 189.383 73.1042 200 99.999 200C100.147 200 100.295 200 100.443 199.999V169.999C100.296 170 100.148 170 100 170C81.3892 170 64.4747 162.737 51.9363 150.891L30.7166 172.111Z"
              fill="#F1F2F2"
            />
            <path
              d="M102.443 199.971V169.958C120.802 169.328 137.365 161.629 149.497 149.498L170.71 170.711C153.149 188.272 129.087 199.332 102.443 199.971Z"
              fill="#F1F2F2"
            />
            <path
              d="M172.11 169.282L150.891 148.064C162.643 135.625 169.884 118.88 169.999 100.444H199.998C199.882 127.164 189.287 151.409 172.11 169.282Z"
              fill="#F1F2F2"
            />
            <path
              d="M199.987 98.4444H169.983C169.591 80.4477 162.406 64.1245 150.891 51.9363L172.11 30.7176C189.048 48.343 199.586 72.1644 199.987 98.4444Z"
              fill="#F1F2F2"
            />
            <path
              d="M170.71 29.2893L149.497 50.502C137.365 38.3708 120.802 30.6716 102.443 30.0418V0.0292917C129.087 0.668468 153.149 11.7284 170.71 29.2893Z"
              fill="#CECECE"
            />
            <path
              d="M100 30C81.3892 30 64.4747 37.2629 51.9363 49.1089L30.7166 27.8892C48.6891 10.6173 73.1042 0 99.999 0C100.147 0 100.295 0.000322481 100.443 0.00096678V30.0014C100.296 30.0005 100.148 30 100 30Z"
              fill="#F1F2F2"
            />
            <path
              d="M50.502 50.503C38.1755 62.8298 30.4249 79.7325 30.0169 98.4444H0.0108867C0.422576 71.4477 11.5323 47.0453 29.2884 29.2893L50.502 50.503Z"
              fill="#F1F2F2"
            />
            <path
              d="M0 100.444H30.0014C30.1205 119.598 37.932 136.927 50.502 149.497L29.2884 170.711C11.2891 152.711 0.119391 127.882 0 100.444Z"
              fill="#F1F2F2"
            />
            <animate
              attributeName="visibility"
              values={frameByFrame(1, 9)}
              dur="2s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path
              d="M103.724 132.583H103.268C99.6041 132.6 95.9608 132.038 92.4718 130.917C88.8627 129.673 85.469 127.872 82.4128 125.58C79.1387 122.968 76.4957 119.648 74.6814 115.868C72.532 111.223 71.5061 106.135 71.687 101.017C71.6711 96.6561 72.5428 92.3373 74.2486 88.3255C75.9293 84.5057 78.3098 81.0361 81.2665 78.0969C84.2186 75.1483 87.7468 72.844 91.6296 71.3286C98.0725 68.6758 105.184 68.1219 111.958 69.7451C114.5 70.3298 116.948 71.2654 119.233 72.5251C121.38 73.7943 123.231 75.5103 124.661 77.5573C126.153 79.7274 126.93 82.3116 126.883 84.9472C126.905 85.8756 126.744 86.7993 126.409 87.665C126.074 88.5306 125.571 89.3212 124.93 89.9911C123.407 91.3505 121.409 92.0505 119.374 91.9383C117.758 91.9235 116.164 91.5678 114.695 90.8943C113.959 90.5711 113.339 90.0286 112.921 89.3403C112.502 88.6521 112.305 87.8514 112.356 87.0469C112.4 85.3995 112.728 83.7723 113.327 82.2376C113.867 80.8118 114.194 79.3137 114.298 77.7919C114.291 76.8196 114.005 75.8698 113.474 75.0563C112.943 74.2428 112.189 73.6002 111.303 73.2054C109.104 72.0531 106.648 71.4838 104.168 71.5515C102.227 71.5515 96.2147 71.8565 92.2378 75.9855C90.4366 77.9444 89.0213 80.959 87.8867 84.9472C86.5979 90.3418 86.0199 95.8826 86.1674 101.428C86.1394 105.152 86.3896 108.873 86.9159 112.56C87.3425 115.693 88.2541 118.741 89.6178 121.592V121.662C92.6121 127.832 100.566 128.7 105.069 128.7C109.736 128.6 114.218 126.856 117.731 123.774C121.245 120.692 123.565 116.469 124.286 111.844H128.333C125.479 125.885 116.029 132.43 103.724 132.583Z"
              fill="#F1F2F2"
            />
            <path
              d="M30.7166 172.111C48.6891 189.383 73.1042 200 99.999 200C100.147 200 100.295 200 100.443 199.999V169.999C100.296 170 100.148 170 100 170C81.3892 170 64.4747 162.737 51.9363 150.891L30.7166 172.111Z"
              fill="#F1F2F2"
            />
            <path
              d="M102.443 199.971V169.958C120.802 169.328 137.365 161.629 149.497 149.498L170.71 170.711C153.149 188.272 129.087 199.332 102.443 199.971Z"
              fill="#F1F2F2"
            />
            <path
              d="M172.11 169.282L150.891 148.064C162.643 135.625 169.884 118.88 169.999 100.444H199.998C199.882 127.164 189.287 151.409 172.11 169.282Z"
              fill="#F1F2F2"
            />
            <path
              d="M199.987 98.4444H169.983C169.591 80.4477 162.406 64.1245 150.891 51.9363L172.11 30.7176C189.048 48.343 199.586 72.1644 199.987 98.4444Z"
              fill="#CECECE"
            />
            <path
              d="M170.71 29.2893L149.497 50.502C137.365 38.3708 120.802 30.6716 102.443 30.0418V0.0292917C129.087 0.668468 153.149 11.7284 170.71 29.2893Z"
              fill="#F1F2F2"
            />
            <path
              d="M100 30C81.3892 30 64.4747 37.2629 51.9363 49.1089L30.7166 27.8892C48.6891 10.6173 73.1042 0 99.999 0C100.147 0 100.295 0.000322481 100.443 0.00096678V30.0014C100.296 30.0005 100.148 30 100 30Z"
              fill="#F1F2F2"
            />
            <path
              d="M50.502 50.503C38.1755 62.8298 30.4249 79.7325 30.0169 98.4444H0.0108867C0.422576 71.4477 11.5323 47.0453 29.2884 29.2893L50.502 50.503Z"
              fill="#F1F2F2"
            />
            <path
              d="M0 100.444H30.0014C30.1205 119.598 37.932 136.927 50.502 149.497L29.2884 170.711C11.2891 152.711 0.119391 127.882 0 100.444Z"
              fill="#F1F2F2"
            />
            <animate
              attributeName="visibility"
              values={frameByFrame(2, 9)}
              dur="2s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path
              d="M103.724 132.583H103.268C99.6041 132.6 95.9608 132.038 92.4718 130.917C88.8627 129.673 85.469 127.872 82.4128 125.58C79.1387 122.968 76.4957 119.648 74.6814 115.868C72.532 111.223 71.5061 106.135 71.687 101.017C71.6711 96.6561 72.5428 92.3373 74.2486 88.3255C75.9293 84.5057 78.3098 81.0361 81.2665 78.0969C84.2186 75.1483 87.7468 72.844 91.6296 71.3286C98.0725 68.6758 105.184 68.1219 111.958 69.7451C114.5 70.3298 116.948 71.2654 119.233 72.5251C121.38 73.7943 123.231 75.5103 124.661 77.5573C126.153 79.7274 126.93 82.3116 126.883 84.9472C126.905 85.8756 126.744 86.7993 126.409 87.665C126.074 88.5306 125.571 89.3212 124.93 89.9911C123.407 91.3505 121.409 92.0505 119.374 91.9383C117.758 91.9235 116.164 91.5678 114.695 90.8943C113.959 90.5711 113.339 90.0286 112.921 89.3403C112.502 88.6521 112.305 87.8514 112.356 87.0469C112.4 85.3995 112.728 83.7723 113.327 82.2376C113.867 80.8118 114.194 79.3137 114.298 77.7919C114.291 76.8196 114.005 75.8698 113.474 75.0563C112.943 74.2428 112.189 73.6002 111.303 73.2054C109.104 72.0531 106.648 71.4838 104.168 71.5515C102.227 71.5515 96.2147 71.8565 92.2378 75.9855C90.4366 77.9444 89.0213 80.959 87.8867 84.9472C86.5979 90.3418 86.0199 95.8826 86.1674 101.428C86.1394 105.152 86.3896 108.873 86.9159 112.56C87.3425 115.693 88.2541 118.741 89.6178 121.592V121.662C92.6121 127.832 100.566 128.7 105.069 128.7C109.736 128.6 114.218 126.856 117.731 123.774C121.245 120.692 123.565 116.469 124.286 111.844H128.333C125.479 125.885 116.029 132.43 103.724 132.583Z"
              fill="#F1F2F2"
            />
            <path
              d="M30.7166 172.111C48.6891 189.383 73.1042 200 99.999 200C100.147 200 100.295 200 100.443 199.999V169.999C100.296 170 100.148 170 100 170C81.3892 170 64.4747 162.737 51.9363 150.891L30.7166 172.111Z"
              fill="#F1F2F2"
            />
            <path
              d="M102.443 199.971V169.958C120.802 169.328 137.365 161.629 149.497 149.498L170.71 170.711C153.149 188.272 129.087 199.332 102.443 199.971Z"
              fill="#F1F2F2"
            />
            <path
              d="M172.11 169.282L150.891 148.064C162.643 135.625 169.884 118.88 169.999 100.444H199.998C199.882 127.164 189.287 151.409 172.11 169.282Z"
              fill="#CECECE"
            />
            <path
              d="M199.987 98.4444H169.983C169.591 80.4477 162.406 64.1245 150.891 51.9363L172.11 30.7176C189.048 48.343 199.586 72.1644 199.987 98.4444Z"
              fill="#F1F2F2"
            />
            <path
              d="M170.71 29.2893L149.497 50.502C137.365 38.3708 120.802 30.6716 102.443 30.0418V0.0292917C129.087 0.668468 153.149 11.7284 170.71 29.2893Z"
              fill="#F1F2F2"
            />
            <path
              d="M100 30C81.3892 30 64.4747 37.2629 51.9363 49.1089L30.7166 27.8892C48.6891 10.6173 73.1042 0 99.999 0C100.147 0 100.295 0.000322481 100.443 0.00096678V30.0014C100.296 30.0005 100.148 30 100 30Z"
              fill="#F1F2F2"
            />
            <path
              d="M50.502 50.503C38.1755 62.8298 30.4249 79.7325 30.0169 98.4444H0.0108867C0.422576 71.4477 11.5323 47.0453 29.2884 29.2893L50.502 50.503Z"
              fill="#F1F2F2"
            />
            <path
              d="M0 100.444H30.0014C30.1205 119.598 37.932 136.927 50.502 149.497L29.2884 170.711C11.2891 152.711 0.119391 127.882 0 100.444Z"
              fill="#F1F2F2"
            />
            <animate
              attributeName="visibility"
              values={frameByFrame(3, 9)}
              dur="2s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path
              d="M104.718 132.583H104.262C100.598 132.6 96.9549 132.038 93.4659 130.917C89.8568 129.673 86.4631 127.872 83.4069 125.58C80.1328 122.968 77.4898 119.648 75.6755 115.868C73.5262 111.223 72.5002 106.135 72.6812 101.017C72.6653 96.6561 73.5369 92.3373 75.2427 88.3255C76.9234 84.5057 79.3039 81.0361 82.2606 78.0969C85.2127 75.1483 88.741 72.844 92.6238 71.3286C99.0667 68.6758 106.178 68.1219 112.952 69.7451C115.494 70.3298 117.942 71.2654 120.228 72.5251C122.374 73.7943 124.225 75.5103 125.655 77.5573C127.147 79.7274 127.924 82.3116 127.877 84.9472C127.899 85.8756 127.738 86.7993 127.403 87.665C127.068 88.5306 126.565 89.3212 125.924 89.9911C124.401 91.3505 122.404 92.0505 120.368 91.9383C118.753 91.9235 117.159 91.5678 115.689 90.8943C114.953 90.5711 114.334 90.0286 113.915 89.3403C113.497 88.6521 113.299 87.8514 113.35 87.0469C113.394 85.3995 113.722 83.7723 114.321 82.2376C114.861 80.8118 115.188 79.3137 115.292 77.7919C115.285 76.8196 114.999 75.8698 114.468 75.0563C113.937 74.2428 113.183 73.6002 112.297 73.2054C110.099 72.0531 107.642 71.4838 105.162 71.5515C103.221 71.5515 97.2088 71.8565 93.232 75.9855C91.4307 77.9444 90.0154 80.959 88.8809 84.9472C87.5921 90.3418 87.014 95.8826 87.1615 101.428C87.1336 105.152 87.3838 108.873 87.9101 112.56C88.3366 115.693 89.2482 118.741 90.612 121.592V121.662C93.6063 127.832 101.56 128.7 106.063 128.7C110.73 128.6 115.212 126.856 118.726 123.774C122.239 120.692 124.559 116.469 125.28 111.844H129.327C126.474 125.885 117.023 132.43 104.718 132.583Z"
              fill="#F1F2F2"
            />
            <path
              d="M31.7108 172.111C49.6833 189.383 74.0983 200 100.993 200C101.141 200 101.29 200 101.438 199.999V169.999C101.29 170 101.142 170 100.994 170C82.3833 170 65.4689 162.737 52.9305 150.891L31.7108 172.111Z"
              fill="#F1F2F2"
            />
            <path
              d="M103.438 199.971V169.958C121.796 169.328 138.36 161.629 150.491 149.498L171.704 170.711C154.143 188.272 130.081 199.332 103.438 199.971Z"
              fill="#CECECE"
            />
            <path
              d="M173.104 169.282L151.885 148.064C163.637 135.625 170.878 118.88 170.993 100.444H200.992C200.876 127.164 190.281 151.409 173.104 169.282Z"
              fill="#F1F2F2"
            />
            <path
              d="M200.981 98.4444H170.977C170.585 80.4477 163.4 64.1245 151.885 51.9363L173.104 30.7176C190.042 48.343 200.581 72.1644 200.981 98.4444Z"
              fill="#F1F2F2"
            />
            <path
              d="M171.704 29.2893L150.491 50.502C138.36 38.3708 121.796 30.6716 103.438 30.0418V0.0292917C130.081 0.668468 154.143 11.7284 171.704 29.2893Z"
              fill="#F1F2F2"
            />
            <path
              d="M100.994 30C82.3833 30 65.4689 37.2629 52.9305 49.1089L31.7108 27.8892C49.6833 10.6173 74.0983 0 100.993 0C101.141 0 101.29 0.000322481 101.438 0.00096678V30.0014C101.29 30.0005 101.142 30 100.994 30Z"
              fill="#F1F2F2"
            />
            <path
              d="M51.4962 50.503C39.1696 62.8298 31.4191 79.7325 31.0111 98.4444H1.00503C1.41672 71.4477 12.5265 47.0453 30.2825 29.2893L51.4962 50.503Z"
              fill="#F1F2F2"
            />
            <path
              d="M0.994141 100.444H30.9955C31.1146 119.598 38.9261 136.927 51.4962 149.497L30.2825 170.711C12.2832 152.711 1.11353 127.882 0.994141 100.444Z"
              fill="#F1F2F2"
            />
            <animate
              attributeName="visibility"
              values={frameByFrame(4, 9)}
              dur="2s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path
              d="M104.716 132.583H104.26C100.596 132.6 96.953 132.038 93.464 130.917C89.8549 129.673 86.4612 127.872 83.4049 125.58C80.1309 122.968 77.4879 119.648 75.6735 115.868C73.5242 111.223 72.4983 106.135 72.6792 101.017C72.6633 96.6561 73.535 92.3373 75.2408 88.3255C76.9215 84.5057 79.302 81.0361 82.2587 78.0969C85.2108 75.1483 88.739 72.844 92.6218 71.3286C99.0647 68.6758 106.176 68.1219 112.95 69.7451C115.492 70.3298 117.94 71.2654 120.226 72.5251C122.372 73.7943 124.223 75.5103 125.653 77.5573C127.145 79.7274 127.922 82.3116 127.875 84.9472C127.897 85.8756 127.736 86.7993 127.401 87.665C127.066 88.5306 126.563 89.3212 125.922 89.9911C124.399 91.3505 122.402 92.0505 120.366 91.9383C118.751 91.9235 117.157 91.5678 115.687 90.8943C114.951 90.5711 114.332 90.0286 113.913 89.3403C113.495 88.6521 113.297 87.8514 113.348 87.0469C113.392 85.3995 113.72 83.7723 114.319 82.2376C114.859 80.8118 115.186 79.3137 115.29 77.7919C115.283 76.8196 114.997 75.8698 114.466 75.0563C113.935 74.2428 113.181 73.6002 112.295 73.2054C110.097 72.0531 107.64 71.4838 105.16 71.5515C103.219 71.5515 97.2069 71.8565 93.23 75.9855C91.4288 77.9444 90.0135 80.959 88.8789 84.9472C87.5901 90.3418 87.0121 95.8826 87.1595 101.428C87.1316 105.152 87.3818 108.873 87.9081 112.56C88.3347 115.693 89.2463 118.741 90.61 121.592V121.662C93.6043 127.832 101.558 128.7 106.061 128.7C110.728 128.6 115.21 126.856 118.724 123.774C122.237 120.692 124.557 116.469 125.279 111.844H129.326C126.472 125.885 117.021 132.43 104.716 132.583Z"
              fill="#F1F2F2"
            />
            <path
              d="M31.7088 172.111C49.6813 189.383 74.0964 200 100.991 200C101.139 200 101.288 200 101.436 199.999V169.999C101.288 170 101.14 170 100.992 170C82.3814 170 65.4669 162.737 52.9285 150.891L31.7088 172.111Z"
              fill="#CECECE"
            />
            <path
              d="M103.436 199.971V169.958C121.794 169.328 138.358 161.629 150.489 149.498L171.702 170.711C154.141 188.272 130.079 199.332 103.436 199.971Z"
              fill="#F1F2F2"
            />
            <path
              d="M173.102 169.282L151.883 148.064C163.635 135.625 170.876 118.88 170.991 100.444H200.99C200.874 127.164 190.279 151.409 173.102 169.282Z"
              fill="#F1F2F2"
            />
            <path
              d="M200.979 98.4444H170.975C170.583 80.4477 163.398 64.1245 151.883 51.9363L173.102 30.7176C190.04 48.343 200.579 72.1644 200.979 98.4444Z"
              fill="#F1F2F2"
            />
            <path
              d="M171.702 29.2893L150.489 50.502C138.358 38.3708 121.794 30.6716 103.436 30.0418V0.0292917C130.079 0.668468 154.141 11.7284 171.702 29.2893Z"
              fill="#F1F2F2"
            />
            <path
              d="M100.992 30C82.3814 30 65.4669 37.2629 52.9285 49.1089L31.7088 27.8892C49.6813 10.6173 74.0964 0 100.991 0C101.139 0 101.288 0.000322481 101.436 0.00096678V30.0014C101.288 30.0005 101.14 30 100.992 30Z"
              fill="#F1F2F2"
            />
            <path
              d="M51.4942 50.503C39.1677 62.8298 31.4171 79.7325 31.0091 98.4444H1.00307C1.41476 71.4477 12.5245 47.0453 30.2805 29.2893L51.4942 50.503Z"
              fill="#F1F2F2"
            />
            <path
              d="M0.992188 100.444H30.9936C31.1127 119.598 38.9242 136.927 51.4942 149.497L30.2805 170.711C12.2812 152.711 1.11158 127.882 0.992188 100.444Z"
              fill="#F1F2F2"
            />
            <animate
              attributeName="visibility"
              values={frameByFrame(5, 9)}
              dur="2s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path
              d="M103.724 132.583H103.268C99.6041 132.6 95.9608 132.038 92.4718 130.917C88.8627 129.673 85.469 127.872 82.4128 125.58C79.1387 122.968 76.4957 119.648 74.6814 115.868C72.532 111.223 71.5061 106.135 71.687 101.017C71.6711 96.6561 72.5428 92.3373 74.2486 88.3255C75.9293 84.5057 78.3098 81.0361 81.2665 78.0969C84.2186 75.1483 87.7468 72.844 91.6296 71.3286C98.0725 68.6758 105.184 68.1219 111.958 69.7451C114.5 70.3298 116.948 71.2654 119.233 72.5251C121.38 73.7943 123.231 75.5103 124.661 77.5573C126.153 79.7274 126.93 82.3116 126.883 84.9472C126.905 85.8756 126.744 86.7993 126.409 87.665C126.074 88.5306 125.571 89.3212 124.93 89.9911C123.407 91.3505 121.409 92.0505 119.374 91.9383C117.758 91.9235 116.164 91.5678 114.695 90.8943C113.959 90.5711 113.339 90.0286 112.921 89.3403C112.502 88.6521 112.305 87.8514 112.356 87.0469C112.4 85.3995 112.728 83.7723 113.327 82.2376C113.867 80.8118 114.194 79.3137 114.298 77.7919C114.291 76.8196 114.005 75.8698 113.474 75.0563C112.943 74.2428 112.189 73.6002 111.303 73.2054C109.104 72.0531 106.648 71.4838 104.168 71.5515C102.227 71.5515 96.2147 71.8565 92.2378 75.9855C90.4366 77.9444 89.0213 80.959 87.8867 84.9472C86.5979 90.3418 86.0199 95.8826 86.1674 101.428C86.1394 105.152 86.3896 108.873 86.9159 112.56C87.3425 115.693 88.2541 118.741 89.6178 121.592V121.662C92.6121 127.832 100.566 128.7 105.069 128.7C109.736 128.6 114.218 126.856 117.731 123.774C121.245 120.692 123.565 116.469 124.286 111.844H128.333C125.479 125.885 116.029 132.43 103.724 132.583Z"
              fill="#F1F2F2"
            />
            <path
              d="M30.7166 172.111C48.6891 189.383 73.1042 200 99.999 200C100.147 200 100.295 200 100.443 199.999V169.999C100.296 170 100.148 170 100 170C81.3892 170 64.4747 162.737 51.9363 150.891L30.7166 172.111Z"
              fill="#F1F2F2"
            />
            <path
              d="M102.443 199.971V169.958C120.802 169.328 137.365 161.629 149.497 149.498L170.71 170.711C153.149 188.272 129.087 199.332 102.443 199.971Z"
              fill="#F1F2F2"
            />
            <path
              d="M172.11 169.282L150.891 148.064C162.643 135.625 169.884 118.88 169.999 100.444H199.998C199.882 127.164 189.287 151.409 172.11 169.282Z"
              fill="#F1F2F2"
            />
            <path
              d="M199.987 98.4444H169.983C169.591 80.4477 162.406 64.1245 150.891 51.9363L172.11 30.7176C189.048 48.343 199.586 72.1644 199.987 98.4444Z"
              fill="#F1F2F2"
            />
            <path
              d="M170.71 29.2893L149.497 50.502C137.365 38.3708 120.802 30.6716 102.443 30.0418V0.0292917C129.087 0.668468 153.149 11.7284 170.71 29.2893Z"
              fill="#F1F2F2"
            />
            <path
              d="M100 30C81.3892 30 64.4747 37.2629 51.9363 49.1089L30.7166 27.8892C48.6891 10.6173 73.1042 0 99.999 0C100.147 0 100.295 0.000322481 100.443 0.00096678V30.0014C100.296 30.0005 100.148 30 100 30Z"
              fill="#F1F2F2"
            />
            <path
              d="M50.502 50.503C38.1755 62.8298 30.4249 79.7325 30.0169 98.4444H0.0108867C0.422576 71.4477 11.5323 47.0453 29.2884 29.2893L50.502 50.503Z"
              fill="#F1F2F2"
            />
            <path
              d="M0 100.444H30.0014C30.1205 119.598 37.932 136.927 50.502 149.497L29.2884 170.711C11.2891 152.711 0.119391 127.882 0 100.444Z"
              fill="#CECECE"
            />
            <animate
              attributeName="visibility"
              values={frameByFrame(6, 9)}
              dur="2s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path
              d="M104.712 132.583H104.256C100.592 132.6 96.9491 132.038 93.4601 130.917C89.851 129.673 86.4573 127.872 83.401 125.58C80.127 122.968 77.484 119.648 75.6696 115.868C73.5203 111.223 72.4944 106.135 72.6753 101.017C72.6594 96.6561 73.531 92.3373 75.2369 88.3255C76.9176 84.5057 79.2981 81.0361 82.2548 78.0969C85.2068 75.1483 88.7351 72.844 92.6179 71.3286C99.0608 68.6758 106.172 68.1219 112.946 69.7451C115.488 70.3298 117.937 71.2654 120.222 72.5251C122.369 73.7943 124.219 75.5103 125.649 77.5573C127.141 79.7274 127.918 82.3116 127.871 84.9472C127.894 85.8756 127.732 86.7993 127.397 87.665C127.062 88.5306 126.559 89.3212 125.918 89.9911C124.395 91.3505 122.398 92.0505 120.362 91.9383C118.747 91.9235 117.153 91.5678 115.683 90.8943C114.947 90.5711 114.328 90.0286 113.909 89.3403C113.491 88.6521 113.293 87.8514 113.344 87.0469C113.388 85.3995 113.716 83.7723 114.315 82.2376C114.855 80.8118 115.182 79.3137 115.286 77.7919C115.279 76.8196 114.993 75.8698 114.462 75.0563C113.931 74.2428 113.177 73.6002 112.291 73.2054C110.093 72.0531 107.637 71.4838 105.157 71.5515C103.215 71.5515 97.2029 71.8565 93.2261 75.9855C91.4249 77.9444 90.0096 80.959 88.875 84.9472C87.5862 90.3418 87.0082 95.8826 87.1556 101.428C87.1277 105.152 87.3779 108.873 87.9042 112.56C88.3308 115.693 89.2424 118.741 90.6061 121.592V121.662C93.6004 127.832 101.554 128.7 106.057 128.7C110.724 128.6 115.206 126.856 118.72 123.774C122.233 120.692 124.554 116.469 125.275 111.844H129.322C126.468 125.885 117.017 132.43 104.712 132.583Z"
              fill="#F1F2F2"
            />
            <path
              d="M31.7049 172.111C49.6774 189.383 74.0925 200 100.987 200C101.136 200 101.284 200 101.432 199.999V169.999C101.284 170 101.136 170 100.988 170C82.3775 170 65.463 162.737 52.9246 150.891L31.7049 172.111Z"
              fill="#F1F2F2"
            />
            <path
              d="M103.432 199.971V169.958C121.79 169.328 138.354 161.629 150.485 149.498L171.698 170.711C154.137 188.272 130.075 199.332 103.432 199.971Z"
              fill="#F1F2F2"
            />
            <path
              d="M173.098 169.282L151.879 148.064C163.631 135.625 170.872 118.88 170.987 100.444H200.986C200.87 127.164 190.275 151.409 173.098 169.282Z"
              fill="#F1F2F2"
            />
            <path
              d="M200.975 98.4444H170.971C170.579 80.4477 163.395 64.1245 151.879 51.9363L173.098 30.7176C190.036 48.343 200.575 72.1644 200.975 98.4444Z"
              fill="#F1F2F2"
            />
            <path
              d="M171.698 29.2893L150.485 50.502C138.354 38.3708 121.79 30.6716 103.432 30.0418V0.0292917C130.075 0.668468 154.137 11.7284 171.698 29.2893Z"
              fill="#F1F2F2"
            />
            <path
              d="M100.988 30C82.3775 30 65.463 37.2629 52.9246 49.1089L31.7049 27.8892C49.6774 10.6173 74.0925 0 100.987 0C101.136 0 101.284 0.000322481 101.432 0.00096678V30.0014C101.284 30.0005 101.136 30 100.988 30Z"
              fill="#F1F2F2"
            />
            <path
              d="M51.4903 50.503C39.1638 62.8298 31.4132 79.7325 31.0052 98.4444H0.999168C1.41086 71.4477 12.5206 47.0453 30.2766 29.2893L51.4903 50.503Z"
              fill="#CECECE"
            />
            <path
              d="M0.988281 100.444H30.9897C31.1087 119.598 38.9203 136.927 51.4903 149.497L30.2766 170.711C12.2773 152.711 1.10767 127.882 0.988281 100.444Z"
              fill="#F1F2F2"
            />
            <animate
              attributeName="visibility"
              values={frameByFrame(7, 9)}
              dur="2s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path
              d="M103.724 132.583H103.268C99.6041 132.6 95.9608 132.038 92.4718 130.917C88.8627 129.673 85.469 127.872 82.4128 125.58C79.1387 122.968 76.4957 119.648 74.6814 115.868C72.532 111.223 71.5061 106.135 71.687 101.017C71.6711 96.6561 72.5428 92.3373 74.2486 88.3255C75.9293 84.5057 78.3098 81.0361 81.2665 78.0969C84.2186 75.1483 87.7468 72.844 91.6296 71.3286C98.0725 68.6758 105.184 68.1219 111.958 69.7451C114.5 70.3298 116.948 71.2654 119.233 72.5251C121.38 73.7943 123.231 75.5103 124.661 77.5573C126.153 79.7274 126.93 82.3116 126.883 84.9472C126.905 85.8756 126.744 86.7993 126.409 87.665C126.074 88.5306 125.571 89.3212 124.93 89.9911C123.407 91.3505 121.409 92.0505 119.374 91.9383C117.758 91.9235 116.164 91.5678 114.695 90.8943C113.959 90.5711 113.339 90.0286 112.921 89.3403C112.502 88.6521 112.305 87.8514 112.356 87.0469C112.4 85.3995 112.728 83.7723 113.327 82.2376C113.867 80.8118 114.194 79.3137 114.298 77.7919C114.291 76.8196 114.005 75.8698 113.474 75.0563C112.943 74.2428 112.189 73.6002 111.303 73.2054C109.104 72.0531 106.648 71.4838 104.168 71.5515C102.227 71.5515 96.2147 71.8565 92.2378 75.9855C90.4366 77.9444 89.0213 80.959 87.8867 84.9472C86.5979 90.3418 86.0199 95.8826 86.1674 101.428C86.1394 105.152 86.3896 108.873 86.9159 112.56C87.3425 115.693 88.2541 118.741 89.6178 121.592V121.662C92.6121 127.832 100.566 128.7 105.069 128.7C109.736 128.6 114.218 126.856 117.731 123.774C121.245 120.692 123.565 116.469 124.286 111.844H128.333C125.479 125.885 116.029 132.43 103.724 132.583Z"
              fill="#F1F2F2"
            />
            <path
              d="M30.7166 172.111C48.6891 189.383 73.1042 200 99.999 200C100.147 200 100.295 200 100.443 199.999V169.999C100.296 170 100.148 170 100 170C81.3892 170 64.4747 162.737 51.9363 150.891L30.7166 172.111Z"
              fill="#F1F2F2"
            />
            <path
              d="M102.443 199.971V169.958C120.802 169.328 137.365 161.629 149.497 149.498L170.71 170.711C153.149 188.272 129.087 199.332 102.443 199.971Z"
              fill="#F1F2F2"
            />
            <path
              d="M172.11 169.282L150.891 148.064C162.643 135.625 169.884 118.88 169.999 100.444H199.998C199.882 127.164 189.287 151.409 172.11 169.282Z"
              fill="#F1F2F2"
            />
            <path
              d="M199.987 98.4444H169.983C169.591 80.4477 162.406 64.1245 150.891 51.9363L172.11 30.7176C189.048 48.343 199.586 72.1644 199.987 98.4444Z"
              fill="#F1F2F2"
            />
            <path
              d="M170.71 29.2893L149.497 50.502C137.365 38.3708 120.802 30.6716 102.443 30.0418V0.0292917C129.087 0.668468 153.149 11.7284 170.71 29.2893Z"
              fill="#F1F2F2"
            />
            <path
              d="M100 30C81.3892 30 64.4747 37.2629 51.9363 49.1089L30.7166 27.8892C48.6891 10.6173 73.1042 0 99.999 0C100.147 0 100.295 0.000322481 100.443 0.00096678V30.0014C100.296 30.0005 100.148 30 100 30Z"
              fill="#CECECE"
            />
            <path
              d="M50.502 50.503C38.1755 62.8298 30.4249 79.7325 30.0169 98.4444H0.0108867C0.422576 71.4477 11.5323 47.0453 29.2884 29.2893L50.502 50.503Z"
              fill="#F1F2F2"
            />
            <path
              d="M0 100.444H30.0014C30.1205 119.598 37.932 136.927 50.502 149.497L29.2884 170.711C11.2891 152.711 0.119391 127.882 0 100.444Z"
              fill="#F1F2F2"
            />
            <animate
              attributeName="visibility"
              values={frameByFrame(8, 9)}
              dur="2s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path
              d="M79 133C79 131.9 78.1 131 77 131C75.9 131 75 131.8 75 133C75 134.1 75.9 135 77 135C78.1 134.9 79 134.1 79 133ZM77 134.5C76.1 134.5 75.4 133.8 75.4 132.9C75.4 132 76.1 131.3 77 131.3C77.9 131.3 78.6 132 78.6 132.9C78.6 133.9 77.9 134.5 77 134.5Z"
              fill="#1A1919"
            />
            <path
              d="M77.8 132.3C77.8 131.8 77.6 131.6 77.1 131.6H76.3V134H76.6V133H77L77.5 134H77.8L77.2 133C77.6 132.9 77.8 132.8 77.8 132.3ZM77 132.7H76.7V132H77C77.2 132 77.4 132.1 77.4 132.4C77.4 132.7 77.2 132.7 77 132.7Z"
              fill="#1A1919"
            />
            <path
              d="M100 168C137.555 168 168 137.555 168 100C168 62.4446 137.555 32 100 32C62.4446 32 32 62.4446 32 100C32 137.555 62.4446 168 100 168Z"
              fill="#F1F2F2"
            />
            <path
              d="M103.724 132.583H103.268C99.6041 132.6 95.9608 132.038 92.4718 130.917C88.8627 129.673 85.469 127.872 82.4128 125.58C79.1387 122.968 76.4957 119.648 74.6814 115.868C72.532 111.223 71.5061 106.135 71.687 101.017C71.6711 96.6561 72.5428 92.3373 74.2486 88.3255C75.9293 84.5057 78.3098 81.0361 81.2665 78.0969C84.2186 75.1483 87.7468 72.844 91.6296 71.3286C98.0725 68.6758 105.184 68.1219 111.958 69.7451C114.5 70.3298 116.948 71.2654 119.233 72.5251C121.38 73.7943 123.231 75.5103 124.661 77.5573C126.153 79.7274 126.93 82.3116 126.883 84.9472C126.905 85.8756 126.744 86.7993 126.409 87.665C126.074 88.5306 125.571 89.3212 124.93 89.9911C123.407 91.3505 121.409 92.0505 119.374 91.9383C117.758 91.9235 116.164 91.5678 114.695 90.8943C113.959 90.5711 113.339 90.0286 112.921 89.3403C112.502 88.6521 112.305 87.8514 112.356 87.0469C112.4 85.3995 112.728 83.7723 113.327 82.2376C113.867 80.8118 114.194 79.3137 114.298 77.7919C114.291 76.8196 114.005 75.8698 113.474 75.0563C112.943 74.2428 112.189 73.6002 111.303 73.2054C109.104 72.0531 106.648 71.4838 104.168 71.5515C102.227 71.5515 96.2147 71.8565 92.2378 75.9855C90.4366 77.9444 89.0213 80.959 87.8867 84.9472C86.5979 90.3418 86.0199 95.8826 86.1674 101.428C86.1394 105.152 86.3896 108.873 86.9159 112.56C87.3425 115.693 88.2541 118.741 89.6178 121.592V121.662C92.6121 127.832 100.566 128.7 105.069 128.7C109.736 128.6 114.218 126.856 117.731 123.774C121.245 120.692 123.565 116.469 124.286 111.844H128.333C125.479 125.885 116.029 132.43 103.724 132.583Z"
              fill="#FDFEFF"
            />
            <path
              d="M30.7166 172.111C48.6891 189.383 73.1042 200 99.999 200C100.147 200 100.295 200 100.443 199.999V169.999C100.296 170 100.148 170 100 170C81.3892 170 64.4747 162.737 51.9363 150.891L30.7166 172.111Z"
              fill="#F1F2F2"
            />
            <path
              d="M102.443 199.971V169.958C120.802 169.328 137.365 161.629 149.497 149.498L170.71 170.711C153.149 188.272 129.087 199.332 102.443 199.971Z"
              fill="#F1F2F2"
            />
            <path
              d="M172.11 169.282L150.891 148.064C162.643 135.625 169.884 118.88 169.999 100.444H199.998C199.882 127.164 189.287 151.409 172.11 169.282Z"
              fill="#F1F2F2"
            />
            <path
              d="M199.987 98.4444H169.983C169.591 80.4477 162.406 64.1245 150.891 51.9363L172.11 30.7176C189.048 48.343 199.586 72.1644 199.987 98.4444Z"
              fill="#F1F2F2"
            />
            <path
              d="M170.71 29.2893L149.497 50.502C137.365 38.3708 120.802 30.6716 102.443 30.0418V0.0292917C129.087 0.668468 153.149 11.7284 170.71 29.2893Z"
              fill="#F1F2F2"
            />
            <path
              d="M100 30C81.3892 30 64.4747 37.2629 51.9363 49.1089L30.7166 27.8892C48.6891 10.6173 73.1042 0 99.999 0C100.147 0 100.295 0.000322481 100.443 0.00096678V30.0014C100.296 30.0005 100.148 30 100 30Z"
              fill="#F1F2F2"
            />
            <path
              d="M50.502 50.503C38.1755 62.8298 30.4249 79.7325 30.0169 98.4444H0.0108867C0.422576 71.4477 11.5323 47.0453 29.2884 29.2893L50.502 50.503Z"
              fill="#F1F2F2"
            />
            <path
              d="M0 100.444H30.0014C30.1205 119.598 37.932 136.927 50.502 149.497L29.2884 170.711C11.2891 152.711 0.119391 127.882 0 100.444Z"
              fill="#F1F2F2"
            />
            <animate
              attributeName="visibility"
              values={frameByFrame(9, 9)}
              dur="2s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
        </StyledTransitionContainer>
      )
    case 'linear-flow':
      return (
        <StyledLinearFlowContainer>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="92" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="128" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(1, 5)}
              dur="2.4s"
              begin="0"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="12" cy="16" r="12" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="92" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="128" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(2, 5)}
              dur="2.4s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="12" cy="16" r="12" fill="#1C4DA1" />
            <circle cx="48" cy="16" r="12" fill="#005D30" />
            <circle cx="92" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="128" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(3, 5)}
              dur="2.4s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="12" cy="16" r="12" fill="#1C4DA1" />
            <circle cx="48" cy="16" r="12" fill="#005D30" />
            <circle cx="84" cy="16" r="12" fill="#D51C29" />
            <circle cx="128" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(4, 5)}
              dur="2.4s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="12" cy="16" r="12" fill="#1C4DA1" />
            <circle cx="48" cy="16" r="12" fill="#005D30" />
            <circle cx="84" cy="16" r="12" fill="#D51C29" />
            <circle cx="120" cy="16" r="12" fill="#FDB930" />
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(5, 5)}
              dur="2.4s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Clearly Icon */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path fill="black">
              <animate
                attributeName="d"
                dur="2.4s"
                values="
                M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z;
                M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z;
                M52 32C60.8366 32 68 24.8366 68 16C68 7.16344 60.8366 0 52 0C43.1634 0 36 7.16344 36 16C36 24.8366 43.1634 32 52 32Z;
                M52 32C60.8366 32 68 24.8366 68 16C68 7.16344 60.8366 0 52 0C43.1634 0 36 7.16344 36 16C36 24.8366 43.1634 32 52 32Z;
                M88 32C96.8366 32 104 24.8366 104 16C104 7.16344 96.8366 0 88 0C79.1634 0 72 7.16344 72 16C72 24.8366 79.1634 32 88 32Z;
                M88 32C96.8366 32 104 24.8366 104 16C104 7.16344 96.8366 0 88 0C79.1634 0 72 7.16344 72 16C72 24.8366 79.1634 32 88 32Z;
                M124 32C132.837 32 140 24.8366 140 16C140 7.16344 132.837 0 124 0C115.163 0 108 7.16344 108 16C108 24.8366 115.163 32 124 32Z;
                M124 32C132.837 32 140 24.8366 140 16C140 7.16344 132.837 0 124 0C115.163 0 108 7.16344 108 16C108 24.8366 115.163 32 124 32Z;
                M160 32C168.837 32 176 24.8366 176 16C176 7.16344 168.837 0 160 0C151.163 0 144 7.16344 144 16C144 24.8366 151.163 32 160 32Z;
                M160 32C168.837 32 176 24.8366 176 16C176 7.16344 168.837 0 160 0C151.163 0 144 7.16344 144 16C144 24.8366 151.163 32 160 32Z
                "
                keyTimes="0;0.11;0.22;0.33;0.44;0.55;0.66;0.77;0.88;1"
                begin="0s"
                repeatCount="indefinite"
              />
            </path>
            <path fill="#FDFEFF">
              <animate
                attributeName="d"
                dur="2.4s"
                values="
                M16.8762 23.6665H16.7688C15.9068 23.6705 15.0496 23.5383 14.2286 23.2746C13.3794 22.9818 12.5809 22.5582 11.8618 22.0188C11.0914 21.4043 10.4696 20.623 10.0427 19.7335C9.53693 18.6406 9.29553 17.4434 9.33811 16.2393C9.33436 15.2131 9.53945 14.197 9.94082 13.253C10.3363 12.3542 10.8964 11.5379 11.5921 10.8463C12.2867 10.1525 13.1169 9.6103 14.0305 9.25375C15.5465 8.62957 17.2198 8.49922 18.8137 8.88115C19.4117 9.01874 19.9878 9.23888 20.5255 9.53527C21.0306 9.83392 21.4661 10.2377 21.8025 10.7193C22.1536 11.2299 22.3364 11.838 22.3254 12.4581C22.3306 12.6766 22.2927 12.8939 22.2139 13.0976C22.135 13.3013 22.0167 13.4873 21.8658 13.6449C21.5074 13.9648 21.0375 14.1295 20.5585 14.1031C20.1784 14.0996 19.8034 14.0159 19.4577 13.8575C19.2844 13.7814 19.1387 13.6537 19.0402 13.4918C18.9417 13.3299 18.8953 13.1415 18.9072 12.9522C18.9175 12.5646 18.9948 12.1817 19.1357 11.8206C19.2628 11.4851 19.3398 11.1326 19.3641 10.7745C19.3626 10.5457 19.2953 10.3223 19.1703 10.1309C19.0454 9.93945 18.868 9.78825 18.6596 9.69536C18.1422 9.42423 17.5643 9.29026 16.9808 9.3062C16.5239 9.3062 15.1093 9.37796 14.1736 10.3495C13.7498 10.8104 13.4168 11.5197 13.1498 12.4581C12.8466 13.7274 12.7105 15.0312 12.7452 16.3359C12.7387 17.2122 12.7975 18.0877 12.9214 18.9552C13.0217 19.6925 13.2362 20.4095 13.5571 21.0804V21.097C14.2617 22.5487 16.1331 22.753 17.1927 22.753C18.2907 22.7293 19.3454 22.3189 20.1721 21.5938C20.9988 20.8687 21.5448 19.875 21.7144 18.7868H22.6666C21.9951 22.0906 19.7714 23.6307 16.8762 23.6665Z;
                M16.8762 23.6665H16.7688C15.9068 23.6705 15.0496 23.5383 14.2286 23.2746C13.3794 22.9818 12.5809 22.5582 11.8618 22.0188C11.0914 21.4043 10.4696 20.623 10.0427 19.7335C9.53693 18.6406 9.29553 17.4434 9.33811 16.2393C9.33436 15.2131 9.53945 14.197 9.94082 13.253C10.3363 12.3542 10.8964 11.5379 11.5921 10.8463C12.2867 10.1525 13.1169 9.6103 14.0305 9.25375C15.5465 8.62957 17.2198 8.49922 18.8137 8.88115C19.4117 9.01874 19.9878 9.23888 20.5255 9.53527C21.0306 9.83392 21.4661 10.2377 21.8025 10.7193C22.1536 11.2299 22.3364 11.838 22.3254 12.4581C22.3306 12.6766 22.2927 12.8939 22.2139 13.0976C22.135 13.3013 22.0167 13.4873 21.8658 13.6449C21.5074 13.9648 21.0375 14.1295 20.5585 14.1031C20.1784 14.0996 19.8034 14.0159 19.4577 13.8575C19.2844 13.7814 19.1387 13.6537 19.0402 13.4918C18.9417 13.3299 18.8953 13.1415 18.9072 12.9522C18.9175 12.5646 18.9948 12.1817 19.1357 11.8206C19.2628 11.4851 19.3398 11.1326 19.3641 10.7745C19.3626 10.5457 19.2953 10.3223 19.1703 10.1309C19.0454 9.93945 18.868 9.78825 18.6596 9.69536C18.1422 9.42423 17.5643 9.29026 16.9808 9.3062C16.5239 9.3062 15.1093 9.37796 14.1736 10.3495C13.7498 10.8104 13.4168 11.5197 13.1498 12.4581C12.8466 13.7274 12.7105 15.0312 12.7452 16.3359C12.7387 17.2122 12.7975 18.0877 12.9214 18.9552C13.0217 19.6925 13.2362 20.4095 13.5571 21.0804V21.097C14.2617 22.5487 16.1331 22.753 17.1927 22.753C18.2907 22.7293 19.3454 22.3189 20.1721 21.5938C20.9988 20.8687 21.5448 19.875 21.7144 18.7868H22.6666C21.9951 22.0906 19.7714 23.6307 16.8762 23.6665Z;
                M52.8762 23.6665H52.7689C51.9069 23.6705 51.0496 23.5383 50.2287 23.2746C49.3795 22.9818 48.581 22.5582 47.8619 22.0188C47.0915 21.4043 46.4696 20.623 46.0427 19.7335C45.537 18.6406 45.2956 17.4434 45.3382 16.2393C45.3344 15.2131 45.5395 14.197 45.9409 13.253C46.3363 12.3542 46.8965 11.5379 47.5922 10.8463C48.2868 10.1525 49.1169 9.6103 50.0305 9.25375C51.5465 8.62957 53.2198 8.49922 54.8137 8.88115C55.4118 9.01874 55.9879 9.23888 56.5256 9.53527C57.0307 9.83392 57.4661 10.2377 57.8025 10.7193C58.1536 11.2299 58.3365 11.838 58.3254 12.4581C58.3307 12.6766 58.2928 12.8939 58.2139 13.0976C58.135 13.3013 58.0167 13.4873 57.8658 13.6449C57.5075 13.9648 57.0376 14.1295 56.5586 14.1031C56.1785 14.0996 55.8034 14.0159 55.4577 13.8575C55.2845 13.7814 55.1387 13.6537 55.0403 13.4918C54.9418 13.3299 54.8954 13.1415 54.9073 12.9522C54.9176 12.5646 54.9949 12.1817 55.1357 11.8206C55.2629 11.4851 55.3398 11.1326 55.3642 10.7745C55.3626 10.5457 55.2954 10.3223 55.1704 10.1309C55.0454 9.93945 54.8681 9.78825 54.6596 9.69536C54.1422 9.42423 53.5643 9.29026 52.9808 9.3062C52.524 9.3062 51.1094 9.37796 50.1737 10.3495C49.7498 10.8104 49.4168 11.5197 49.1499 12.4581C48.8466 13.7274 48.7106 15.0312 48.7453 16.3359C48.7387 17.2122 48.7976 18.0877 48.9214 18.9552C49.0218 19.6925 49.2363 20.4095 49.5572 21.0804V21.097C50.2617 22.5487 52.1332 22.753 53.1927 22.753C54.2908 22.7293 55.3455 22.3189 56.1721 21.5938C56.9988 20.8687 57.5448 19.875 57.7145 18.7868H58.6667C57.9952 22.0906 55.7715 23.6307 52.8762 23.6665Z;
                M52.8762 23.6665H52.7689C51.9069 23.6705 51.0496 23.5383 50.2287 23.2746C49.3795 22.9818 48.581 22.5582 47.8619 22.0188C47.0915 21.4043 46.4696 20.623 46.0427 19.7335C45.537 18.6406 45.2956 17.4434 45.3382 16.2393C45.3344 15.2131 45.5395 14.197 45.9409 13.253C46.3363 12.3542 46.8965 11.5379 47.5922 10.8463C48.2868 10.1525 49.1169 9.6103 50.0305 9.25375C51.5465 8.62957 53.2198 8.49922 54.8137 8.88115C55.4118 9.01874 55.9879 9.23888 56.5256 9.53527C57.0307 9.83392 57.4661 10.2377 57.8025 10.7193C58.1536 11.2299 58.3365 11.838 58.3254 12.4581C58.3307 12.6766 58.2928 12.8939 58.2139 13.0976C58.135 13.3013 58.0167 13.4873 57.8658 13.6449C57.5075 13.9648 57.0376 14.1295 56.5586 14.1031C56.1785 14.0996 55.8034 14.0159 55.4577 13.8575C55.2845 13.7814 55.1387 13.6537 55.0403 13.4918C54.9418 13.3299 54.8954 13.1415 54.9073 12.9522C54.9176 12.5646 54.9949 12.1817 55.1357 11.8206C55.2629 11.4851 55.3398 11.1326 55.3642 10.7745C55.3626 10.5457 55.2954 10.3223 55.1704 10.1309C55.0454 9.93945 54.8681 9.78825 54.6596 9.69536C54.1422 9.42423 53.5643 9.29026 52.9808 9.3062C52.524 9.3062 51.1094 9.37796 50.1737 10.3495C49.7498 10.8104 49.4168 11.5197 49.1499 12.4581C48.8466 13.7274 48.7106 15.0312 48.7453 16.3359C48.7387 17.2122 48.7976 18.0877 48.9214 18.9552C49.0218 19.6925 49.2363 20.4095 49.5572 21.0804V21.097C50.2617 22.5487 52.1332 22.753 53.1927 22.753C54.2908 22.7293 55.3455 22.3189 56.1721 21.5938C56.9988 20.8687 57.5448 19.875 57.7145 18.7868H58.6667C57.9952 22.0906 55.7715 23.6307 52.8762 23.6665Z;
                M88.8762 23.6665H88.7689C87.9069 23.6705 87.0496 23.5383 86.2287 23.2746C85.3795 22.9818 84.581 22.5582 83.8619 22.0188C83.0915 21.4043 82.4696 20.623 82.0427 19.7335C81.537 18.6406 81.2956 17.4434 81.3382 16.2393C81.3344 15.2131 81.5395 14.197 81.9409 13.253C82.3363 12.3542 82.8965 11.5379 83.5922 10.8463C84.2868 10.1525 85.1169 9.6103 86.0305 9.25375C87.5465 8.62957 89.2198 8.49922 90.8137 8.88115C91.4118 9.01874 91.9879 9.23888 92.5256 9.53527C93.0307 9.83392 93.4661 10.2377 93.8025 10.7193C94.1536 11.2299 94.3365 11.838 94.3254 12.4581C94.3307 12.6766 94.2928 12.8939 94.2139 13.0976C94.135 13.3013 94.0167 13.4873 93.8658 13.6449C93.5075 13.9648 93.0376 14.1295 92.5586 14.1031C92.1785 14.0996 91.8034 14.0159 91.4577 13.8575C91.2845 13.7814 91.1387 13.6537 91.0403 13.4918C90.9418 13.3299 90.8954 13.1415 90.9073 12.9522C90.9176 12.5646 90.9949 12.1817 91.1357 11.8206C91.2629 11.4851 91.3398 11.1326 91.3642 10.7745C91.3626 10.5457 91.2954 10.3223 91.1704 10.1309C91.0454 9.93945 90.8681 9.78825 90.6596 9.69536C90.1422 9.42423 89.5643 9.29026 88.9808 9.3062C88.524 9.3062 87.1094 9.37796 86.1737 10.3495C85.7498 10.8104 85.4168 11.5197 85.1499 12.4581C84.8466 13.7274 84.7106 15.0312 84.7453 16.3359C84.7387 17.2122 84.7976 18.0877 84.9214 18.9552C85.0218 19.6925 85.2363 20.4095 85.5572 21.0804V21.097C86.2617 22.5487 88.1332 22.753 89.1927 22.753C90.2908 22.7293 91.3455 22.3189 92.1721 21.5938C92.9988 20.8687 93.5448 19.875 93.7145 18.7868H94.6667C93.9952 22.0906 91.7715 23.6307 88.8762 23.6665Z;
                M88.8762 23.6665H88.7689C87.9069 23.6705 87.0496 23.5383 86.2287 23.2746C85.3795 22.9818 84.581 22.5582 83.8619 22.0188C83.0915 21.4043 82.4696 20.623 82.0427 19.7335C81.537 18.6406 81.2956 17.4434 81.3382 16.2393C81.3344 15.2131 81.5395 14.197 81.9409 13.253C82.3363 12.3542 82.8965 11.5379 83.5922 10.8463C84.2868 10.1525 85.1169 9.6103 86.0305 9.25375C87.5465 8.62957 89.2198 8.49922 90.8137 8.88115C91.4118 9.01874 91.9879 9.23888 92.5256 9.53527C93.0307 9.83392 93.4661 10.2377 93.8025 10.7193C94.1536 11.2299 94.3365 11.838 94.3254 12.4581C94.3307 12.6766 94.2928 12.8939 94.2139 13.0976C94.135 13.3013 94.0167 13.4873 93.8658 13.6449C93.5075 13.9648 93.0376 14.1295 92.5586 14.1031C92.1785 14.0996 91.8034 14.0159 91.4577 13.8575C91.2845 13.7814 91.1387 13.6537 91.0403 13.4918C90.9418 13.3299 90.8954 13.1415 90.9073 12.9522C90.9176 12.5646 90.9949 12.1817 91.1357 11.8206C91.2629 11.4851 91.3398 11.1326 91.3642 10.7745C91.3626 10.5457 91.2954 10.3223 91.1704 10.1309C91.0454 9.93945 90.8681 9.78825 90.6596 9.69536C90.1422 9.42423 89.5643 9.29026 88.9808 9.3062C88.524 9.3062 87.1094 9.37796 86.1737 10.3495C85.7498 10.8104 85.4168 11.5197 85.1499 12.4581C84.8466 13.7274 84.7106 15.0312 84.7453 16.3359C84.7387 17.2122 84.7976 18.0877 84.9214 18.9552C85.0218 19.6925 85.2363 20.4095 85.5572 21.0804V21.097C86.2617 22.5487 88.1332 22.753 89.1927 22.753C90.2908 22.7293 91.3455 22.3189 92.1721 21.5938C92.9988 20.8687 93.5448 19.875 93.7145 18.7868H94.6667C93.9952 22.0906 91.7715 23.6307 88.8762 23.6665Z;
                M124.876 23.6665H124.769C123.907 23.6705 123.05 23.5383 122.229 23.2746C121.38 22.9818 120.581 22.5582 119.862 22.0188C119.091 21.4043 118.47 20.623 118.043 19.7335C117.537 18.6406 117.296 17.4434 117.338 16.2393C117.334 15.2131 117.54 14.197 117.941 13.253C118.336 12.3542 118.896 11.5379 119.592 10.8463C120.287 10.1525 121.117 9.6103 122.031 9.25375C123.547 8.62957 125.22 8.49922 126.814 8.88115C127.412 9.01874 127.988 9.23888 128.526 9.53527C129.031 9.83392 129.466 10.2377 129.803 10.7193C130.154 11.2299 130.336 11.838 130.325 12.4581C130.331 12.6766 130.293 12.8939 130.214 13.0976C130.135 13.3013 130.017 13.4873 129.866 13.6449C129.508 13.9648 129.038 14.1295 128.559 14.1031C128.179 14.0996 127.803 14.0159 127.458 13.8575C127.284 13.7814 127.139 13.6537 127.04 13.4918C126.942 13.3299 126.895 13.1415 126.907 12.9522C126.918 12.5646 126.995 12.1817 127.136 11.8206C127.263 11.4851 127.34 11.1326 127.364 10.7745C127.363 10.5457 127.295 10.3223 127.17 10.1309C127.045 9.93945 126.868 9.78825 126.66 9.69536C126.142 9.42423 125.564 9.29026 124.981 9.3062C124.524 9.3062 123.109 9.37796 122.174 10.3495C121.75 10.8104 121.417 11.5197 121.15 12.4581C120.847 13.7274 120.711 15.0312 120.745 16.3359C120.739 17.2122 120.798 18.0877 120.921 18.9552C121.022 19.6925 121.236 20.4095 121.557 21.0804V21.097C122.262 22.5487 124.133 22.753 125.193 22.753C126.291 22.7293 127.345 22.3189 128.172 21.5938C128.999 20.8687 129.545 19.875 129.714 18.7868H130.667C129.995 22.0906 127.771 23.6307 124.876 23.6665Z;
                M124.876 23.6665H124.769C123.907 23.6705 123.05 23.5383 122.229 23.2746C121.38 22.9818 120.581 22.5582 119.862 22.0188C119.091 21.4043 118.47 20.623 118.043 19.7335C117.537 18.6406 117.296 17.4434 117.338 16.2393C117.334 15.2131 117.54 14.197 117.941 13.253C118.336 12.3542 118.896 11.5379 119.592 10.8463C120.287 10.1525 121.117 9.6103 122.031 9.25375C123.547 8.62957 125.22 8.49922 126.814 8.88115C127.412 9.01874 127.988 9.23888 128.526 9.53527C129.031 9.83392 129.466 10.2377 129.803 10.7193C130.154 11.2299 130.336 11.838 130.325 12.4581C130.331 12.6766 130.293 12.8939 130.214 13.0976C130.135 13.3013 130.017 13.4873 129.866 13.6449C129.508 13.9648 129.038 14.1295 128.559 14.1031C128.179 14.0996 127.803 14.0159 127.458 13.8575C127.284 13.7814 127.139 13.6537 127.04 13.4918C126.942 13.3299 126.895 13.1415 126.907 12.9522C126.918 12.5646 126.995 12.1817 127.136 11.8206C127.263 11.4851 127.34 11.1326 127.364 10.7745C127.363 10.5457 127.295 10.3223 127.17 10.1309C127.045 9.93945 126.868 9.78825 126.66 9.69536C126.142 9.42423 125.564 9.29026 124.981 9.3062C124.524 9.3062 123.109 9.37796 122.174 10.3495C121.75 10.8104 121.417 11.5197 121.15 12.4581C120.847 13.7274 120.711 15.0312 120.745 16.3359C120.739 17.2122 120.798 18.0877 120.921 18.9552C121.022 19.6925 121.236 20.4095 121.557 21.0804V21.097C122.262 22.5487 124.133 22.753 125.193 22.753C126.291 22.7293 127.345 22.3189 128.172 21.5938C128.999 20.8687 129.545 19.875 129.714 18.7868H130.667C129.995 22.0906 127.771 23.6307 124.876 23.6665Z;
                M160.876 23.6665H160.769C159.907 23.6705 159.05 23.5383 158.229 23.2746C157.38 22.9818 156.581 22.5582 155.862 22.0188C155.091 21.4043 154.47 20.623 154.043 19.7335C153.537 18.6406 153.296 17.4434 153.338 16.2393C153.334 15.2131 153.54 14.197 153.941 13.253C154.336 12.3542 154.896 11.5379 155.592 10.8463C156.287 10.1525 157.117 9.6103 158.031 9.25375C159.547 8.62957 161.22 8.49922 162.814 8.88115C163.412 9.01874 163.988 9.23888 164.526 9.53527C165.031 9.83392 165.466 10.2377 165.803 10.7193C166.154 11.2299 166.336 11.838 166.325 12.4581C166.331 12.6766 166.293 12.8939 166.214 13.0976C166.135 13.3013 166.017 13.4873 165.866 13.6449C165.508 13.9648 165.038 14.1295 164.559 14.1031C164.179 14.0996 163.803 14.0159 163.458 13.8575C163.284 13.7814 163.139 13.6537 163.04 13.4918C162.942 13.3299 162.895 13.1415 162.907 12.9522C162.918 12.5646 162.995 12.1817 163.136 11.8206C163.263 11.4851 163.34 11.1326 163.364 10.7745C163.363 10.5457 163.295 10.3223 163.17 10.1309C163.045 9.93945 162.868 9.78825 162.66 9.69536C162.142 9.42423 161.564 9.29026 160.981 9.3062C160.524 9.3062 159.109 9.37796 158.174 10.3495C157.75 10.8104 157.417 11.5197 157.15 12.4581C156.847 13.7274 156.711 15.0312 156.745 16.3359C156.739 17.2122 156.798 18.0877 156.921 18.9552C157.022 19.6925 157.236 20.4095 157.557 21.0804V21.097C158.262 22.5487 160.133 22.753 161.193 22.753C162.291 22.7293 163.345 22.3189 164.172 21.5938C164.999 20.8687 165.545 19.875 165.714 18.7868H166.667C165.995 22.0906 163.771 23.6307 160.876 23.6665Z;
                M160.876 23.6665H160.769C159.907 23.6705 159.05 23.5383 158.229 23.2746C157.38 22.9818 156.581 22.5582 155.862 22.0188C155.091 21.4043 154.47 20.623 154.043 19.7335C153.537 18.6406 153.296 17.4434 153.338 16.2393C153.334 15.2131 153.54 14.197 153.941 13.253C154.336 12.3542 154.896 11.5379 155.592 10.8463C156.287 10.1525 157.117 9.6103 158.031 9.25375C159.547 8.62957 161.22 8.49922 162.814 8.88115C163.412 9.01874 163.988 9.23888 164.526 9.53527C165.031 9.83392 165.466 10.2377 165.803 10.7193C166.154 11.2299 166.336 11.838 166.325 12.4581C166.331 12.6766 166.293 12.8939 166.214 13.0976C166.135 13.3013 166.017 13.4873 165.866 13.6449C165.508 13.9648 165.038 14.1295 164.559 14.1031C164.179 14.0996 163.803 14.0159 163.458 13.8575C163.284 13.7814 163.139 13.6537 163.04 13.4918C162.942 13.3299 162.895 13.1415 162.907 12.9522C162.918 12.5646 162.995 12.1817 163.136 11.8206C163.263 11.4851 163.34 11.1326 163.364 10.7745C163.363 10.5457 163.295 10.3223 163.17 10.1309C163.045 9.93945 162.868 9.78825 162.66 9.69536C162.142 9.42423 161.564 9.29026 160.981 9.3062C160.524 9.3062 159.109 9.37796 158.174 10.3495C157.75 10.8104 157.417 11.5197 157.15 12.4581C156.847 13.7274 156.711 15.0312 156.745 16.3359C156.739 17.2122 156.798 18.0877 156.921 18.9552C157.022 19.6925 157.236 20.4095 157.557 21.0804V21.097C158.262 22.5487 160.133 22.753 161.193 22.753C162.291 22.7293 163.345 22.3189 164.172 21.5938C164.999 20.8687 165.545 19.875 165.714 18.7868H166.667C165.995 22.0906 163.771 23.6307 160.876 23.6665Z
                "
                keyTimes="0;0.11;0.22;0.33;0.44;0.55;0.66;0.77;0.88;1"
                begin="0s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </StyledLinearFlowContainer>
      )
    case 'run-in-linear-flow':
      return (
        <StyledLinearFlowContainer>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="92" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="128" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(1, 5)}
              dur="2.4s"
              begin="0"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="92" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="128" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(2, 5)}
              dur="2.4s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="92" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="128" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(3, 5)}
              dur="2.4s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="128" cy="16" r="12" fill="#B8B8B8" />
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(4, 5)}
              dur="2.4s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="164" cy="16" r="12" fill="#B8B8B8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(5, 5)}
              dur="2.4s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Blue Circle */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="12" cy="16" r="12" fill="#1C4DA1">
              <animate
                attributeName="cy"
                values="-28;-28;-6;0;16;16;16;16;16;16;16;16;16;16;16"
                dur="2.4s"
                begin="0s"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
          {/* Green Circle */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="48" cy="16" r="12" fill="#005D30">
              <animate
                attributeName="cy"
                values="-28;-28;-28;-28;-28;-6;0;16;16;16;16;16;16;16;16"
                dur="2.4s"
                begin="0s"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
          {/* Red Circle */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="84" cy="16" r="12" fill="#D51C29">
              <animate
                attributeName="cy"
                values="-28;-28;-28;-28;-28;-28;-28;-28;-6;0;16;16;16;16;16"
                dur="2.4s"
                begin="0s"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
          {/* Yellow Circle */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="120" cy="16" r="12" fill="#FDB930">
              <animate
                attributeName="cy"
                values="-28;-28;-28;-28;-28;-28;-28;-28;-28;-28;-28;-6;0;16;16"
                dur="2.4s"
                begin="0s"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
          {/* Clearly Icon */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 176 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path fill="black">
              <animate
                attributeName="d"
                dur="2.4s"
                values="
                M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z;
                M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z;
                M52 32C60.8366 32 68 24.8366 68 16C68 7.16344 60.8366 0 52 0C43.1634 0 36 7.16344 36 16C36 24.8366 43.1634 32 52 32Z;
                M52 32C60.8366 32 68 24.8366 68 16C68 7.16344 60.8366 0 52 0C43.1634 0 36 7.16344 36 16C36 24.8366 43.1634 32 52 32Z;
                M88 32C96.8366 32 104 24.8366 104 16C104 7.16344 96.8366 0 88 0C79.1634 0 72 7.16344 72 16C72 24.8366 79.1634 32 88 32Z;
                M88 32C96.8366 32 104 24.8366 104 16C104 7.16344 96.8366 0 88 0C79.1634 0 72 7.16344 72 16C72 24.8366 79.1634 32 88 32Z;
                M124 32C132.837 32 140 24.8366 140 16C140 7.16344 132.837 0 124 0C115.163 0 108 7.16344 108 16C108 24.8366 115.163 32 124 32Z;
                M124 32C132.837 32 140 24.8366 140 16C140 7.16344 132.837 0 124 0C115.163 0 108 7.16344 108 16C108 24.8366 115.163 32 124 32Z;
                M160 32C168.837 32 176 24.8366 176 16C176 7.16344 168.837 0 160 0C151.163 0 144 7.16344 144 16C144 24.8366 151.163 32 160 32Z;
                M160 32C168.837 32 176 24.8366 176 16C176 7.16344 168.837 0 160 0C151.163 0 144 7.16344 144 16C144 24.8366 151.163 32 160 32Z
                "
                keyTimes="0;0.11;0.22;0.33;0.44;0.55;0.66;0.77;0.88;1"
                begin="0s"
                repeatCount="indefinite"
              />
            </path>
            <path fill="#FDFEFF">
              <animate
                attributeName="d"
                dur="2.4s"
                values="
                M16.8762 23.6665H16.7688C15.9068 23.6705 15.0496 23.5383 14.2286 23.2746C13.3794 22.9818 12.5809 22.5582 11.8618 22.0188C11.0914 21.4043 10.4696 20.623 10.0427 19.7335C9.53693 18.6406 9.29553 17.4434 9.33811 16.2393C9.33436 15.2131 9.53945 14.197 9.94082 13.253C10.3363 12.3542 10.8964 11.5379 11.5921 10.8463C12.2867 10.1525 13.1169 9.6103 14.0305 9.25375C15.5465 8.62957 17.2198 8.49922 18.8137 8.88115C19.4117 9.01874 19.9878 9.23888 20.5255 9.53527C21.0306 9.83392 21.4661 10.2377 21.8025 10.7193C22.1536 11.2299 22.3364 11.838 22.3254 12.4581C22.3306 12.6766 22.2927 12.8939 22.2139 13.0976C22.135 13.3013 22.0167 13.4873 21.8658 13.6449C21.5074 13.9648 21.0375 14.1295 20.5585 14.1031C20.1784 14.0996 19.8034 14.0159 19.4577 13.8575C19.2844 13.7814 19.1387 13.6537 19.0402 13.4918C18.9417 13.3299 18.8953 13.1415 18.9072 12.9522C18.9175 12.5646 18.9948 12.1817 19.1357 11.8206C19.2628 11.4851 19.3398 11.1326 19.3641 10.7745C19.3626 10.5457 19.2953 10.3223 19.1703 10.1309C19.0454 9.93945 18.868 9.78825 18.6596 9.69536C18.1422 9.42423 17.5643 9.29026 16.9808 9.3062C16.5239 9.3062 15.1093 9.37796 14.1736 10.3495C13.7498 10.8104 13.4168 11.5197 13.1498 12.4581C12.8466 13.7274 12.7105 15.0312 12.7452 16.3359C12.7387 17.2122 12.7975 18.0877 12.9214 18.9552C13.0217 19.6925 13.2362 20.4095 13.5571 21.0804V21.097C14.2617 22.5487 16.1331 22.753 17.1927 22.753C18.2907 22.7293 19.3454 22.3189 20.1721 21.5938C20.9988 20.8687 21.5448 19.875 21.7144 18.7868H22.6666C21.9951 22.0906 19.7714 23.6307 16.8762 23.6665Z;
                M16.8762 23.6665H16.7688C15.9068 23.6705 15.0496 23.5383 14.2286 23.2746C13.3794 22.9818 12.5809 22.5582 11.8618 22.0188C11.0914 21.4043 10.4696 20.623 10.0427 19.7335C9.53693 18.6406 9.29553 17.4434 9.33811 16.2393C9.33436 15.2131 9.53945 14.197 9.94082 13.253C10.3363 12.3542 10.8964 11.5379 11.5921 10.8463C12.2867 10.1525 13.1169 9.6103 14.0305 9.25375C15.5465 8.62957 17.2198 8.49922 18.8137 8.88115C19.4117 9.01874 19.9878 9.23888 20.5255 9.53527C21.0306 9.83392 21.4661 10.2377 21.8025 10.7193C22.1536 11.2299 22.3364 11.838 22.3254 12.4581C22.3306 12.6766 22.2927 12.8939 22.2139 13.0976C22.135 13.3013 22.0167 13.4873 21.8658 13.6449C21.5074 13.9648 21.0375 14.1295 20.5585 14.1031C20.1784 14.0996 19.8034 14.0159 19.4577 13.8575C19.2844 13.7814 19.1387 13.6537 19.0402 13.4918C18.9417 13.3299 18.8953 13.1415 18.9072 12.9522C18.9175 12.5646 18.9948 12.1817 19.1357 11.8206C19.2628 11.4851 19.3398 11.1326 19.3641 10.7745C19.3626 10.5457 19.2953 10.3223 19.1703 10.1309C19.0454 9.93945 18.868 9.78825 18.6596 9.69536C18.1422 9.42423 17.5643 9.29026 16.9808 9.3062C16.5239 9.3062 15.1093 9.37796 14.1736 10.3495C13.7498 10.8104 13.4168 11.5197 13.1498 12.4581C12.8466 13.7274 12.7105 15.0312 12.7452 16.3359C12.7387 17.2122 12.7975 18.0877 12.9214 18.9552C13.0217 19.6925 13.2362 20.4095 13.5571 21.0804V21.097C14.2617 22.5487 16.1331 22.753 17.1927 22.753C18.2907 22.7293 19.3454 22.3189 20.1721 21.5938C20.9988 20.8687 21.5448 19.875 21.7144 18.7868H22.6666C21.9951 22.0906 19.7714 23.6307 16.8762 23.6665Z;
                M52.8762 23.6665H52.7689C51.9069 23.6705 51.0496 23.5383 50.2287 23.2746C49.3795 22.9818 48.581 22.5582 47.8619 22.0188C47.0915 21.4043 46.4696 20.623 46.0427 19.7335C45.537 18.6406 45.2956 17.4434 45.3382 16.2393C45.3344 15.2131 45.5395 14.197 45.9409 13.253C46.3363 12.3542 46.8965 11.5379 47.5922 10.8463C48.2868 10.1525 49.1169 9.6103 50.0305 9.25375C51.5465 8.62957 53.2198 8.49922 54.8137 8.88115C55.4118 9.01874 55.9879 9.23888 56.5256 9.53527C57.0307 9.83392 57.4661 10.2377 57.8025 10.7193C58.1536 11.2299 58.3365 11.838 58.3254 12.4581C58.3307 12.6766 58.2928 12.8939 58.2139 13.0976C58.135 13.3013 58.0167 13.4873 57.8658 13.6449C57.5075 13.9648 57.0376 14.1295 56.5586 14.1031C56.1785 14.0996 55.8034 14.0159 55.4577 13.8575C55.2845 13.7814 55.1387 13.6537 55.0403 13.4918C54.9418 13.3299 54.8954 13.1415 54.9073 12.9522C54.9176 12.5646 54.9949 12.1817 55.1357 11.8206C55.2629 11.4851 55.3398 11.1326 55.3642 10.7745C55.3626 10.5457 55.2954 10.3223 55.1704 10.1309C55.0454 9.93945 54.8681 9.78825 54.6596 9.69536C54.1422 9.42423 53.5643 9.29026 52.9808 9.3062C52.524 9.3062 51.1094 9.37796 50.1737 10.3495C49.7498 10.8104 49.4168 11.5197 49.1499 12.4581C48.8466 13.7274 48.7106 15.0312 48.7453 16.3359C48.7387 17.2122 48.7976 18.0877 48.9214 18.9552C49.0218 19.6925 49.2363 20.4095 49.5572 21.0804V21.097C50.2617 22.5487 52.1332 22.753 53.1927 22.753C54.2908 22.7293 55.3455 22.3189 56.1721 21.5938C56.9988 20.8687 57.5448 19.875 57.7145 18.7868H58.6667C57.9952 22.0906 55.7715 23.6307 52.8762 23.6665Z;
                M52.8762 23.6665H52.7689C51.9069 23.6705 51.0496 23.5383 50.2287 23.2746C49.3795 22.9818 48.581 22.5582 47.8619 22.0188C47.0915 21.4043 46.4696 20.623 46.0427 19.7335C45.537 18.6406 45.2956 17.4434 45.3382 16.2393C45.3344 15.2131 45.5395 14.197 45.9409 13.253C46.3363 12.3542 46.8965 11.5379 47.5922 10.8463C48.2868 10.1525 49.1169 9.6103 50.0305 9.25375C51.5465 8.62957 53.2198 8.49922 54.8137 8.88115C55.4118 9.01874 55.9879 9.23888 56.5256 9.53527C57.0307 9.83392 57.4661 10.2377 57.8025 10.7193C58.1536 11.2299 58.3365 11.838 58.3254 12.4581C58.3307 12.6766 58.2928 12.8939 58.2139 13.0976C58.135 13.3013 58.0167 13.4873 57.8658 13.6449C57.5075 13.9648 57.0376 14.1295 56.5586 14.1031C56.1785 14.0996 55.8034 14.0159 55.4577 13.8575C55.2845 13.7814 55.1387 13.6537 55.0403 13.4918C54.9418 13.3299 54.8954 13.1415 54.9073 12.9522C54.9176 12.5646 54.9949 12.1817 55.1357 11.8206C55.2629 11.4851 55.3398 11.1326 55.3642 10.7745C55.3626 10.5457 55.2954 10.3223 55.1704 10.1309C55.0454 9.93945 54.8681 9.78825 54.6596 9.69536C54.1422 9.42423 53.5643 9.29026 52.9808 9.3062C52.524 9.3062 51.1094 9.37796 50.1737 10.3495C49.7498 10.8104 49.4168 11.5197 49.1499 12.4581C48.8466 13.7274 48.7106 15.0312 48.7453 16.3359C48.7387 17.2122 48.7976 18.0877 48.9214 18.9552C49.0218 19.6925 49.2363 20.4095 49.5572 21.0804V21.097C50.2617 22.5487 52.1332 22.753 53.1927 22.753C54.2908 22.7293 55.3455 22.3189 56.1721 21.5938C56.9988 20.8687 57.5448 19.875 57.7145 18.7868H58.6667C57.9952 22.0906 55.7715 23.6307 52.8762 23.6665Z;
                M88.8762 23.6665H88.7689C87.9069 23.6705 87.0496 23.5383 86.2287 23.2746C85.3795 22.9818 84.581 22.5582 83.8619 22.0188C83.0915 21.4043 82.4696 20.623 82.0427 19.7335C81.537 18.6406 81.2956 17.4434 81.3382 16.2393C81.3344 15.2131 81.5395 14.197 81.9409 13.253C82.3363 12.3542 82.8965 11.5379 83.5922 10.8463C84.2868 10.1525 85.1169 9.6103 86.0305 9.25375C87.5465 8.62957 89.2198 8.49922 90.8137 8.88115C91.4118 9.01874 91.9879 9.23888 92.5256 9.53527C93.0307 9.83392 93.4661 10.2377 93.8025 10.7193C94.1536 11.2299 94.3365 11.838 94.3254 12.4581C94.3307 12.6766 94.2928 12.8939 94.2139 13.0976C94.135 13.3013 94.0167 13.4873 93.8658 13.6449C93.5075 13.9648 93.0376 14.1295 92.5586 14.1031C92.1785 14.0996 91.8034 14.0159 91.4577 13.8575C91.2845 13.7814 91.1387 13.6537 91.0403 13.4918C90.9418 13.3299 90.8954 13.1415 90.9073 12.9522C90.9176 12.5646 90.9949 12.1817 91.1357 11.8206C91.2629 11.4851 91.3398 11.1326 91.3642 10.7745C91.3626 10.5457 91.2954 10.3223 91.1704 10.1309C91.0454 9.93945 90.8681 9.78825 90.6596 9.69536C90.1422 9.42423 89.5643 9.29026 88.9808 9.3062C88.524 9.3062 87.1094 9.37796 86.1737 10.3495C85.7498 10.8104 85.4168 11.5197 85.1499 12.4581C84.8466 13.7274 84.7106 15.0312 84.7453 16.3359C84.7387 17.2122 84.7976 18.0877 84.9214 18.9552C85.0218 19.6925 85.2363 20.4095 85.5572 21.0804V21.097C86.2617 22.5487 88.1332 22.753 89.1927 22.753C90.2908 22.7293 91.3455 22.3189 92.1721 21.5938C92.9988 20.8687 93.5448 19.875 93.7145 18.7868H94.6667C93.9952 22.0906 91.7715 23.6307 88.8762 23.6665Z;
                M88.8762 23.6665H88.7689C87.9069 23.6705 87.0496 23.5383 86.2287 23.2746C85.3795 22.9818 84.581 22.5582 83.8619 22.0188C83.0915 21.4043 82.4696 20.623 82.0427 19.7335C81.537 18.6406 81.2956 17.4434 81.3382 16.2393C81.3344 15.2131 81.5395 14.197 81.9409 13.253C82.3363 12.3542 82.8965 11.5379 83.5922 10.8463C84.2868 10.1525 85.1169 9.6103 86.0305 9.25375C87.5465 8.62957 89.2198 8.49922 90.8137 8.88115C91.4118 9.01874 91.9879 9.23888 92.5256 9.53527C93.0307 9.83392 93.4661 10.2377 93.8025 10.7193C94.1536 11.2299 94.3365 11.838 94.3254 12.4581C94.3307 12.6766 94.2928 12.8939 94.2139 13.0976C94.135 13.3013 94.0167 13.4873 93.8658 13.6449C93.5075 13.9648 93.0376 14.1295 92.5586 14.1031C92.1785 14.0996 91.8034 14.0159 91.4577 13.8575C91.2845 13.7814 91.1387 13.6537 91.0403 13.4918C90.9418 13.3299 90.8954 13.1415 90.9073 12.9522C90.9176 12.5646 90.9949 12.1817 91.1357 11.8206C91.2629 11.4851 91.3398 11.1326 91.3642 10.7745C91.3626 10.5457 91.2954 10.3223 91.1704 10.1309C91.0454 9.93945 90.8681 9.78825 90.6596 9.69536C90.1422 9.42423 89.5643 9.29026 88.9808 9.3062C88.524 9.3062 87.1094 9.37796 86.1737 10.3495C85.7498 10.8104 85.4168 11.5197 85.1499 12.4581C84.8466 13.7274 84.7106 15.0312 84.7453 16.3359C84.7387 17.2122 84.7976 18.0877 84.9214 18.9552C85.0218 19.6925 85.2363 20.4095 85.5572 21.0804V21.097C86.2617 22.5487 88.1332 22.753 89.1927 22.753C90.2908 22.7293 91.3455 22.3189 92.1721 21.5938C92.9988 20.8687 93.5448 19.875 93.7145 18.7868H94.6667C93.9952 22.0906 91.7715 23.6307 88.8762 23.6665Z;
                M124.876 23.6665H124.769C123.907 23.6705 123.05 23.5383 122.229 23.2746C121.38 22.9818 120.581 22.5582 119.862 22.0188C119.091 21.4043 118.47 20.623 118.043 19.7335C117.537 18.6406 117.296 17.4434 117.338 16.2393C117.334 15.2131 117.54 14.197 117.941 13.253C118.336 12.3542 118.896 11.5379 119.592 10.8463C120.287 10.1525 121.117 9.6103 122.031 9.25375C123.547 8.62957 125.22 8.49922 126.814 8.88115C127.412 9.01874 127.988 9.23888 128.526 9.53527C129.031 9.83392 129.466 10.2377 129.803 10.7193C130.154 11.2299 130.336 11.838 130.325 12.4581C130.331 12.6766 130.293 12.8939 130.214 13.0976C130.135 13.3013 130.017 13.4873 129.866 13.6449C129.508 13.9648 129.038 14.1295 128.559 14.1031C128.179 14.0996 127.803 14.0159 127.458 13.8575C127.284 13.7814 127.139 13.6537 127.04 13.4918C126.942 13.3299 126.895 13.1415 126.907 12.9522C126.918 12.5646 126.995 12.1817 127.136 11.8206C127.263 11.4851 127.34 11.1326 127.364 10.7745C127.363 10.5457 127.295 10.3223 127.17 10.1309C127.045 9.93945 126.868 9.78825 126.66 9.69536C126.142 9.42423 125.564 9.29026 124.981 9.3062C124.524 9.3062 123.109 9.37796 122.174 10.3495C121.75 10.8104 121.417 11.5197 121.15 12.4581C120.847 13.7274 120.711 15.0312 120.745 16.3359C120.739 17.2122 120.798 18.0877 120.921 18.9552C121.022 19.6925 121.236 20.4095 121.557 21.0804V21.097C122.262 22.5487 124.133 22.753 125.193 22.753C126.291 22.7293 127.345 22.3189 128.172 21.5938C128.999 20.8687 129.545 19.875 129.714 18.7868H130.667C129.995 22.0906 127.771 23.6307 124.876 23.6665Z;
                M124.876 23.6665H124.769C123.907 23.6705 123.05 23.5383 122.229 23.2746C121.38 22.9818 120.581 22.5582 119.862 22.0188C119.091 21.4043 118.47 20.623 118.043 19.7335C117.537 18.6406 117.296 17.4434 117.338 16.2393C117.334 15.2131 117.54 14.197 117.941 13.253C118.336 12.3542 118.896 11.5379 119.592 10.8463C120.287 10.1525 121.117 9.6103 122.031 9.25375C123.547 8.62957 125.22 8.49922 126.814 8.88115C127.412 9.01874 127.988 9.23888 128.526 9.53527C129.031 9.83392 129.466 10.2377 129.803 10.7193C130.154 11.2299 130.336 11.838 130.325 12.4581C130.331 12.6766 130.293 12.8939 130.214 13.0976C130.135 13.3013 130.017 13.4873 129.866 13.6449C129.508 13.9648 129.038 14.1295 128.559 14.1031C128.179 14.0996 127.803 14.0159 127.458 13.8575C127.284 13.7814 127.139 13.6537 127.04 13.4918C126.942 13.3299 126.895 13.1415 126.907 12.9522C126.918 12.5646 126.995 12.1817 127.136 11.8206C127.263 11.4851 127.34 11.1326 127.364 10.7745C127.363 10.5457 127.295 10.3223 127.17 10.1309C127.045 9.93945 126.868 9.78825 126.66 9.69536C126.142 9.42423 125.564 9.29026 124.981 9.3062C124.524 9.3062 123.109 9.37796 122.174 10.3495C121.75 10.8104 121.417 11.5197 121.15 12.4581C120.847 13.7274 120.711 15.0312 120.745 16.3359C120.739 17.2122 120.798 18.0877 120.921 18.9552C121.022 19.6925 121.236 20.4095 121.557 21.0804V21.097C122.262 22.5487 124.133 22.753 125.193 22.753C126.291 22.7293 127.345 22.3189 128.172 21.5938C128.999 20.8687 129.545 19.875 129.714 18.7868H130.667C129.995 22.0906 127.771 23.6307 124.876 23.6665Z;
                M160.876 23.6665H160.769C159.907 23.6705 159.05 23.5383 158.229 23.2746C157.38 22.9818 156.581 22.5582 155.862 22.0188C155.091 21.4043 154.47 20.623 154.043 19.7335C153.537 18.6406 153.296 17.4434 153.338 16.2393C153.334 15.2131 153.54 14.197 153.941 13.253C154.336 12.3542 154.896 11.5379 155.592 10.8463C156.287 10.1525 157.117 9.6103 158.031 9.25375C159.547 8.62957 161.22 8.49922 162.814 8.88115C163.412 9.01874 163.988 9.23888 164.526 9.53527C165.031 9.83392 165.466 10.2377 165.803 10.7193C166.154 11.2299 166.336 11.838 166.325 12.4581C166.331 12.6766 166.293 12.8939 166.214 13.0976C166.135 13.3013 166.017 13.4873 165.866 13.6449C165.508 13.9648 165.038 14.1295 164.559 14.1031C164.179 14.0996 163.803 14.0159 163.458 13.8575C163.284 13.7814 163.139 13.6537 163.04 13.4918C162.942 13.3299 162.895 13.1415 162.907 12.9522C162.918 12.5646 162.995 12.1817 163.136 11.8206C163.263 11.4851 163.34 11.1326 163.364 10.7745C163.363 10.5457 163.295 10.3223 163.17 10.1309C163.045 9.93945 162.868 9.78825 162.66 9.69536C162.142 9.42423 161.564 9.29026 160.981 9.3062C160.524 9.3062 159.109 9.37796 158.174 10.3495C157.75 10.8104 157.417 11.5197 157.15 12.4581C156.847 13.7274 156.711 15.0312 156.745 16.3359C156.739 17.2122 156.798 18.0877 156.921 18.9552C157.022 19.6925 157.236 20.4095 157.557 21.0804V21.097C158.262 22.5487 160.133 22.753 161.193 22.753C162.291 22.7293 163.345 22.3189 164.172 21.5938C164.999 20.8687 165.545 19.875 165.714 18.7868H166.667C165.995 22.0906 163.771 23.6307 160.876 23.6665Z;
                M160.876 23.6665H160.769C159.907 23.6705 159.05 23.5383 158.229 23.2746C157.38 22.9818 156.581 22.5582 155.862 22.0188C155.091 21.4043 154.47 20.623 154.043 19.7335C153.537 18.6406 153.296 17.4434 153.338 16.2393C153.334 15.2131 153.54 14.197 153.941 13.253C154.336 12.3542 154.896 11.5379 155.592 10.8463C156.287 10.1525 157.117 9.6103 158.031 9.25375C159.547 8.62957 161.22 8.49922 162.814 8.88115C163.412 9.01874 163.988 9.23888 164.526 9.53527C165.031 9.83392 165.466 10.2377 165.803 10.7193C166.154 11.2299 166.336 11.838 166.325 12.4581C166.331 12.6766 166.293 12.8939 166.214 13.0976C166.135 13.3013 166.017 13.4873 165.866 13.6449C165.508 13.9648 165.038 14.1295 164.559 14.1031C164.179 14.0996 163.803 14.0159 163.458 13.8575C163.284 13.7814 163.139 13.6537 163.04 13.4918C162.942 13.3299 162.895 13.1415 162.907 12.9522C162.918 12.5646 162.995 12.1817 163.136 11.8206C163.263 11.4851 163.34 11.1326 163.364 10.7745C163.363 10.5457 163.295 10.3223 163.17 10.1309C163.045 9.93945 162.868 9.78825 162.66 9.69536C162.142 9.42423 161.564 9.29026 160.981 9.3062C160.524 9.3062 159.109 9.37796 158.174 10.3495C157.75 10.8104 157.417 11.5197 157.15 12.4581C156.847 13.7274 156.711 15.0312 156.745 16.3359C156.739 17.2122 156.798 18.0877 156.921 18.9552C157.022 19.6925 157.236 20.4095 157.557 21.0804V21.097C158.262 22.5487 160.133 22.753 161.193 22.753C162.291 22.7293 163.345 22.3189 164.172 21.5938C164.999 20.8687 165.545 19.875 165.714 18.7868H166.667C165.995 22.0906 163.771 23.6307 160.876 23.6665Z
                "
                keyTimes="0;0.11;0.22;0.33;0.44;0.55;0.66;0.77;0.88;1"
                begin="0s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </StyledLinearFlowContainer>
      )
    case 'pulse-linear-flow':
      return (
        <StyledLinearFlowContainer>
          {/* Frame 1 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <rect width="192" height="32" fill="white" />
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <g filter="url(#filter0_f_62089_87722)">
              <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            </g>
            <defs>
              <filter
                id="filter0_f_62089_87722"
                x="4"
                y="4"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_62089_87722" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              id="first"
              values={frameByFrame(1, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 2 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <g filter="url(#filter0_f_62188_87038)">
              <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87038"
                x="4"
                y="4"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_62188_87038" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(2, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 3 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <g filter="url(#filter0_f_62188_87068)">
              <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87068"
                x="4"
                y="4"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_62188_87068" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(3, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 4 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87078)">
              <circle cx="16" cy="16" r="14" fill="#1C4DA1" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87078"
                x="0"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87078" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(4, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 5 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87197)">
              <circle cx="16" cy="16" r="14" fill="#1C4DA1" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87197"
                x="0"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87197" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(5, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 6 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87212)">
              <circle cx="16" cy="16" r="14" fill="#1C4DA1" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87212"
                x="0"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87212" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(6, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 7 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87222)">
              <circle cx="16" cy="16" r="12" fill="#1C4DA1" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87222"
                x="2"
                y="2"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87222" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(7, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 8 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <animate
              attributeName="visibility"
              values={frameByFrame(8, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 9 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <animate
              attributeName="visibility"
              values={frameByFrame(9, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 10 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="56" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <animate
              attributeName="visibility"
              values={frameByFrame(10, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 11 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <g filter="url(#filter0_f_62188_87337)">
              <circle cx="56" cy="16" r="8" fill="#005D30" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87337"
                x="44"
                y="4"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_62188_87337" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(11, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 12 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <g filter="url(#filter0_f_62188_87417)">
              <circle cx="56" cy="16" r="8" fill="#005D30" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87417"
                x="44"
                y="4"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_62188_87417" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(12, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 13 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87474)">
              <circle cx="56" cy="16" r="14" fill="#005D30" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87474"
                x="40"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87474" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(13, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 14 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87490)">
              <circle cx="56" cy="16" r="14" fill="#005D30" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87490"
                x="40"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87490" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(14, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 15 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87500)">
              <circle cx="56" cy="16" r="14" fill="#005D30" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87500"
                x="40"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87500" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(15, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 16 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87517)">
              <circle cx="56" cy="16" r="12" fill="#005D30" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87517"
                x="42"
                y="2"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87517" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(16, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 17 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <animate
              attributeName="visibility"
              values={frameByFrame(17, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 18 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <animate
              attributeName="visibility"
              values={frameByFrame(18, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 19 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="96" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <animate
              attributeName="visibility"
              values={frameByFrame(19, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 20 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <g filter="url(#filter0_f_62188_87597)">
              <circle cx="96" cy="16" r="8" fill="#D51C29" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87597"
                x="84"
                y="4"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_62188_87597" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(20, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 21 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <g filter="url(#filter0_f_62188_87609)">
              <circle cx="96" cy="16" r="8" fill="#D51C29" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87609"
                x="84"
                y="4"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_62188_87609" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(21, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 22 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87619)">
              <circle cx="96" cy="16" r="14" fill="#D51C29" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87619"
                x="80"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87619" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(22, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 23 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87630)">
              <circle cx="96" cy="16" r="14" fill="#D51C29" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87630"
                x="80"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87630" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(23, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 24 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87645)">
              <circle cx="96" cy="16" r="14" fill="#D51C29" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87645"
                x="80"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87645" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(24, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 25 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87655)">
              <circle cx="96" cy="16" r="12" fill="#D51C29" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87655"
                x="82"
                y="2"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87655" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(25, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 26 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <animate
              attributeName="visibility"
              values={frameByFrame(26, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 27 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <animate
              attributeName="visibility"
              values={frameByFrame(27, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 28 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="136" cy="16" r="8" fill="#B8B8B8" />
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <animate
              attributeName="visibility"
              values={frameByFrame(28, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 29 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <g filter="url(#filter0_f_62188_87811)">
              <circle cx="136" cy="16" r="8" fill="#FDB930" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87811"
                x="124"
                y="4"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_62188_87811" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(29, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 30 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <g filter="url(#filter0_f_62188_87826)">
              <circle cx="136" cy="16" r="8" fill="#FDB930" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87826"
                x="124"
                y="4"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_62188_87826" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(30, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 31 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87836)">
              <circle cx="136" cy="16" r="14" fill="#FDB930" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87836"
                x="120"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87836" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(31, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 32 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87847)">
              <circle cx="136" cy="16" r="14" fill="#FDB930" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87847"
                x="120"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87847" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(32, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 33 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87857)">
              <circle cx="136" cy="16" r="14" fill="#FDB930" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87857"
                x="120"
                y="0"
                width="32"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87857" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(33, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 34 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <g opacity="0.5" filter="url(#filter0_f_62188_87867)">
              <circle cx="136" cy="16" r="12" fill="#FDB930" />
            </g>
            <defs>
              <filter
                id="filter0_f_62188_87867"
                x="122"
                y="2"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_62188_87867" />
              </filter>
            </defs>
            <animate
              attributeName="visibility"
              values={frameByFrame(34, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 35 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(35, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 36 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(36, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 37 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(37, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 38 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(38, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 39 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(39, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 40 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(40, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 41 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(41, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 42 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(42, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 43 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(43, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Frame 44 */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="16" cy="16" r="8" fill="#1C4DA1" />
            <circle cx="56" cy="16" r="8" fill="#005D30" />
            <circle cx="96" cy="16" r="8" fill="#D51C29" />
            <circle cx="136" cy="16" r="8" fill="#FDB930" />
            <animate
              attributeName="visibility"
              values={frameByFrame(44, 44)}
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          {/* Clearly Icon */}
          <svg
            width={size / 0.18}
            height={size}
            viewBox="0 0 192 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <path fill="black">
              <animate
                attributeName="d"
                values="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z;
                M28 32C36.8366 32 44 24.8366 44 16C44 7.16344 36.8366 0 28 0C19.1634 0 12 7.16344 12 16C12 24.8366 19.1634 32 28 32Z;
                M32 32C40.8366 32 48 24.8366 48 16C48 7.16344 40.8366 0 32 0C23.1634 0 16 7.16344 16 16C16 24.8366 23.1634 32 32 32Z;
                M36 32C44.8366 32 52 24.8366 52 16C52 7.16344 44.8366 0 36 0C27.1634 0 20 7.16344 20 16C20 24.8366 27.1634 32 36 32Z;
                M40 32C48.8366 32 56 24.8366 56 16C56 7.16344 48.8366 0 40 0C31.1634 0 24 7.16344 24 16C24 24.8366 31.1634 32 40 32Z;
                M40 32C48.8366 32 56 24.8366 56 16C56 7.16344 48.8366 0 40 0C31.1634 0 24 7.16344 24 16C24 24.8366 31.1634 32 40 32Z;
                M44 32C52.8366 32 60 24.8366 60 16C60 7.16344 52.8366 0 44 0C35.1634 0 28 7.16344 28 16C28 24.8366 35.1634 32 44 32Z;
                M48 32C56.8366 32 64 24.8366 64 16C64 7.16344 56.8366 0 48 0C39.1634 0 32 7.16344 32 16C32 24.8366 39.1634 32 48 32Z;
                M52 32C60.8366 32 68 24.8366 68 16C68 7.16344 60.8366 0 52 0C43.1634 0 36 7.16344 36 16C36 24.8366 43.1634 32 52 32Z;
                M56 32C64.8366 32 72 24.8366 72 16C72 7.16344 64.8366 0 56 0C47.1634 0 40 7.16344 40 16C40 24.8366 47.1634 32 56 32Z;
                M68 32C76.8366 32 84 24.8366 84 16C84 7.16344 76.8366 0 68 0C59.1634 0 52 7.16344 52 16C52 24.8366 59.1634 32 68 32Z;
                M72 32C80.8366 32 88 24.8366 88 16C88 7.16344 80.8366 0 72 0C63.1634 0 56 7.16344 56 16C56 24.8366 63.1634 32 72 32Z;
                M76 32C84.8366 32 92 24.8366 92 16C92 7.16344 84.8366 0 76 0C67.1634 0 60 7.16344 60 16C60 24.8366 67.1634 32 76 32Z;
                M80 32C88.8366 32 96 24.8366 96 16C96 7.16344 88.8366 0 80 0C71.1634 0 64 7.16344 64 16C64 24.8366 71.1634 32 80 32Z;
                M80 32C88.8366 32 96 24.8366 96 16C96 7.16344 88.8366 0 80 0C71.1634 0 64 7.16344 64 16C64 24.8366 71.1634 32 80 32Z;
                M84 32C92.8366 32 100 24.8366 100 16C100 7.16344 92.8366 0 84 0C75.1634 0 68 7.16344 68 16C68 24.8366 75.1634 32 84 32Z;
                M88 32C96.8366 32 104 24.8366 104 16C104 7.16344 96.8366 0 88 0C79.1634 0 72 7.16344 72 16C72 24.8366 79.1634 32 88 32Z;
                M92 32C100.837 32 108 24.8366 108 16C108 7.16344 100.837 0 92 0C83.1634 0 76 7.16344 76 16C76 24.8366 83.1634 32 92 32Z;
                M96 32C104.837 32 112 24.8366 112 16C112 7.16344 104.837 0 96 0C87.1634 0 80 7.16344 80 16C80 24.8366 87.1634 32 96 32Z;
                M108 32C116.837 32 124 24.8366 124 16C124 7.16344 116.837 0 108 0C99.1634 0 92 7.16344 92 16C92 24.8366 99.1634 32 108 32Z;
                M112 32C120.837 32 128 24.8366 128 16C128 7.16344 120.837 0 112 0C103.163 0 96 7.16344 96 16C96 24.8366 103.163 32 112 32Z;
                M116 32C124.837 32 132 24.8366 132 16C132 7.16344 124.837 0 116 0C107.163 0 100 7.16344 100 16C100 24.8366 107.163 32 116 32Z;
                M120 32C128.837 32 136 24.8366 136 16C136 7.16344 128.837 0 120 0C111.163 0 104 7.16344 104 16C104 24.8366 111.163 32 120 32Z;
                M120 32C128.837 32 136 24.8366 136 16C136 7.16344 128.837 0 120 0C111.163 0 104 7.16344 104 16C104 24.8366 111.163 32 120 32Z;
                M124 32C132.837 32 140 24.8366 140 16C140 7.16344 132.837 0 124 0C115.163 0 108 7.16344 108 16C108 24.8366 115.163 32 124 32Z;
                M128 32C136.837 32 144 24.8366 144 16C144 7.16344 136.837 0 128 0C119.163 0 112 7.16344 112 16C112 24.8366 119.163 32 128 32Z;
                M132 32C140.837 32 148 24.8366 148 16C148 7.16344 140.837 0 132 0C123.163 0 116 7.16344 116 16C116 24.8366 123.163 32 132 32Z;
                M136 32C144.837 32 152 24.8366 152 16C152 7.16344 144.837 0 136 0C127.163 0 120 7.16344 120 16C120 24.8366 127.163 32 136 32Z;
                M148 32C156.837 32 164 24.8366 164 16C164 7.16344 156.837 0 148 0C139.163 0 132 7.16344 132 16C132 24.8366 139.163 32 148 32Z;
                M152 32C160.837 32 168 24.8366 168 16C168 7.16344 160.837 0 152 0C143.163 0 136 7.16344 136 16C136 24.8366 143.163 32 152 32Z;
                M156 32C164.837 32 172 24.8366 172 16C172 7.16344 164.837 0 156 0C147.163 0 140 7.16344 140 16C140 24.8366 147.163 32 156 32Z;
                M160 32C168.837 32 176 24.8366 176 16C176 7.16344 168.837 0 160 0C151.163 0 144 7.16344 144 16C144 24.8366 151.163 32 160 32Z;
                M160 32C168.837 32 176 24.8366 176 16C176 7.16344 168.837 0 160 0C151.163 0 144 7.16344 144 16C144 24.8366 151.163 32 160 32Z;
                M164 32C172.837 32 180 24.8366 180 16C180 7.16344 172.837 0 164 0C155.163 0 148 7.16344 148 16C148 24.8366 155.163 32 164 32Z;
                M168 32C176.837 32 184 24.8366 184 16C184 7.16344 176.837 0 168 0C159.163 0 152 7.16344 152 16C152 24.8366 159.163 32 168 32Z;
                M172 32C180.837 32 188 24.8366 188 16C188 7.16344 180.837 0 172 0C163.163 0 156 7.16344 156 16C156 24.8366 163.163 32 172 32Z;
                M176 32C184.837 32 192 24.8366 192 16C192 7.16344 184.837 0 176 0C167.163 0 160 7.16344 160 16C160 24.8366 167.163 32 176 32Z;
                M176 32C184.837 32 192 24.8366 192 16C192 7.16344 184.837 0 176 0C167.163 0 160 7.16344 160 16C160 24.8366 167.163 32 176 32Z;
                M -4 32 C 4.8366 32 12 24.8366 12 16 C 12 7.1634 4.8366 0 -4 0 C -12.8366 0 -20 7.1634 -20 16 C -20 24.8366 -12.8366 32 -4 32 Z;
                M 0 32 C 8.8366 32 16 24.8366 16 16 C 16 7.1634 8.8366 0 0 0 C -8.8366 0 -16 7.1634 -16 16 C -16 24.8366 -8.8366 32 0 32 Z;
                M 4 32 C 12.8366 32 20 24.8366 20 16 C 20 7.1634 12.8366 0 4 0 C -4.8366 0 -12 7.1634 -12 16 C -12 24.8366 -4.8366 32 4 32 Z;
                M 8 32 C 16.8366 32 24 24.8366 24 16 C 24 7.1634 16.8366 0 8 0 C -0.8366 0 -8 7.1634 -8 16 C -8 24.8366 -0.8366 32 8 32 Z;
                M 14 32 C 22.8366 32 30 24.8366 30 16 C 30 7.1634 22.8366 0 14 0 C 5.1634 0 -2 7.1634 -2 16 C -2 24.8366 5.1634 32 14 32 Z;
                M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                dur="4.5s"
                begin="0s"
                repeatCount="indefinite"
              />
            </path>
            <path fill="#FDFEFF">
              <animate
                attributeName="d"
                values="M16.8762 23.6669H16.7689C15.9069 23.6709 15.0496 23.5386 14.2287 23.275C13.3795 22.9822 12.581 22.5585 11.8619 22.0192C11.0915 21.4046 10.4696 20.6234 10.0427 19.7339C9.53699 18.641 9.2956 17.4438 9.33817 16.2397C9.33443 15.2135 9.53951 14.1973 9.94089 13.2534C10.3363 12.3546 10.8965 11.5382 11.5922 10.8466C12.2868 10.1529 13.1169 9.61067 14.0305 9.25412C15.5465 8.62994 17.2198 8.49959 18.8137 8.88152C19.4118 9.0191 19.9879 9.23924 20.5256 9.53564C21.0307 9.83429 21.4661 10.238 21.8025 10.7197C22.1536 11.2303 22.3365 11.8383 22.3254 12.4585C22.3307 12.6769 22.2928 12.8943 22.2139 13.098C22.135 13.3017 22.0167 13.4877 21.8658 13.6453C21.5075 13.9651 21.0376 14.1299 20.5586 14.1035C20.1785 14.1 19.8034 14.0163 19.4577 13.8578C19.2845 13.7818 19.1387 13.6541 19.0403 13.4922C18.9418 13.3302 18.8954 13.1418 18.9073 12.9525C18.9176 12.5649 18.9949 12.182 19.1357 11.8209C19.2629 11.4854 19.3398 11.133 19.3642 10.7749C19.3626 10.5461 19.2954 10.3226 19.1704 10.1312C19.0454 9.93982 18.8681 9.78861 18.6596 9.69572C18.1422 9.42459 17.5643 9.29063 16.9808 9.30656C16.524 9.30656 15.1094 9.37832 14.1737 10.3498C13.7498 10.8108 13.4168 11.5201 13.1499 12.4585C12.8466 13.7278 12.7106 15.0315 12.7453 16.3363C12.7387 17.2126 12.7976 18.0881 12.9214 18.9556C13.0218 19.6928 13.2363 20.4099 13.5572 21.0808V21.0973C14.2617 22.5491 16.1332 22.7533 17.1927 22.7533C18.2908 22.7296 19.3455 22.3193 20.1721 21.5942C20.9988 20.8691 21.5448 19.8754 21.7145 18.7872H22.6667C21.9952 22.0909 19.7715 23.631 16.8762 23.6669Z;
                M28.8762 23.6669H28.7689C27.9069 23.6709 27.0496 23.5386 26.2287 23.275C25.3795 22.9822 24.581 22.5585 23.8619 22.0192C23.0915 21.4046 22.4696 20.6234 22.0427 19.7339C21.537 18.641 21.2956 17.4438 21.3382 16.2397C21.3344 15.2135 21.5395 14.1973 21.9409 13.2534C22.3363 12.3546 22.8965 11.5382 23.5922 10.8466C24.2868 10.1529 25.1169 9.61067 26.0305 9.25412C27.5465 8.62994 29.2198 8.49959 30.8137 8.88152C31.4118 9.0191 31.9879 9.23924 32.5256 9.53564C33.0307 9.83429 33.4661 10.238 33.8025 10.7197C34.1536 11.2303 34.3365 11.8383 34.3254 12.4585C34.3307 12.6769 34.2928 12.8943 34.2139 13.098C34.135 13.3017 34.0167 13.4877 33.8658 13.6453C33.5075 13.9651 33.0376 14.1299 32.5586 14.1035C32.1785 14.1 31.8034 14.0163 31.4577 13.8578C31.2845 13.7818 31.1387 13.6541 31.0403 13.4922C30.9418 13.3302 30.8954 13.1418 30.9073 12.9525C30.9176 12.5649 30.9949 12.182 31.1357 11.8209C31.2629 11.4854 31.3398 11.133 31.3642 10.7749C31.3626 10.5461 31.2954 10.3226 31.1704 10.1312C31.0454 9.93982 30.8681 9.78861 30.6596 9.69572C30.1422 9.42459 29.5643 9.29063 28.9808 9.30656C28.524 9.30656 27.1094 9.37832 26.1737 10.3498C25.7498 10.8108 25.4168 11.5201 25.1499 12.4585C24.8466 13.7278 24.7106 15.0315 24.7453 16.3363C24.7387 17.2126 24.7976 18.0881 24.9214 18.9556C25.0218 19.6928 25.2363 20.4099 25.5572 21.0808V21.0973C26.2617 22.5491 28.1332 22.7533 29.1927 22.7533C30.2908 22.7296 31.3455 22.3193 32.1721 21.5942C32.9988 20.8691 33.5448 19.8754 33.7145 18.7872H34.6667C33.9952 22.0909 31.7715 23.631 28.8762 23.6669Z;
                M32.8762 23.6669H32.7689C31.9069 23.6709 31.0496 23.5386 30.2287 23.275C29.3795 22.9822 28.581 22.5585 27.8619 22.0192C27.0915 21.4046 26.4696 20.6234 26.0427 19.7339C25.537 18.641 25.2956 17.4438 25.3382 16.2397C25.3344 15.2135 25.5395 14.1973 25.9409 13.2534C26.3363 12.3546 26.8965 11.5382 27.5922 10.8466C28.2868 10.1529 29.1169 9.61067 30.0305 9.25412C31.5465 8.62994 33.2198 8.49959 34.8137 8.88152C35.4118 9.0191 35.9879 9.23924 36.5256 9.53564C37.0307 9.83429 37.4661 10.238 37.8025 10.7197C38.1536 11.2303 38.3365 11.8383 38.3254 12.4585C38.3307 12.6769 38.2928 12.8943 38.2139 13.098C38.135 13.3017 38.0167 13.4877 37.8658 13.6453C37.5075 13.9651 37.0376 14.1299 36.5586 14.1035C36.1785 14.1 35.8034 14.0163 35.4577 13.8578C35.2845 13.7818 35.1387 13.6541 35.0403 13.4922C34.9418 13.3302 34.8954 13.1418 34.9073 12.9525C34.9176 12.5649 34.9949 12.182 35.1357 11.8209C35.2629 11.4854 35.3398 11.133 35.3642 10.7749C35.3626 10.5461 35.2954 10.3226 35.1704 10.1312C35.0454 9.93982 34.8681 9.78861 34.6596 9.69572C34.1422 9.42459 33.5643 9.29063 32.9808 9.30656C32.524 9.30656 31.1094 9.37832 30.1737 10.3498C29.7498 10.8108 29.4168 11.5201 29.1499 12.4585C28.8466 13.7278 28.7106 15.0315 28.7453 16.3363C28.7387 17.2126 28.7976 18.0881 28.9214 18.9556C29.0218 19.6928 29.2363 20.4099 29.5572 21.0808V21.0973C30.2617 22.5491 32.1332 22.7533 33.1927 22.7533C34.2908 22.7296 35.3455 22.3193 36.1721 21.5942C36.9988 20.8691 37.5448 19.8754 37.7145 18.7872H38.6667C37.9952 22.0909 35.7715 23.631 32.8762 23.6669Z;
                M36.8762 23.6669H36.7688C35.9068 23.6709 35.0496 23.5386 34.2286 23.275C33.3794 22.9822 32.5809 22.5585 31.8618 22.0192C31.0914 21.4046 30.4696 20.6234 30.0427 19.7339C29.5369 18.641 29.2955 17.4438 29.3381 16.2397C29.3344 15.2135 29.5395 14.1973 29.9408 13.2534C30.3363 12.3546 30.8964 11.5382 31.5921 10.8466C32.2867 10.1529 33.1169 9.61067 34.0305 9.25412C35.5465 8.62994 37.2198 8.49959 38.8137 8.88152C39.4117 9.0191 39.9878 9.23924 40.5255 9.53564C41.0306 9.83429 41.4661 10.238 41.8025 10.7197C42.1536 11.2303 42.3364 11.8383 42.3254 12.4585C42.3306 12.6769 42.2927 12.8943 42.2139 13.098C42.135 13.3017 42.0167 13.4877 41.8658 13.6453C41.5074 13.9651 41.0375 14.1299 40.5585 14.1035C40.1784 14.1 39.8034 14.0163 39.4577 13.8578C39.2844 13.7818 39.1387 13.6541 39.0402 13.4922C38.9417 13.3302 38.8953 13.1418 38.9072 12.9525C38.9175 12.5649 38.9948 12.182 39.1357 11.8209C39.2628 11.4854 39.3398 11.133 39.3641 10.7749C39.3626 10.5461 39.2953 10.3226 39.1703 10.1312C39.0454 9.93982 38.868 9.78861 38.6596 9.69572C38.1422 9.42459 37.5643 9.29063 36.9808 9.30656C36.5239 9.30656 35.1093 9.37832 34.1736 10.3498C33.7498 10.8108 33.4168 11.5201 33.1498 12.4585C32.8466 13.7278 32.7105 15.0315 32.7452 16.3363C32.7387 17.2126 32.7975 18.0881 32.9214 18.9556C33.0217 19.6928 33.2362 20.4099 33.5571 21.0808V21.0973C34.2617 22.5491 36.1331 22.7533 37.1927 22.7533C38.2907 22.7296 39.3454 22.3193 40.1721 21.5942C40.9988 20.8691 41.5448 19.8754 41.7144 18.7872H42.6666C41.9951 22.0909 39.7714 23.631 36.8762 23.6669Z;
                M40.8762 23.6669H40.7688C39.9068 23.6709 39.0496 23.5386 38.2286 23.275C37.3794 22.9822 36.5809 22.5585 35.8618 22.0192C35.0914 21.4046 34.4696 20.6234 34.0427 19.7339C33.5369 18.641 33.2955 17.4438 33.3381 16.2397C33.3344 15.2135 33.5395 14.1973 33.9408 13.2534C34.3363 12.3546 34.8964 11.5382 35.5921 10.8466C36.2867 10.1529 37.1169 9.61067 38.0305 9.25412C39.5465 8.62994 41.2198 8.49959 42.8137 8.88152C43.4117 9.0191 43.9878 9.23924 44.5255 9.53564C45.0306 9.83429 45.4661 10.238 45.8025 10.7197C46.1536 11.2303 46.3364 11.8383 46.3254 12.4585C46.3306 12.6769 46.2927 12.8943 46.2139 13.098C46.135 13.3017 46.0167 13.4877 45.8658 13.6453C45.5074 13.9651 45.0375 14.1299 44.5585 14.1035C44.1784 14.1 43.8034 14.0163 43.4577 13.8578C43.2844 13.7818 43.1387 13.6541 43.0402 13.4922C42.9417 13.3302 42.8953 13.1418 42.9072 12.9525C42.9175 12.5649 42.9948 12.182 43.1357 11.8209C43.2628 11.4854 43.3398 11.133 43.3641 10.7749C43.3626 10.5461 43.2953 10.3226 43.1703 10.1312C43.0454 9.93982 42.868 9.78861 42.6596 9.69572C42.1422 9.42459 41.5643 9.29063 40.9808 9.30656C40.5239 9.30656 39.1093 9.37832 38.1736 10.3498C37.7498 10.8108 37.4168 11.5201 37.1498 12.4585C36.8466 13.7278 36.7105 15.0315 36.7452 16.3363C36.7387 17.2126 36.7975 18.0881 36.9214 18.9556C37.0217 19.6928 37.2362 20.4099 37.5571 21.0808V21.0973C38.2617 22.5491 40.1331 22.7533 41.1927 22.7533C42.2907 22.7296 43.3454 22.3193 44.1721 21.5942C44.9988 20.8691 45.5448 19.8754 45.7144 18.7872H46.6666C45.9951 22.0909 43.7714 23.631 40.8762 23.6669Z;
                M40.8762 23.6669H40.7689C39.9069 23.6709 39.0496 23.5386 38.2287 23.275C37.3795 22.9822 36.5809 22.5585 35.8618 22.0192C35.0915 21.4046 34.4696 20.6234 34.0427 19.7339C33.537 18.641 33.2956 17.4438 33.3381 16.2397C33.3344 15.2135 33.5395 14.1973 33.9409 13.2534C34.3363 12.3546 34.8964 11.5382 35.5921 10.8466C36.2867 10.1529 37.1169 9.61067 38.0305 9.25412C39.5465 8.62994 41.2198 8.49959 42.8137 8.88152C43.4117 9.0191 43.9878 9.23924 44.5255 9.53564C45.0307 9.83429 45.4661 10.238 45.8025 10.7197C46.1536 11.2303 46.3364 11.8383 46.3254 12.4585C46.3307 12.6769 46.2928 12.8943 46.2139 13.098C46.135 13.3017 46.0167 13.4877 45.8658 13.6453C45.5075 13.9651 45.0375 14.1299 44.5585 14.1035C44.1785 14.1 43.8034 14.0163 43.4577 13.8578C43.2844 13.7818 43.1387 13.6541 43.0402 13.4922C42.9418 13.3302 42.8954 13.1418 42.9073 12.9525C42.9176 12.5649 42.9948 12.182 43.1357 11.8209C43.2628 11.4854 43.3398 11.133 43.3641 10.7749C43.3626 10.5461 43.2953 10.3226 43.1704 10.1312C43.0454 9.93982 42.8681 9.78861 42.6596 9.69572C42.1422 9.42459 41.5643 9.29063 40.9808 9.30656C40.5239 9.30656 39.1093 9.37832 38.1736 10.3498C37.7498 10.8108 37.4168 11.5201 37.1498 12.4585C36.8466 13.7278 36.7106 15.0315 36.7453 16.3363C36.7387 17.2126 36.7976 18.0881 36.9214 18.9556C37.0218 19.6928 37.2363 20.4099 37.5571 21.0808V21.0973C38.2617 22.5491 40.1331 22.7533 41.1927 22.7533C42.2907 22.7296 43.3454 22.3193 44.1721 21.5942C44.9988 20.8691 45.5448 19.8754 45.7144 18.7872H46.6667C45.9952 22.0909 43.7714 23.631 40.8762 23.6669Z;
                M44.8762 23.6669H44.7689C43.9068 23.6709 43.0496 23.5386 42.2286 23.275C41.3795 22.9822 40.5809 22.5585 39.8618 22.0192C39.0915 21.4046 38.4696 20.6234 38.0427 19.7339C37.5369 18.641 37.2956 17.4438 37.3381 16.2397C37.3344 15.2135 37.5395 14.1973 37.9408 13.2534C38.3363 12.3546 38.8964 11.5382 39.5921 10.8466C40.2867 10.1529 41.1169 9.61067 42.0305 9.25412C43.5465 8.62994 45.2198 8.49959 46.8137 8.88152C47.4117 9.0191 47.9878 9.23924 48.5255 9.53564C49.0306 9.83429 49.4661 10.238 49.8025 10.7197C50.1536 11.2303 50.3364 11.8383 50.3254 12.4585C50.3307 12.6769 50.2928 12.8943 50.2139 13.098C50.135 13.3017 50.0167 13.4877 49.8658 13.6453C49.5075 13.9651 49.0375 14.1299 48.5585 14.1035C48.1785 14.1 47.8034 14.0163 47.4577 13.8578C47.2844 13.7818 47.1387 13.6541 47.0402 13.4922C46.9418 13.3302 46.8953 13.1418 46.9073 12.9525C46.9175 12.5649 46.9948 12.182 47.1357 11.8209C47.2628 11.4854 47.3398 11.133 47.3641 10.7749C47.3626 10.5461 47.2953 10.3226 47.1704 10.1312C47.0454 9.93982 46.868 9.78861 46.6596 9.69572C46.1422 9.42459 45.5643 9.29063 44.9808 9.30656C44.5239 9.30656 43.1093 9.37832 42.1736 10.3498C41.7498 10.8108 41.4168 11.5201 41.1498 12.4585C40.8466 13.7278 40.7106 15.0315 40.7453 16.3363C40.7387 17.2126 40.7976 18.0881 40.9214 18.9556C41.0218 19.6928 41.2363 20.4099 41.5571 21.0808V21.0973C42.2617 22.5491 44.1331 22.7533 45.1927 22.7533C46.2907 22.7296 47.3454 22.3193 48.1721 21.5942C48.9988 20.8691 49.5448 19.8754 49.7144 18.7872H50.6667C49.9951 22.0909 47.7714 23.631 44.8762 23.6669Z;
                M48.8762 23.6669H48.7689C47.9069 23.6709 47.0496 23.5386 46.2287 23.275C45.3795 22.9822 44.5809 22.5585 43.8618 22.0192C43.0915 21.4046 42.4696 20.6234 42.0427 19.7339C41.5369 18.641 41.2956 17.4438 41.3381 16.2397C41.3344 15.2135 41.5395 14.1973 41.9408 13.2534C42.3363 12.3546 42.8964 11.5382 43.5921 10.8466C44.2867 10.1529 45.1169 9.61067 46.0305 9.25412C47.5465 8.62994 49.2198 8.49959 50.8137 8.88152C51.4117 9.0191 51.9878 9.23924 52.5255 9.53564C53.0306 9.83429 53.4661 10.238 53.8025 10.7197C54.1536 11.2303 54.3364 11.8383 54.3254 12.4585C54.3307 12.6769 54.2928 12.8943 54.2139 13.098C54.135 13.3017 54.0167 13.4877 53.8658 13.6453C53.5075 13.9651 53.0375 14.1299 52.5585 14.1035C52.1785 14.1 51.8034 14.0163 51.4577 13.8578C51.2844 13.7818 51.1387 13.6541 51.0402 13.4922C50.9418 13.3302 50.8953 13.1418 50.9073 12.9525C50.9175 12.5649 50.9948 12.182 51.1357 11.8209C51.2628 11.4854 51.3398 11.133 51.3641 10.7749C51.3626 10.5461 51.2953 10.3226 51.1704 10.1312C51.0454 9.93982 50.868 9.78861 50.6596 9.69572C50.1422 9.42459 49.5643 9.29063 48.9808 9.30656C48.5239 9.30656 47.1093 9.37832 46.1736 10.3498C45.7498 10.8108 45.4168 11.5201 45.1498 12.4585C44.8466 13.7278 44.7106 15.0315 44.7453 16.3363C44.7387 17.2126 44.7976 18.0881 44.9214 18.9556C45.0218 19.6928 45.2363 20.4099 45.5571 21.0808V21.0973C46.2617 22.5491 48.1331 22.7533 49.1927 22.7533C50.2907 22.7296 51.3454 22.3193 52.1721 21.5942C52.9988 20.8691 53.5448 19.8754 53.7144 18.7872H54.6667C53.9951 22.0909 51.7714 23.631 48.8762 23.6669Z;
                M52.8762 23.6669H52.7689C51.9069 23.6709 51.0496 23.5386 50.2287 23.275C49.3795 22.9822 48.5809 22.5585 47.8618 22.0192C47.0915 21.4046 46.4696 20.6234 46.0427 19.7339C45.537 18.641 45.2956 17.4438 45.3381 16.2397C45.3344 15.2135 45.5395 14.1973 45.9409 13.2534C46.3363 12.3546 46.8964 11.5382 47.5921 10.8466C48.2867 10.1529 49.1169 9.61067 50.0305 9.25412C51.5465 8.62994 53.2198 8.49959 54.8137 8.88152C55.4117 9.0191 55.9878 9.23924 56.5255 9.53564C57.0307 9.83429 57.4661 10.238 57.8025 10.7197C58.1536 11.2303 58.3364 11.8383 58.3254 12.4585C58.3307 12.6769 58.2928 12.8943 58.2139 13.098C58.135 13.3017 58.0167 13.4877 57.8658 13.6453C57.5075 13.9651 57.0375 14.1299 56.5585 14.1035C56.1785 14.1 55.8034 14.0163 55.4577 13.8578C55.2844 13.7818 55.1387 13.6541 55.0402 13.4922C54.9418 13.3302 54.8954 13.1418 54.9073 12.9525C54.9176 12.5649 54.9948 12.182 55.1357 11.8209C55.2628 11.4854 55.3398 11.133 55.3641 10.7749C55.3626 10.5461 55.2953 10.3226 55.1704 10.1312C55.0454 9.93982 54.8681 9.78861 54.6596 9.69572C54.1422 9.42459 53.5643 9.29063 52.9808 9.30656C52.5239 9.30656 51.1093 9.37832 50.1736 10.3498C49.7498 10.8108 49.4168 11.5201 49.1498 12.4585C48.8466 13.7278 48.7106 15.0315 48.7453 16.3363C48.7387 17.2126 48.7976 18.0881 48.9214 18.9556C49.0218 19.6928 49.2363 20.4099 49.5571 21.0808V21.0973C50.2617 22.5491 52.1331 22.7533 53.1927 22.7533C54.2907 22.7296 55.3454 22.3193 56.1721 21.5942C56.9988 20.8691 57.5448 19.8754 57.7144 18.7872H58.6667C57.9952 22.0909 55.7714 23.631 52.8762 23.6669Z;
                M56.8762 23.6669H56.7689C55.9069 23.6709 55.0496 23.5386 54.2287 23.275C53.3795 22.9822 52.5809 22.5585 51.8618 22.0192C51.0915 21.4046 50.4696 20.6234 50.0427 19.7339C49.537 18.641 49.2956 17.4438 49.3381 16.2397C49.3344 15.2135 49.5395 14.1973 49.9409 13.2534C50.3363 12.3546 50.8964 11.5382 51.5921 10.8466C52.2867 10.1529 53.1169 9.61067 54.0305 9.25412C55.5465 8.62994 57.2198 8.49959 58.8137 8.88152C59.4117 9.0191 59.9878 9.23924 60.5255 9.53564C61.0307 9.83429 61.4661 10.238 61.8025 10.7197C62.1536 11.2303 62.3364 11.8383 62.3254 12.4585C62.3307 12.6769 62.2928 12.8943 62.2139 13.098C62.135 13.3017 62.0167 13.4877 61.8658 13.6453C61.5075 13.9651 61.0375 14.1299 60.5585 14.1035C60.1785 14.1 59.8034 14.0163 59.4577 13.8578C59.2844 13.7818 59.1387 13.6541 59.0402 13.4922C58.9418 13.3302 58.8954 13.1418 58.9073 12.9525C58.9176 12.5649 58.9948 12.182 59.1357 11.8209C59.2628 11.4854 59.3398 11.133 59.3641 10.7749C59.3626 10.5461 59.2953 10.3226 59.1704 10.1312C59.0454 9.93982 58.8681 9.78861 58.6596 9.69572C58.1422 9.42459 57.5643 9.29063 56.9808 9.30656C56.5239 9.30656 55.1093 9.37832 54.1736 10.3498C53.7498 10.8108 53.4168 11.5201 53.1498 12.4585C52.8466 13.7278 52.7106 15.0315 52.7453 16.3363C52.7387 17.2126 52.7976 18.0881 52.9214 18.9556C53.0218 19.6928 53.2363 20.4099 53.5571 21.0808V21.0973C54.2617 22.5491 56.1331 22.7533 57.1927 22.7533C58.2907 22.7296 59.3454 22.3193 60.1721 21.5942C60.9988 20.8691 61.5448 19.8754 61.7144 18.7872H62.6667C61.9952 22.0909 59.7714 23.631 56.8762 23.6669Z;
                M68.8762 23.6669H68.7689C67.9069 23.6709 67.0496 23.5386 66.2287 23.275C65.3795 22.9822 64.581 22.5585 63.8619 22.0192C63.0915 21.4046 62.4696 20.6234 62.0427 19.7339C61.537 18.641 61.2956 17.4438 61.3382 16.2397C61.3344 15.2135 61.5395 14.1973 61.9409 13.2534C62.3363 12.3546 62.8965 11.5382 63.5922 10.8466C64.2868 10.1529 65.1169 9.61067 66.0305 9.25412C67.5465 8.62994 69.2198 8.49959 70.8137 8.88152C71.4118 9.0191 71.9879 9.23924 72.5256 9.53564C73.0307 9.83429 73.4661 10.238 73.8025 10.7197C74.1536 11.2303 74.3365 11.8383 74.3254 12.4585C74.3307 12.6769 74.2928 12.8943 74.2139 13.098C74.135 13.3017 74.0167 13.4877 73.8658 13.6453C73.5075 13.9651 73.0376 14.1299 72.5586 14.1035C72.1785 14.1 71.8034 14.0163 71.4577 13.8578C71.2845 13.7818 71.1387 13.6541 71.0403 13.4922C70.9418 13.3302 70.8954 13.1418 70.9073 12.9525C70.9176 12.5649 70.9949 12.182 71.1357 11.8209C71.2629 11.4854 71.3398 11.133 71.3642 10.7749C71.3626 10.5461 71.2954 10.3226 71.1704 10.1312C71.0454 9.93982 70.8681 9.78861 70.6596 9.69572C70.1422 9.42459 69.5643 9.29063 68.9808 9.30656C68.524 9.30656 67.1094 9.37832 66.1737 10.3498C65.7498 10.8108 65.4168 11.5201 65.1499 12.4585C64.8466 13.7278 64.7106 15.0315 64.7453 16.3363C64.7387 17.2126 64.7976 18.0881 64.9214 18.9556C65.0218 19.6928 65.2363 20.4099 65.5572 21.0808V21.0973C66.2617 22.5491 68.1332 22.7533 69.1927 22.7533C70.2908 22.7296 71.3455 22.3193 72.1721 21.5942C72.9988 20.8691 73.5448 19.8754 73.7145 18.7872H74.6667C73.9952 22.0909 71.7715 23.631 68.8762 23.6669Z;
                M72.8762 23.6669H72.7689C71.9069 23.6709 71.0496 23.5386 70.2287 23.275C69.3795 22.9822 68.581 22.5585 67.8619 22.0192C67.0915 21.4046 66.4696 20.6234 66.0427 19.7339C65.537 18.641 65.2956 17.4438 65.3382 16.2397C65.3344 15.2135 65.5395 14.1973 65.9409 13.2534C66.3363 12.3546 66.8965 11.5382 67.5922 10.8466C68.2868 10.1529 69.1169 9.61067 70.0305 9.25412C71.5465 8.62994 73.2198 8.49959 74.8137 8.88152C75.4118 9.0191 75.9879 9.23924 76.5256 9.53564C77.0307 9.83429 77.4661 10.238 77.8025 10.7197C78.1536 11.2303 78.3365 11.8383 78.3254 12.4585C78.3307 12.6769 78.2928 12.8943 78.2139 13.098C78.135 13.3017 78.0167 13.4877 77.8658 13.6453C77.5075 13.9651 77.0376 14.1299 76.5586 14.1035C76.1785 14.1 75.8034 14.0163 75.4577 13.8578C75.2845 13.7818 75.1387 13.6541 75.0403 13.4922C74.9418 13.3302 74.8954 13.1418 74.9073 12.9525C74.9176 12.5649 74.9949 12.182 75.1357 11.8209C75.2629 11.4854 75.3398 11.133 75.3642 10.7749C75.3626 10.5461 75.2954 10.3226 75.1704 10.1312C75.0454 9.93982 74.8681 9.78861 74.6596 9.69572C74.1422 9.42459 73.5643 9.29063 72.9808 9.30656C72.524 9.30656 71.1094 9.37832 70.1737 10.3498C69.7498 10.8108 69.4168 11.5201 69.1499 12.4585C68.8466 13.7278 68.7106 15.0315 68.7453 16.3363C68.7387 17.2126 68.7976 18.0881 68.9214 18.9556C69.0218 19.6928 69.2363 20.4099 69.5572 21.0808V21.0973C70.2617 22.5491 72.1332 22.7533 73.1927 22.7533C74.2908 22.7296 75.3455 22.3193 76.1721 21.5942C76.9988 20.8691 77.5448 19.8754 77.7145 18.7872H78.6667C77.9952 22.0909 75.7715 23.631 72.8762 23.6669Z;
                M76.8762 23.6669H76.7688C75.9068 23.6709 75.0496 23.5386 74.2286 23.275C73.3794 22.9822 72.5809 22.5585 71.8618 22.0192C71.0914 21.4046 70.4696 20.6234 70.0427 19.7339C69.5369 18.641 69.2955 17.4438 69.3381 16.2397C69.3344 15.2135 69.5395 14.1973 69.9408 13.2534C70.3363 12.3546 70.8964 11.5382 71.5921 10.8466C72.2867 10.1529 73.1169 9.61067 74.0305 9.25412C75.5465 8.62994 77.2198 8.49959 78.8137 8.88152C79.4117 9.0191 79.9878 9.23924 80.5255 9.53564C81.0306 9.83429 81.4661 10.238 81.8025 10.7197C82.1536 11.2303 82.3364 11.8383 82.3254 12.4585C82.3306 12.6769 82.2927 12.8943 82.2139 13.098C82.135 13.3017 82.0167 13.4877 81.8658 13.6453C81.5074 13.9651 81.0375 14.1299 80.5585 14.1035C80.1784 14.1 79.8034 14.0163 79.4577 13.8578C79.2844 13.7818 79.1387 13.6541 79.0402 13.4922C78.9417 13.3302 78.8953 13.1418 78.9072 12.9525C78.9175 12.5649 78.9948 12.182 79.1357 11.8209C79.2628 11.4854 79.3398 11.133 79.3641 10.7749C79.3626 10.5461 79.2953 10.3226 79.1703 10.1312C79.0454 9.93982 78.868 9.78861 78.6596 9.69572C78.1422 9.42459 77.5643 9.29063 76.9808 9.30656C76.5239 9.30656 75.1093 9.37832 74.1736 10.3498C73.7498 10.8108 73.4168 11.5201 73.1498 12.4585C72.8466 13.7278 72.7105 15.0315 72.7452 16.3363C72.7387 17.2126 72.7975 18.0881 72.9214 18.9556C73.0217 19.6928 73.2362 20.4099 73.5571 21.0808V21.0973C74.2617 22.5491 76.1331 22.7533 77.1927 22.7533C78.2907 22.7296 79.3454 22.3193 80.1721 21.5942C80.9988 20.8691 81.5448 19.8754 81.7144 18.7872H82.6666C81.9951 22.0909 79.7714 23.631 76.8762 23.6669Z;
                M80.8762 23.6669H80.7688C79.9068 23.6709 79.0496 23.5386 78.2286 23.275C77.3794 22.9822 76.5809 22.5585 75.8618 22.0192C75.0914 21.4046 74.4696 20.6234 74.0427 19.7339C73.5369 18.641 73.2955 17.4438 73.3381 16.2397C73.3344 15.2135 73.5395 14.1973 73.9408 13.2534C74.3363 12.3546 74.8964 11.5382 75.5921 10.8466C76.2867 10.1529 77.1169 9.61067 78.0305 9.25412C79.5465 8.62994 81.2198 8.49959 82.8137 8.88152C83.4117 9.0191 83.9878 9.23924 84.5255 9.53564C85.0306 9.83429 85.4661 10.238 85.8025 10.7197C86.1536 11.2303 86.3364 11.8383 86.3254 12.4585C86.3306 12.6769 86.2927 12.8943 86.2139 13.098C86.135 13.3017 86.0167 13.4877 85.8658 13.6453C85.5074 13.9651 85.0375 14.1299 84.5585 14.1035C84.1784 14.1 83.8034 14.0163 83.4577 13.8578C83.2844 13.7818 83.1387 13.6541 83.0402 13.4922C82.9417 13.3302 82.8953 13.1418 82.9072 12.9525C82.9175 12.5649 82.9948 12.182 83.1357 11.8209C83.2628 11.4854 83.3398 11.133 83.3641 10.7749C83.3626 10.5461 83.2953 10.3226 83.1703 10.1312C83.0454 9.93982 82.868 9.78861 82.6596 9.69572C82.1422 9.42459 81.5643 9.29063 80.9808 9.30656C80.5239 9.30656 79.1093 9.37832 78.1736 10.3498C77.7498 10.8108 77.4168 11.5201 77.1498 12.4585C76.8466 13.7278 76.7105 15.0315 76.7452 16.3363C76.7387 17.2126 76.7975 18.0881 76.9214 18.9556C77.0217 19.6928 77.2362 20.4099 77.5571 21.0808V21.0973C78.2617 22.5491 80.1331 22.7533 81.1927 22.7533C82.2907 22.7296 83.3454 22.3193 84.1721 21.5942C84.9988 20.8691 85.5448 19.8754 85.7144 18.7872H86.6666C85.9951 22.0909 83.7714 23.631 80.8762 23.6669Z;
                M80.8762 23.6669H80.7689C79.9069 23.6709 79.0496 23.5386 78.2287 23.275C77.3795 22.9822 76.5809 22.5585 75.8618 22.0192C75.0915 21.4046 74.4696 20.6234 74.0427 19.7339C73.537 18.641 73.2956 17.4438 73.3381 16.2397C73.3344 15.2135 73.5395 14.1973 73.9409 13.2534C74.3363 12.3546 74.8964 11.5382 75.5921 10.8466C76.2867 10.1529 77.1169 9.61067 78.0305 9.25412C79.5465 8.62994 81.2198 8.49959 82.8137 8.88152C83.4117 9.0191 83.9878 9.23924 84.5255 9.53564C85.0307 9.83429 85.4661 10.238 85.8025 10.7197C86.1536 11.2303 86.3364 11.8383 86.3254 12.4585C86.3307 12.6769 86.2928 12.8943 86.2139 13.098C86.135 13.3017 86.0167 13.4877 85.8658 13.6453C85.5075 13.9651 85.0375 14.1299 84.5585 14.1035C84.1785 14.1 83.8034 14.0163 83.4577 13.8578C83.2844 13.7818 83.1387 13.6541 83.0402 13.4922C82.9418 13.3302 82.8954 13.1418 82.9073 12.9525C82.9176 12.5649 82.9948 12.182 83.1357 11.8209C83.2628 11.4854 83.3398 11.133 83.3641 10.7749C83.3626 10.5461 83.2953 10.3226 83.1704 10.1312C83.0454 9.93982 82.8681 9.78861 82.6596 9.69572C82.1422 9.42459 81.5643 9.29063 80.9808 9.30656C80.5239 9.30656 79.1093 9.37832 78.1736 10.3498C77.7498 10.8108 77.4168 11.5201 77.1498 12.4585C76.8466 13.7278 76.7106 15.0315 76.7453 16.3363C76.7387 17.2126 76.7976 18.0881 76.9214 18.9556C77.0218 19.6928 77.2363 20.4099 77.5571 21.0808V21.0973C78.2617 22.5491 80.1331 22.7533 81.1927 22.7533C82.2907 22.7296 83.3454 22.3193 84.1721 21.5942C84.9988 20.8691 85.5448 19.8754 85.7144 18.7872H86.6667C85.9952 22.0909 83.7714 23.631 80.8762 23.6669Z;
                M84.8762 23.6669H84.7689C83.9068 23.6709 83.0496 23.5386 82.2286 23.275C81.3795 22.9822 80.5809 22.5585 79.8618 22.0192C79.0915 21.4046 78.4696 20.6234 78.0427 19.7339C77.5369 18.641 77.2956 17.4438 77.3381 16.2397C77.3344 15.2135 77.5395 14.1973 77.9408 13.2534C78.3363 12.3546 78.8964 11.5382 79.5921 10.8466C80.2867 10.1529 81.1169 9.61067 82.0305 9.25412C83.5465 8.62994 85.2198 8.49959 86.8137 8.88152C87.4117 9.0191 87.9878 9.23924 88.5255 9.53564C89.0306 9.83429 89.4661 10.238 89.8025 10.7197C90.1536 11.2303 90.3364 11.8383 90.3254 12.4585C90.3307 12.6769 90.2928 12.8943 90.2139 13.098C90.135 13.3017 90.0167 13.4877 89.8658 13.6453C89.5075 13.9651 89.0375 14.1299 88.5585 14.1035C88.1785 14.1 87.8034 14.0163 87.4577 13.8578C87.2844 13.7818 87.1387 13.6541 87.0402 13.4922C86.9418 13.3302 86.8953 13.1418 86.9073 12.9525C86.9175 12.5649 86.9948 12.182 87.1357 11.8209C87.2628 11.4854 87.3398 11.133 87.3641 10.7749C87.3626 10.5461 87.2953 10.3226 87.1704 10.1312C87.0454 9.93982 86.868 9.78861 86.6596 9.69572C86.1422 9.42459 85.5643 9.29063 84.9808 9.30656C84.5239 9.30656 83.1093 9.37832 82.1736 10.3498C81.7498 10.8108 81.4168 11.5201 81.1498 12.4585C80.8466 13.7278 80.7106 15.0315 80.7453 16.3363C80.7387 17.2126 80.7976 18.0881 80.9214 18.9556C81.0218 19.6928 81.2363 20.4099 81.5571 21.0808V21.0973C82.2617 22.5491 84.1331 22.7533 85.1927 22.7533C86.2907 22.7296 87.3454 22.3193 88.1721 21.5942C88.9988 20.8691 89.5448 19.8754 89.7144 18.7872H90.6667C89.9951 22.0909 87.7714 23.631 84.8762 23.6669Z;
                M88.8762 23.6669H88.7689C87.9069 23.6709 87.0496 23.5386 86.2287 23.275C85.3795 22.9822 84.5809 22.5585 83.8618 22.0192C83.0915 21.4046 82.4696 20.6234 82.0427 19.7339C81.5369 18.641 81.2956 17.4438 81.3381 16.2397C81.3344 15.2135 81.5395 14.1973 81.9408 13.2534C82.3363 12.3546 82.8964 11.5382 83.5921 10.8466C84.2867 10.1529 85.1169 9.61067 86.0305 9.25412C87.5465 8.62994 89.2198 8.49959 90.8137 8.88152C91.4117 9.0191 91.9878 9.23924 92.5255 9.53564C93.0306 9.83429 93.4661 10.238 93.8025 10.7197C94.1536 11.2303 94.3364 11.8383 94.3254 12.4585C94.3307 12.6769 94.2928 12.8943 94.2139 13.098C94.135 13.3017 94.0167 13.4877 93.8658 13.6453C93.5075 13.9651 93.0375 14.1299 92.5585 14.1035C92.1785 14.1 91.8034 14.0163 91.4577 13.8578C91.2844 13.7818 91.1387 13.6541 91.0402 13.4922C90.9418 13.3302 90.8953 13.1418 90.9073 12.9525C90.9175 12.5649 90.9948 12.182 91.1357 11.8209C91.2628 11.4854 91.3398 11.133 91.3641 10.7749C91.3626 10.5461 91.2953 10.3226 91.1704 10.1312C91.0454 9.93982 90.868 9.78861 90.6596 9.69572C90.1422 9.42459 89.5643 9.29063 88.9808 9.30656C88.5239 9.30656 87.1093 9.37832 86.1736 10.3498C85.7498 10.8108 85.4168 11.5201 85.1498 12.4585C84.8466 13.7278 84.7106 15.0315 84.7453 16.3363C84.7387 17.2126 84.7976 18.0881 84.9214 18.9556C85.0218 19.6928 85.2363 20.4099 85.5571 21.0808V21.0973C86.2617 22.5491 88.1331 22.7533 89.1927 22.7533C90.2907 22.7296 91.3454 22.3193 92.1721 21.5942C92.9988 20.8691 93.5448 19.8754 93.7144 18.7872H94.6667C93.9951 22.0909 91.7714 23.631 88.8762 23.6669Z;
                M92.8762 23.6669H92.7689C91.9069 23.6709 91.0496 23.5386 90.2287 23.275C89.3795 22.9822 88.5809 22.5585 87.8618 22.0192C87.0915 21.4046 86.4696 20.6234 86.0427 19.7339C85.537 18.641 85.2956 17.4438 85.3381 16.2397C85.3344 15.2135 85.5395 14.1973 85.9409 13.2534C86.3363 12.3546 86.8964 11.5382 87.5921 10.8466C88.2867 10.1529 89.1169 9.61067 90.0305 9.25412C91.5465 8.62994 93.2198 8.49959 94.8137 8.88152C95.4117 9.0191 95.9878 9.23924 96.5255 9.53564C97.0307 9.83429 97.4661 10.238 97.8025 10.7197C98.1536 11.2303 98.3364 11.8383 98.3254 12.4585C98.3307 12.6769 98.2928 12.8943 98.2139 13.098C98.135 13.3017 98.0167 13.4877 97.8658 13.6453C97.5075 13.9651 97.0375 14.1299 96.5585 14.1035C96.1785 14.1 95.8034 14.0163 95.4577 13.8578C95.2844 13.7818 95.1387 13.6541 95.0402 13.4922C94.9418 13.3302 94.8954 13.1418 94.9073 12.9525C94.9176 12.5649 94.9948 12.182 95.1357 11.8209C95.2628 11.4854 95.3398 11.133 95.3641 10.7749C95.3626 10.5461 95.2953 10.3226 95.1704 10.1312C95.0454 9.93982 94.8681 9.78861 94.6596 9.69572C94.1422 9.42459 93.5643 9.29063 92.9808 9.30656C92.5239 9.30656 91.1093 9.37832 90.1736 10.3498C89.7498 10.8108 89.4168 11.5201 89.1498 12.4585C88.8466 13.7278 88.7106 15.0315 88.7453 16.3363C88.7387 17.2126 88.7976 18.0881 88.9214 18.9556C89.0218 19.6928 89.2363 20.4099 89.5571 21.0808V21.0973C90.2617 22.5491 92.1331 22.7533 93.1927 22.7533C94.2907 22.7296 95.3454 22.3193 96.1721 21.5942C96.9988 20.8691 97.5448 19.8754 97.7144 18.7872H98.6667C97.9952 22.0909 95.7714 23.631 92.8762 23.6669Z;
                M96.8762 23.6669H96.7689C95.9069 23.6709 95.0496 23.5386 94.2287 23.275C93.3795 22.9822 92.5809 22.5585 91.8618 22.0192C91.0915 21.4046 90.4696 20.6234 90.0427 19.7339C89.537 18.641 89.2956 17.4438 89.3381 16.2397C89.3344 15.2135 89.5395 14.1973 89.9409 13.2534C90.3363 12.3546 90.8964 11.5382 91.5921 10.8466C92.2867 10.1529 93.1169 9.61067 94.0305 9.25412C95.5465 8.62994 97.2198 8.49959 98.8137 8.88152C99.4117 9.0191 99.9878 9.23924 100.526 9.53564C101.031 9.83429 101.466 10.238 101.803 10.7197C102.154 11.2303 102.336 11.8383 102.325 12.4585C102.331 12.6769 102.293 12.8943 102.214 13.098C102.135 13.3017 102.017 13.4877 101.866 13.6453C101.507 13.9651 101.038 14.1299 100.559 14.1035C100.178 14.1 99.8034 14.0163 99.4577 13.8578C99.2844 13.7818 99.1387 13.6541 99.0402 13.4922C98.9418 13.3302 98.8954 13.1418 98.9073 12.9525C98.9176 12.5649 98.9948 12.182 99.1357 11.8209C99.2628 11.4854 99.3398 11.133 99.3641 10.7749C99.3626 10.5461 99.2953 10.3226 99.1704 10.1312C99.0454 9.93982 98.8681 9.78861 98.6596 9.69572C98.1422 9.42459 97.5643 9.29063 96.9808 9.30656C96.5239 9.30656 95.1093 9.37832 94.1736 10.3498C93.7498 10.8108 93.4168 11.5201 93.1498 12.4585C92.8466 13.7278 92.7106 15.0315 92.7453 16.3363C92.7387 17.2126 92.7976 18.0881 92.9214 18.9556C93.0218 19.6928 93.2363 20.4099 93.5571 21.0808V21.0973C94.2617 22.5491 96.1331 22.7533 97.1927 22.7533C98.2907 22.7296 99.3454 22.3193 100.172 21.5942C100.999 20.8691 101.545 19.8754 101.714 18.7872H102.667C101.995 22.0909 99.7714 23.631 96.8762 23.6669Z;
                M108.876 23.6669H108.769C107.907 23.6709 107.05 23.5386 106.229 23.275C105.38 22.9822 104.581 22.5585 103.862 22.0192C103.091 21.4046 102.47 20.6234 102.043 19.7339C101.537 18.641 101.296 17.4438 101.338 16.2397C101.334 15.2135 101.54 14.1973 101.941 13.2534C102.336 12.3546 102.896 11.5382 103.592 10.8466C104.287 10.1529 105.117 9.61067 106.031 9.25412C107.547 8.62994 109.22 8.49959 110.814 8.88152C111.412 9.0191 111.988 9.23924 112.526 9.53564C113.031 9.83429 113.466 10.238 113.803 10.7197C114.154 11.2303 114.336 11.8383 114.325 12.4585C114.331 12.6769 114.293 12.8943 114.214 13.098C114.135 13.3017 114.017 13.4877 113.866 13.6453C113.508 13.9651 113.038 14.1299 112.559 14.1035C112.179 14.1 111.803 14.0163 111.458 13.8578C111.284 13.7818 111.139 13.6541 111.04 13.4922C110.942 13.3302 110.895 13.1418 110.907 12.9525C110.918 12.5649 110.995 12.182 111.136 11.8209C111.263 11.4854 111.34 11.133 111.364 10.7749C111.363 10.5461 111.295 10.3226 111.17 10.1312C111.045 9.93982 110.868 9.78861 110.66 9.69572C110.142 9.42459 109.564 9.29063 108.981 9.30656C108.524 9.30656 107.109 9.37832 106.174 10.3498C105.75 10.8108 105.417 11.5201 105.15 12.4585C104.847 13.7278 104.711 15.0315 104.745 16.3363C104.739 17.2126 104.798 18.0881 104.921 18.9556C105.022 19.6928 105.236 20.4099 105.557 21.0808V21.0973C106.262 22.5491 108.133 22.7533 109.193 22.7533C110.291 22.7296 111.345 22.3193 112.172 21.5942C112.999 20.8691 113.545 19.8754 113.714 18.7872H114.667C113.995 22.0909 111.771 23.631 108.876 23.6669Z;
                M112.876 23.6669H112.769C111.907 23.6709 111.05 23.5386 110.229 23.275C109.38 22.9822 108.581 22.5585 107.862 22.0192C107.091 21.4046 106.47 20.6234 106.043 19.7339C105.537 18.641 105.296 17.4438 105.338 16.2397C105.334 15.2135 105.54 14.1973 105.941 13.2534C106.336 12.3546 106.896 11.5382 107.592 10.8466C108.287 10.1529 109.117 9.61067 110.031 9.25412C111.547 8.62994 113.22 8.49959 114.814 8.88152C115.412 9.0191 115.988 9.23924 116.526 9.53564C117.031 9.83429 117.466 10.238 117.803 10.7197C118.154 11.2303 118.336 11.8383 118.325 12.4585C118.331 12.6769 118.293 12.8943 118.214 13.098C118.135 13.3017 118.017 13.4877 117.866 13.6453C117.508 13.9651 117.038 14.1299 116.559 14.1035C116.179 14.1 115.803 14.0163 115.458 13.8578C115.284 13.7818 115.139 13.6541 115.04 13.4922C114.942 13.3302 114.895 13.1418 114.907 12.9525C114.918 12.5649 114.995 12.182 115.136 11.8209C115.263 11.4854 115.34 11.133 115.364 10.7749C115.363 10.5461 115.295 10.3226 115.17 10.1312C115.045 9.93982 114.868 9.78861 114.66 9.69572C114.142 9.42459 113.564 9.29063 112.981 9.30656C112.524 9.30656 111.109 9.37832 110.174 10.3498C109.75 10.8108 109.417 11.5201 109.15 12.4585C108.847 13.7278 108.711 15.0315 108.745 16.3363C108.739 17.2126 108.798 18.0881 108.921 18.9556C109.022 19.6928 109.236 20.4099 109.557 21.0808V21.0973C110.262 22.5491 112.133 22.7533 113.193 22.7533C114.291 22.7296 115.345 22.3193 116.172 21.5942C116.999 20.8691 117.545 19.8754 117.714 18.7872H118.667C117.995 22.0909 115.771 23.631 112.876 23.6669Z;
                M116.876 23.6669H116.769C115.907 23.6709 115.05 23.5386 114.229 23.275C113.379 22.9822 112.581 22.5585 111.862 22.0192C111.091 21.4046 110.47 20.6234 110.043 19.7339C109.537 18.641 109.296 17.4438 109.338 16.2397C109.334 15.2135 109.539 14.1973 109.941 13.2534C110.336 12.3546 110.896 11.5382 111.592 10.8466C112.287 10.1529 113.117 9.61067 114.03 9.25412C115.546 8.62994 117.22 8.49959 118.814 8.88152C119.412 9.0191 119.988 9.23924 120.525 9.53564C121.031 9.83429 121.466 10.238 121.802 10.7197C122.154 11.2303 122.336 11.8383 122.325 12.4585C122.331 12.6769 122.293 12.8943 122.214 13.098C122.135 13.3017 122.017 13.4877 121.866 13.6453C121.507 13.9651 121.038 14.1299 120.559 14.1035C120.178 14.1 119.803 14.0163 119.458 13.8578C119.284 13.7818 119.139 13.6541 119.04 13.4922C118.942 13.3302 118.895 13.1418 118.907 12.9525C118.918 12.5649 118.995 12.182 119.136 11.8209C119.263 11.4854 119.34 11.133 119.364 10.7749C119.363 10.5461 119.295 10.3226 119.17 10.1312C119.045 9.93982 118.868 9.78861 118.66 9.69572C118.142 9.42459 117.564 9.29063 116.981 9.30656C116.524 9.30656 115.109 9.37832 114.174 10.3498C113.75 10.8108 113.417 11.5201 113.15 12.4585C112.847 13.7278 112.711 15.0315 112.745 16.3363C112.739 17.2126 112.798 18.0881 112.921 18.9556C113.022 19.6928 113.236 20.4099 113.557 21.0808V21.0973C114.262 22.5491 116.133 22.7533 117.193 22.7533C118.291 22.7296 119.345 22.3193 120.172 21.5942C120.999 20.8691 121.545 19.8754 121.714 18.7872H122.667C121.995 22.0909 119.771 23.631 116.876 23.6669Z;
                M120.876 23.6669H120.769C119.907 23.6709 119.05 23.5386 118.229 23.275C117.379 22.9822 116.581 22.5585 115.862 22.0192C115.091 21.4046 114.47 20.6234 114.043 19.7339C113.537 18.641 113.296 17.4438 113.338 16.2397C113.334 15.2135 113.539 14.1973 113.941 13.2534C114.336 12.3546 114.896 11.5382 115.592 10.8466C116.287 10.1529 117.117 9.61067 118.03 9.25412C119.546 8.62994 121.22 8.49959 122.814 8.88152C123.412 9.0191 123.988 9.23924 124.525 9.53564C125.031 9.83429 125.466 10.238 125.802 10.7197C126.154 11.2303 126.336 11.8383 126.325 12.4585C126.331 12.6769 126.293 12.8943 126.214 13.098C126.135 13.3017 126.017 13.4877 125.866 13.6453C125.507 13.9651 125.038 14.1299 124.559 14.1035C124.178 14.1 123.803 14.0163 123.458 13.8578C123.284 13.7818 123.139 13.6541 123.04 13.4922C122.942 13.3302 122.895 13.1418 122.907 12.9525C122.918 12.5649 122.995 12.182 123.136 11.8209C123.263 11.4854 123.34 11.133 123.364 10.7749C123.363 10.5461 123.295 10.3226 123.17 10.1312C123.045 9.93982 122.868 9.78861 122.66 9.69572C122.142 9.42459 121.564 9.29063 120.981 9.30656C120.524 9.30656 119.109 9.37832 118.174 10.3498C117.75 10.8108 117.417 11.5201 117.15 12.4585C116.847 13.7278 116.711 15.0315 116.745 16.3363C116.739 17.2126 116.798 18.0881 116.921 18.9556C117.022 19.6928 117.236 20.4099 117.557 21.0808V21.0973C118.262 22.5491 120.133 22.7533 121.193 22.7533C122.291 22.7296 123.345 22.3193 124.172 21.5942C124.999 20.8691 125.545 19.8754 125.714 18.7872H126.667C125.995 22.0909 123.771 23.631 120.876 23.6669Z;
                M120.876 23.6669H120.769C119.907 23.6709 119.05 23.5386 118.229 23.275C117.379 22.9822 116.581 22.5585 115.862 22.0192C115.091 21.4046 114.47 20.6234 114.043 19.7339C113.537 18.641 113.296 17.4438 113.338 16.2397C113.334 15.2135 113.539 14.1973 113.941 13.2534C114.336 12.3546 114.896 11.5382 115.592 10.8466C116.287 10.1529 117.117 9.61067 118.031 9.25412C119.546 8.62994 121.22 8.49959 122.814 8.88152C123.412 9.0191 123.988 9.23924 124.526 9.53564C125.031 9.83429 125.466 10.238 125.803 10.7197C126.154 11.2303 126.336 11.8383 126.325 12.4585C126.331 12.6769 126.293 12.8943 126.214 13.098C126.135 13.3017 126.017 13.4877 125.866 13.6453C125.507 13.9651 125.038 14.1299 124.559 14.1035C124.178 14.1 123.803 14.0163 123.458 13.8578C123.284 13.7818 123.139 13.6541 123.04 13.4922C122.942 13.3302 122.895 13.1418 122.907 12.9525C122.918 12.5649 122.995 12.182 123.136 11.8209C123.263 11.4854 123.34 11.133 123.364 10.7749C123.363 10.5461 123.295 10.3226 123.17 10.1312C123.045 9.93982 122.868 9.78861 122.66 9.69572C122.142 9.42459 121.564 9.29063 120.981 9.30656C120.524 9.30656 119.109 9.37832 118.174 10.3498C117.75 10.8108 117.417 11.5201 117.15 12.4585C116.847 13.7278 116.711 15.0315 116.745 16.3363C116.739 17.2126 116.798 18.0881 116.921 18.9556C117.022 19.6928 117.236 20.4099 117.557 21.0808V21.0973C118.262 22.5491 120.133 22.7533 121.193 22.7533C122.291 22.7296 123.345 22.3193 124.172 21.5942C124.999 20.8691 125.545 19.8754 125.714 18.7872H126.667C125.995 22.0909 123.771 23.631 120.876 23.6669Z;
                M124.876 23.6669H124.769C123.907 23.6709 123.05 23.5386 122.229 23.275C121.379 22.9822 120.581 22.5585 119.862 22.0192C119.091 21.4046 118.47 20.6234 118.043 19.7339C117.537 18.641 117.296 17.4438 117.338 16.2397C117.334 15.2135 117.539 14.1973 117.941 13.2534C118.336 12.3546 118.896 11.5382 119.592 10.8466C120.287 10.1529 121.117 9.61067 122.03 9.25412C123.546 8.62994 125.22 8.49959 126.814 8.88152C127.412 9.0191 127.988 9.23924 128.526 9.53564C129.031 9.83429 129.466 10.238 129.802 10.7197C130.154 11.2303 130.336 11.8383 130.325 12.4585C130.331 12.6769 130.293 12.8943 130.214 13.098C130.135 13.3017 130.017 13.4877 129.866 13.6453C129.507 13.9651 129.038 14.1299 128.559 14.1035C128.178 14.1 127.803 14.0163 127.458 13.8578C127.284 13.7818 127.139 13.6541 127.04 13.4922C126.942 13.3302 126.895 13.1418 126.907 12.9525C126.918 12.5649 126.995 12.182 127.136 11.8209C127.263 11.4854 127.34 11.133 127.364 10.7749C127.363 10.5461 127.295 10.3226 127.17 10.1312C127.045 9.93982 126.868 9.78861 126.66 9.69572C126.142 9.42459 125.564 9.29063 124.981 9.30656C124.524 9.30656 123.109 9.37832 122.174 10.3498C121.75 10.8108 121.417 11.5201 121.15 12.4585C120.847 13.7278 120.711 15.0315 120.745 16.3363C120.739 17.2126 120.798 18.0881 120.921 18.9556C121.022 19.6928 121.236 20.4099 121.557 21.0808V21.0973C122.262 22.5491 124.133 22.7533 125.193 22.7533C126.291 22.7296 127.345 22.3193 128.172 21.5942C128.999 20.8691 129.545 19.8754 129.714 18.7872H130.667C129.995 22.0909 127.771 23.631 124.876 23.6669Z;
                M128.876 23.6669H128.769C127.907 23.6709 127.05 23.5386 126.229 23.275C125.379 22.9822 124.581 22.5585 123.862 22.0192C123.091 21.4046 122.47 20.6234 122.043 19.7339C121.537 18.641 121.296 17.4438 121.338 16.2397C121.334 15.2135 121.539 14.1973 121.941 13.2534C122.336 12.3546 122.896 11.5382 123.592 10.8466C124.287 10.1529 125.117 9.61067 126.03 9.25412C127.546 8.62994 129.22 8.49959 130.814 8.88152C131.412 9.0191 131.988 9.23924 132.526 9.53564C133.031 9.83429 133.466 10.238 133.802 10.7197C134.154 11.2303 134.336 11.8383 134.325 12.4585C134.331 12.6769 134.293 12.8943 134.214 13.098C134.135 13.3017 134.017 13.4877 133.866 13.6453C133.507 13.9651 133.038 14.1299 132.559 14.1035C132.178 14.1 131.803 14.0163 131.458 13.8578C131.284 13.7818 131.139 13.6541 131.04 13.4922C130.942 13.3302 130.895 13.1418 130.907 12.9525C130.918 12.5649 130.995 12.182 131.136 11.8209C131.263 11.4854 131.34 11.133 131.364 10.7749C131.363 10.5461 131.295 10.3226 131.17 10.1312C131.045 9.93982 130.868 9.78861 130.66 9.69572C130.142 9.42459 129.564 9.29063 128.981 9.30656C128.524 9.30656 127.109 9.37832 126.174 10.3498C125.75 10.8108 125.417 11.5201 125.15 12.4585C124.847 13.7278 124.711 15.0315 124.745 16.3363C124.739 17.2126 124.798 18.0881 124.921 18.9556C125.022 19.6928 125.236 20.4099 125.557 21.0808V21.0973C126.262 22.5491 128.133 22.7533 129.193 22.7533C130.291 22.7296 131.345 22.3193 132.172 21.5942C132.999 20.8691 133.545 19.8754 133.714 18.7872H134.667C133.995 22.0909 131.771 23.631 128.876 23.6669Z;
                M132.876 23.6669H132.769C131.907 23.6709 131.05 23.5386 130.229 23.275C129.379 22.9822 128.581 22.5585 127.862 22.0192C127.091 21.4046 126.47 20.6234 126.043 19.7339C125.537 18.641 125.296 17.4438 125.338 16.2397C125.334 15.2135 125.539 14.1973 125.941 13.2534C126.336 12.3546 126.896 11.5382 127.592 10.8466C128.287 10.1529 129.117 9.61067 130.031 9.25412C131.546 8.62994 133.22 8.49959 134.814 8.88152C135.412 9.0191 135.988 9.23924 136.526 9.53564C137.031 9.83429 137.466 10.238 137.803 10.7197C138.154 11.2303 138.336 11.8383 138.325 12.4585C138.331 12.6769 138.293 12.8943 138.214 13.098C138.135 13.3017 138.017 13.4877 137.866 13.6453C137.507 13.9651 137.038 14.1299 136.559 14.1035C136.178 14.1 135.803 14.0163 135.458 13.8578C135.284 13.7818 135.139 13.6541 135.04 13.4922C134.942 13.3302 134.895 13.1418 134.907 12.9525C134.918 12.5649 134.995 12.182 135.136 11.8209C135.263 11.4854 135.34 11.133 135.364 10.7749C135.363 10.5461 135.295 10.3226 135.17 10.1312C135.045 9.93982 134.868 9.78861 134.66 9.69572C134.142 9.42459 133.564 9.29063 132.981 9.30656C132.524 9.30656 131.109 9.37832 130.174 10.3498C129.75 10.8108 129.417 11.5201 129.15 12.4585C128.847 13.7278 128.711 15.0315 128.745 16.3363C128.739 17.2126 128.798 18.0881 128.921 18.9556C129.022 19.6928 129.236 20.4099 129.557 21.0808V21.0973C130.262 22.5491 132.133 22.7533 133.193 22.7533C134.291 22.7296 135.345 22.3193 136.172 21.5942C136.999 20.8691 137.545 19.8754 137.714 18.7872H138.667C137.995 22.0909 135.771 23.631 132.876 23.6669Z;
                M136.876 23.6669H136.769C135.907 23.6709 135.05 23.5386 134.229 23.275C133.379 22.9822 132.581 22.5585 131.862 22.0192C131.091 21.4046 130.47 20.6234 130.043 19.7339C129.537 18.641 129.296 17.4438 129.338 16.2397C129.334 15.2135 129.539 14.1973 129.941 13.2534C130.336 12.3546 130.896 11.5382 131.592 10.8466C132.287 10.1529 133.117 9.61067 134.031 9.25412C135.546 8.62994 137.22 8.49959 138.814 8.88152C139.412 9.0191 139.988 9.23924 140.526 9.53564C141.031 9.83429 141.466 10.238 141.803 10.7197C142.154 11.2303 142.336 11.8383 142.325 12.4585C142.331 12.6769 142.293 12.8943 142.214 13.098C142.135 13.3017 142.017 13.4877 141.866 13.6453C141.507 13.9651 141.038 14.1299 140.559 14.1035C140.178 14.1 139.803 14.0163 139.458 13.8578C139.284 13.7818 139.139 13.6541 139.04 13.4922C138.942 13.3302 138.895 13.1418 138.907 12.9525C138.918 12.5649 138.995 12.182 139.136 11.8209C139.263 11.4854 139.34 11.133 139.364 10.7749C139.363 10.5461 139.295 10.3226 139.17 10.1312C139.045 9.93982 138.868 9.78861 138.66 9.69572C138.142 9.42459 137.564 9.29063 136.981 9.30656C136.524 9.30656 135.109 9.37832 134.174 10.3498C133.75 10.8108 133.417 11.5201 133.15 12.4585C132.847 13.7278 132.711 15.0315 132.745 16.3363C132.739 17.2126 132.798 18.0881 132.921 18.9556C133.022 19.6928 133.236 20.4099 133.557 21.0808V21.0973C134.262 22.5491 136.133 22.7533 137.193 22.7533C138.291 22.7296 139.345 22.3193 140.172 21.5942C140.999 20.8691 141.545 19.8754 141.714 18.7872H142.667C141.995 22.0909 139.771 23.631 136.876 23.6669Z;
                M148.876 23.6669H148.769C147.907 23.6709 147.05 23.5386 146.229 23.275C145.38 22.9822 144.581 22.5585 143.862 22.0192C143.091 21.4046 142.47 20.6234 142.043 19.7339C141.537 18.641 141.296 17.4438 141.338 16.2397C141.334 15.2135 141.54 14.1973 141.941 13.2534C142.336 12.3546 142.896 11.5382 143.592 10.8466C144.287 10.1529 145.117 9.61067 146.031 9.25412C147.547 8.62994 149.22 8.49959 150.814 8.88152C151.412 9.0191 151.988 9.23924 152.526 9.53564C153.031 9.83429 153.466 10.238 153.803 10.7197C154.154 11.2303 154.336 11.8383 154.325 12.4585C154.331 12.6769 154.293 12.8943 154.214 13.098C154.135 13.3017 154.017 13.4877 153.866 13.6453C153.508 13.9651 153.038 14.1299 152.559 14.1035C152.179 14.1 151.803 14.0163 151.458 13.8578C151.284 13.7818 151.139 13.6541 151.04 13.4922C150.942 13.3302 150.895 13.1418 150.907 12.9525C150.918 12.5649 150.995 12.182 151.136 11.8209C151.263 11.4854 151.34 11.133 151.364 10.7749C151.363 10.5461 151.295 10.3226 151.17 10.1312C151.045 9.93982 150.868 9.78861 150.66 9.69572C150.142 9.42459 149.564 9.29063 148.981 9.30656C148.524 9.30656 147.109 9.37832 146.174 10.3498C145.75 10.8108 145.417 11.5201 145.15 12.4585C144.847 13.7278 144.711 15.0315 144.745 16.3363C144.739 17.2126 144.798 18.0881 144.921 18.9556C145.022 19.6928 145.236 20.4099 145.557 21.0808V21.0973C146.262 22.5491 148.133 22.7533 149.193 22.7533C150.291 22.7296 151.345 22.3193 152.172 21.5942C152.999 20.8691 153.545 19.8754 153.714 18.7872H154.667C153.995 22.0909 151.771 23.631 148.876 23.6669Z;
                M152.876 23.6669H152.769C151.907 23.6709 151.05 23.5386 150.229 23.275C149.38 22.9822 148.581 22.5585 147.862 22.0192C147.091 21.4046 146.47 20.6234 146.043 19.7339C145.537 18.641 145.296 17.4438 145.338 16.2397C145.334 15.2135 145.54 14.1973 145.941 13.2534C146.336 12.3546 146.896 11.5382 147.592 10.8466C148.287 10.1529 149.117 9.61067 150.031 9.25412C151.547 8.62994 153.22 8.49959 154.814 8.88152C155.412 9.0191 155.988 9.23924 156.526 9.53564C157.031 9.83429 157.466 10.238 157.803 10.7197C158.154 11.2303 158.336 11.8383 158.325 12.4585C158.331 12.6769 158.293 12.8943 158.214 13.098C158.135 13.3017 158.017 13.4877 157.866 13.6453C157.508 13.9651 157.038 14.1299 156.559 14.1035C156.179 14.1 155.803 14.0163 155.458 13.8578C155.284 13.7818 155.139 13.6541 155.04 13.4922C154.942 13.3302 154.895 13.1418 154.907 12.9525C154.918 12.5649 154.995 12.182 155.136 11.8209C155.263 11.4854 155.34 11.133 155.364 10.7749C155.363 10.5461 155.295 10.3226 155.17 10.1312C155.045 9.93982 154.868 9.78861 154.66 9.69572C154.142 9.42459 153.564 9.29063 152.981 9.30656C152.524 9.30656 151.109 9.37832 150.174 10.3498C149.75 10.8108 149.417 11.5201 149.15 12.4585C148.847 13.7278 148.711 15.0315 148.745 16.3363C148.739 17.2126 148.798 18.0881 148.921 18.9556C149.022 19.6928 149.236 20.4099 149.557 21.0808V21.0973C150.262 22.5491 152.133 22.7533 153.193 22.7533C154.291 22.7296 155.345 22.3193 156.172 21.5942C156.999 20.8691 157.545 19.8754 157.714 18.7872H158.667C157.995 22.0909 155.771 23.631 152.876 23.6669Z;
                M156.876 23.6669H156.769C155.907 23.6709 155.05 23.5386 154.229 23.275C153.379 22.9822 152.581 22.5585 151.862 22.0192C151.091 21.4046 150.47 20.6234 150.043 19.7339C149.537 18.641 149.296 17.4438 149.338 16.2397C149.334 15.2135 149.539 14.1973 149.941 13.2534C150.336 12.3546 150.896 11.5382 151.592 10.8466C152.287 10.1529 153.117 9.61067 154.03 9.25412C155.546 8.62994 157.22 8.49959 158.814 8.88152C159.412 9.0191 159.988 9.23924 160.525 9.53564C161.031 9.83429 161.466 10.238 161.802 10.7197C162.154 11.2303 162.336 11.8383 162.325 12.4585C162.331 12.6769 162.293 12.8943 162.214 13.098C162.135 13.3017 162.017 13.4877 161.866 13.6453C161.507 13.9651 161.038 14.1299 160.559 14.1035C160.178 14.1 159.803 14.0163 159.458 13.8578C159.284 13.7818 159.139 13.6541 159.04 13.4922C158.942 13.3302 158.895 13.1418 158.907 12.9525C158.918 12.5649 158.995 12.182 159.136 11.8209C159.263 11.4854 159.34 11.133 159.364 10.7749C159.363 10.5461 159.295 10.3226 159.17 10.1312C159.045 9.93982 158.868 9.78861 158.66 9.69572C158.142 9.42459 157.564 9.29063 156.981 9.30656C156.524 9.30656 155.109 9.37832 154.174 10.3498C153.75 10.8108 153.417 11.5201 153.15 12.4585C152.847 13.7278 152.711 15.0315 152.745 16.3363C152.739 17.2126 152.798 18.0881 152.921 18.9556C153.022 19.6928 153.236 20.4099 153.557 21.0808V21.0973C154.262 22.5491 156.133 22.7533 157.193 22.7533C158.291 22.7296 159.345 22.3193 160.172 21.5942C160.999 20.8691 161.545 19.8754 161.714 18.7872H162.667C161.995 22.0909 159.771 23.631 156.876 23.6669Z;
                M160.876 23.6669H160.769C159.907 23.6709 159.05 23.5386 158.229 23.275C157.379 22.9822 156.581 22.5585 155.862 22.0192C155.091 21.4046 154.47 20.6234 154.043 19.7339C153.537 18.641 153.296 17.4438 153.338 16.2397C153.334 15.2135 153.539 14.1973 153.941 13.2534C154.336 12.3546 154.896 11.5382 155.592 10.8466C156.287 10.1529 157.117 9.61067 158.03 9.25412C159.546 8.62994 161.22 8.49959 162.814 8.88152C163.412 9.0191 163.988 9.23924 164.525 9.53564C165.031 9.83429 165.466 10.238 165.802 10.7197C166.154 11.2303 166.336 11.8383 166.325 12.4585C166.331 12.6769 166.293 12.8943 166.214 13.098C166.135 13.3017 166.017 13.4877 165.866 13.6453C165.507 13.9651 165.038 14.1299 164.559 14.1035C164.178 14.1 163.803 14.0163 163.458 13.8578C163.284 13.7818 163.139 13.6541 163.04 13.4922C162.942 13.3302 162.895 13.1418 162.907 12.9525C162.918 12.5649 162.995 12.182 163.136 11.8209C163.263 11.4854 163.34 11.133 163.364 10.7749C163.363 10.5461 163.295 10.3226 163.17 10.1312C163.045 9.93982 162.868 9.78861 162.66 9.69572C162.142 9.42459 161.564 9.29063 160.981 9.30656C160.524 9.30656 159.109 9.37832 158.174 10.3498C157.75 10.8108 157.417 11.5201 157.15 12.4585C156.847 13.7278 156.711 15.0315 156.745 16.3363C156.739 17.2126 156.798 18.0881 156.921 18.9556C157.022 19.6928 157.236 20.4099 157.557 21.0808V21.0973C158.262 22.5491 160.133 22.7533 161.193 22.7533C162.291 22.7296 163.345 22.3193 164.172 21.5942C164.999 20.8691 165.545 19.8754 165.714 18.7872H166.667C165.995 22.0909 163.771 23.631 160.876 23.6669Z;
                M160.876 23.6669H160.769C159.907 23.6709 159.05 23.5386 158.229 23.275C157.379 22.9822 156.581 22.5585 155.862 22.0192C155.091 21.4046 154.47 20.6234 154.043 19.7339C153.537 18.641 153.296 17.4438 153.338 16.2397C153.334 15.2135 153.539 14.1973 153.941 13.2534C154.336 12.3546 154.896 11.5382 155.592 10.8466C156.287 10.1529 157.117 9.61067 158.031 9.25412C159.546 8.62994 161.22 8.49959 162.814 8.88152C163.412 9.0191 163.988 9.23924 164.526 9.53564C165.031 9.83429 165.466 10.238 165.803 10.7197C166.154 11.2303 166.336 11.8383 166.325 12.4585C166.331 12.6769 166.293 12.8943 166.214 13.098C166.135 13.3017 166.017 13.4877 165.866 13.6453C165.507 13.9651 165.038 14.1299 164.559 14.1035C164.178 14.1 163.803 14.0163 163.458 13.8578C163.284 13.7818 163.139 13.6541 163.04 13.4922C162.942 13.3302 162.895 13.1418 162.907 12.9525C162.918 12.5649 162.995 12.182 163.136 11.8209C163.263 11.4854 163.34 11.133 163.364 10.7749C163.363 10.5461 163.295 10.3226 163.17 10.1312C163.045 9.93982 162.868 9.78861 162.66 9.69572C162.142 9.42459 161.564 9.29063 160.981 9.30656C160.524 9.30656 159.109 9.37832 158.174 10.3498C157.75 10.8108 157.417 11.5201 157.15 12.4585C156.847 13.7278 156.711 15.0315 156.745 16.3363C156.739 17.2126 156.798 18.0881 156.921 18.9556C157.022 19.6928 157.236 20.4099 157.557 21.0808V21.0973C158.262 22.5491 160.133 22.7533 161.193 22.7533C162.291 22.7296 163.345 22.3193 164.172 21.5942C164.999 20.8691 165.545 19.8754 165.714 18.7872H166.667C165.995 22.0909 163.771 23.631 160.876 23.6669Z;
                M164.876 23.6669H164.769C163.907 23.6709 163.05 23.5386 162.229 23.275C161.379 22.9822 160.581 22.5585 159.862 22.0192C159.091 21.4046 158.47 20.6234 158.043 19.7339C157.537 18.641 157.296 17.4438 157.338 16.2397C157.334 15.2135 157.539 14.1973 157.941 13.2534C158.336 12.3546 158.896 11.5382 159.592 10.8466C160.287 10.1529 161.117 9.61067 162.03 9.25412C163.546 8.62994 165.22 8.49959 166.814 8.88152C167.412 9.0191 167.988 9.23924 168.526 9.53564C169.031 9.83429 169.466 10.238 169.802 10.7197C170.154 11.2303 170.336 11.8383 170.325 12.4585C170.331 12.6769 170.293 12.8943 170.214 13.098C170.135 13.3017 170.017 13.4877 169.866 13.6453C169.507 13.9651 169.038 14.1299 168.559 14.1035C168.178 14.1 167.803 14.0163 167.458 13.8578C167.284 13.7818 167.139 13.6541 167.04 13.4922C166.942 13.3302 166.895 13.1418 166.907 12.9525C166.918 12.5649 166.995 12.182 167.136 11.8209C167.263 11.4854 167.34 11.133 167.364 10.7749C167.363 10.5461 167.295 10.3226 167.17 10.1312C167.045 9.93982 166.868 9.78861 166.66 9.69572C166.142 9.42459 165.564 9.29063 164.981 9.30656C164.524 9.30656 163.109 9.37832 162.174 10.3498C161.75 10.8108 161.417 11.5201 161.15 12.4585C160.847 13.7278 160.711 15.0315 160.745 16.3363C160.739 17.2126 160.798 18.0881 160.921 18.9556C161.022 19.6928 161.236 20.4099 161.557 21.0808V21.0973C162.262 22.5491 164.133 22.7533 165.193 22.7533C166.291 22.7296 167.345 22.3193 168.172 21.5942C168.999 20.8691 169.545 19.8754 169.714 18.7872H170.667C169.995 22.0909 167.771 23.631 164.876 23.6669Z;
                M168.876 23.6669H168.769C167.907 23.6709 167.05 23.5386 166.229 23.275C165.379 22.9822 164.581 22.5585 163.862 22.0192C163.091 21.4046 162.47 20.6234 162.043 19.7339C161.537 18.641 161.296 17.4438 161.338 16.2397C161.334 15.2135 161.539 14.1973 161.941 13.2534C162.336 12.3546 162.896 11.5382 163.592 10.8466C164.287 10.1529 165.117 9.61067 166.03 9.25412C167.546 8.62994 169.22 8.49959 170.814 8.88152C171.412 9.0191 171.988 9.23924 172.526 9.53564C173.031 9.83429 173.466 10.238 173.802 10.7197C174.154 11.2303 174.336 11.8383 174.325 12.4585C174.331 12.6769 174.293 12.8943 174.214 13.098C174.135 13.3017 174.017 13.4877 173.866 13.6453C173.507 13.9651 173.038 14.1299 172.559 14.1035C172.178 14.1 171.803 14.0163 171.458 13.8578C171.284 13.7818 171.139 13.6541 171.04 13.4922C170.942 13.3302 170.895 13.1418 170.907 12.9525C170.918 12.5649 170.995 12.182 171.136 11.8209C171.263 11.4854 171.34 11.133 171.364 10.7749C171.363 10.5461 171.295 10.3226 171.17 10.1312C171.045 9.93982 170.868 9.78861 170.66 9.69572C170.142 9.42459 169.564 9.29063 168.981 9.30656C168.524 9.30656 167.109 9.37832 166.174 10.3498C165.75 10.8108 165.417 11.5201 165.15 12.4585C164.847 13.7278 164.711 15.0315 164.745 16.3363C164.739 17.2126 164.798 18.0881 164.921 18.9556C165.022 19.6928 165.236 20.4099 165.557 21.0808V21.0973C166.262 22.5491 168.133 22.7533 169.193 22.7533C170.291 22.7296 171.345 22.3193 172.172 21.5942C172.999 20.8691 173.545 19.8754 173.714 18.7872H174.667C173.995 22.0909 171.771 23.631 168.876 23.6669Z;
                M172.876 23.6669H172.769C171.907 23.6709 171.05 23.5386 170.229 23.275C169.379 22.9822 168.581 22.5585 167.862 22.0192C167.091 21.4046 166.47 20.6234 166.043 19.7339C165.537 18.641 165.296 17.4438 165.338 16.2397C165.334 15.2135 165.539 14.1973 165.941 13.2534C166.336 12.3546 166.896 11.5382 167.592 10.8466C168.287 10.1529 169.117 9.61067 170.031 9.25412C171.546 8.62994 173.22 8.49959 174.814 8.88152C175.412 9.0191 175.988 9.23924 176.526 9.53564C177.031 9.83429 177.466 10.238 177.803 10.7197C178.154 11.2303 178.336 11.8383 178.325 12.4585C178.331 12.6769 178.293 12.8943 178.214 13.098C178.135 13.3017 178.017 13.4877 177.866 13.6453C177.507 13.9651 177.038 14.1299 176.559 14.1035C176.178 14.1 175.803 14.0163 175.458 13.8578C175.284 13.7818 175.139 13.6541 175.04 13.4922C174.942 13.3302 174.895 13.1418 174.907 12.9525C174.918 12.5649 174.995 12.182 175.136 11.8209C175.263 11.4854 175.34 11.133 175.364 10.7749C175.363 10.5461 175.295 10.3226 175.17 10.1312C175.045 9.93982 174.868 9.78861 174.66 9.69572C174.142 9.42459 173.564 9.29063 172.981 9.30656C172.524 9.30656 171.109 9.37832 170.174 10.3498C169.75 10.8108 169.417 11.5201 169.15 12.4585C168.847 13.7278 168.711 15.0315 168.745 16.3363C168.739 17.2126 168.798 18.0881 168.921 18.9556C169.022 19.6928 169.236 20.4099 169.557 21.0808V21.0973C170.262 22.5491 172.133 22.7533 173.193 22.7533C174.291 22.7296 175.345 22.3193 176.172 21.5942C176.999 20.8691 177.545 19.8754 177.714 18.7872H178.667C177.995 22.0909 175.771 23.631 172.876 23.6669Z;
                M176.876 23.6669H176.769C175.907 23.6709 175.05 23.5386 174.229 23.275C173.379 22.9822 172.581 22.5585 171.862 22.0192C171.091 21.4046 170.47 20.6234 170.043 19.7339C169.537 18.641 169.296 17.4438 169.338 16.2397C169.334 15.2135 169.539 14.1973 169.941 13.2534C170.336 12.3546 170.896 11.5382 171.592 10.8466C172.287 10.1529 173.117 9.61067 174.031 9.25412C175.546 8.62994 177.22 8.49959 178.814 8.88152C179.412 9.0191 179.988 9.23924 180.526 9.53564C181.031 9.83429 181.466 10.238 181.803 10.7197C182.154 11.2303 182.336 11.8383 182.325 12.4585C182.331 12.6769 182.293 12.8943 182.214 13.098C182.135 13.3017 182.017 13.4877 181.866 13.6453C181.507 13.9651 181.038 14.1299 180.559 14.1035C180.178 14.1 179.803 14.0163 179.458 13.8578C179.284 13.7818 179.139 13.6541 179.04 13.4922C178.942 13.3302 178.895 13.1418 178.907 12.9525C178.918 12.5649 178.995 12.182 179.136 11.8209C179.263 11.4854 179.34 11.133 179.364 10.7749C179.363 10.5461 179.295 10.3226 179.17 10.1312C179.045 9.93982 178.868 9.78861 178.66 9.69572C178.142 9.42459 177.564 9.29063 176.981 9.30656C176.524 9.30656 175.109 9.37832 174.174 10.3498C173.75 10.8108 173.417 11.5201 173.15 12.4585C172.847 13.7278 172.711 15.0315 172.745 16.3363C172.739 17.2126 172.798 18.0881 172.921 18.9556C173.022 19.6928 173.236 20.4099 173.557 21.0808V21.0973C174.262 22.5491 176.133 22.7533 177.193 22.7533C178.291 22.7296 179.345 22.3193 180.172 21.5942C180.999 20.8691 181.545 19.8754 181.714 18.7872H182.667C181.995 22.0909 179.771 23.631 176.876 23.6669Z;
                M176.876 23.6669H176.769C175.907 23.6709 175.05 23.5386 174.229 23.275C173.379 22.9822 172.581 22.5585 171.862 22.0192C171.091 21.4046 170.47 20.6234 170.043 19.7339C169.537 18.641 169.296 17.4438 169.338 16.2397C169.334 15.2135 169.539 14.1973 169.941 13.2534C170.336 12.3546 170.896 11.5382 171.592 10.8466C172.287 10.1529 173.117 9.61067 174.031 9.25412C175.546 8.62994 177.22 8.49959 178.814 8.88152C179.412 9.0191 179.988 9.23924 180.526 9.53564C181.031 9.83429 181.466 10.238 181.803 10.7197C182.154 11.2303 182.336 11.8383 182.325 12.4585C182.331 12.6769 182.293 12.8943 182.214 13.098C182.135 13.3017 182.017 13.4877 181.866 13.6453C181.507 13.9651 181.038 14.1299 180.559 14.1035C180.178 14.1 179.803 14.0163 179.458 13.8578C179.284 13.7818 179.139 13.6541 179.04 13.4922C178.942 13.3302 178.895 13.1418 178.907 12.9525C178.918 12.5649 178.995 12.182 179.136 11.8209C179.263 11.4854 179.34 11.133 179.364 10.7749C179.363 10.5461 179.295 10.3226 179.17 10.1312C179.045 9.93982 178.868 9.78861 178.66 9.69572C178.142 9.42459 177.564 9.29063 176.981 9.30656C176.524 9.30656 175.109 9.37832 174.174 10.3498C173.75 10.8108 173.417 11.5201 173.15 12.4585C172.847 13.7278 172.711 15.0315 172.745 16.3363C172.739 17.2126 172.798 18.0881 172.921 18.9556C173.022 19.6928 173.236 20.4099 173.557 21.0808V21.0973C174.262 22.5491 176.133 22.7533 177.193 22.7533C178.291 22.7296 179.345 22.3193 180.172 21.5942C180.999 20.8691 181.545 19.8754 181.714 18.7872H182.667C181.995 22.0909 179.771 23.631 176.876 23.6669Z;
                M -3.1238 23.6669 H -3.2311 C -4.0931 23.6709 -4.9504 23.5386 -5.7713 23.275 C -6.6205 22.9822 -7.419 22.5585 -8.1381 22.0192 C -8.9085 21.4046 -9.5304 20.6234 -9.9573 19.7339 C -10.463 18.641 -10.7044 17.4438 -10.6618 16.2397 C -10.6656 15.2135 -10.4605 14.1973 -10.0591 13.2534 C -9.6637 12.3546 -9.1035 11.5382 -8.4078 10.8466 C -7.7132 10.1529 -6.8831 9.6107 -5.9695 9.2541 C -4.4535 8.6299 -2.7802 8.4996 -1.1863 8.8815 C -0.5882 9.0191 -0.0121 9.2392 0.5256 9.5356 C 1.0307 9.8343 1.4661 10.238 1.8025 10.7197 C 2.1536 11.2303 2.3365 11.8383 2.3254 12.4585 C 2.3307 12.6769 2.2928 12.8943 2.2139 13.098 C 2.135 13.3017 2.0167 13.4877 1.8658 13.6453 C 1.5075 13.9651 1.0376 14.1299 0.5586 14.1035 C 0.1785 14.1 -0.1966 14.0163 -0.5423 13.8578 C -0.7155 13.7818 -0.8613 13.6541 -0.9597 13.4922 C -1.0582 13.3302 -1.1046 13.1418 -1.0927 12.9525 C -1.0824 12.5649 -1.0051 12.182 -0.8643 11.8209 C -0.7371 11.4854 -0.6602 11.133 -0.6358 10.7749 C -0.6374 10.5461 -0.7046 10.3226 -0.8296 10.1312 C -0.9546 9.9398 -1.1319 9.7886 -1.3404 9.6957 C -1.8578 9.4246 -2.4357 9.2906 -3.0192 9.3066 C -3.476 9.3066 -4.8906 9.3783 -5.8263 10.3498 C -6.2502 10.8108 -6.5832 11.5201 -6.8501 12.4585 C -7.1534 13.7278 -7.2894 15.0315 -7.2547 16.3363 C -7.2613 17.2126 -7.2024 18.0881 -7.0786 18.9556 C -6.9782 19.6928 -6.7637 20.4099 -6.4428 21.0808 V 21.0973 C -5.7383 22.5491 -3.8668 22.7533 -2.8073 22.7533 C -1.7092 22.7296 -0.6545 22.3193 0.1721 21.5942 C 0.9988 20.8691 1.5448 19.8754 1.7145 18.7872 H 2.6667 C 1.9952 22.0909 -0.2285 23.631 -3.1238 23.6669 Z;
                M 0.8762 23.6669 H 0.7689 C -0.0931 23.6709 -0.9504 23.5386 -1.7713 23.275 C -2.6205 22.9822 -3.419 22.5585 -4.1381 22.0192 C -4.9085 21.4046 -5.5304 20.6234 -5.9573 19.7339 C -6.463 18.641 -6.7044 17.4438 -6.6618 16.2397 C -6.6656 15.2135 -6.4605 14.1973 -6.0591 13.2534 C -5.6637 12.3546 -5.1035 11.5382 -4.4078 10.8466 C -3.7132 10.1529 -2.8831 9.6107 -1.9695 9.2541 C -0.4535 8.6299 1.2198 8.4996 2.8137 8.8815 C 3.4118 9.0191 3.9879 9.2392 4.5256 9.5356 C 5.0307 9.8343 5.4661 10.238 5.8025 10.7197 C 6.1536 11.2303 6.3365 11.8383 6.3254 12.4585 C 6.3307 12.6769 6.2928 12.8943 6.2139 13.098 C 6.135 13.3017 6.0167 13.4877 5.8658 13.6453 C 5.5075 13.9651 5.0376 14.1299 4.5586 14.1035 C 4.1785 14.1 3.8034 14.0163 3.4577 13.8578 C 3.2845 13.7818 3.1387 13.6541 3.0403 13.4922 C 2.9418 13.3302 2.8954 13.1418 2.9073 12.9525 C 2.9176 12.5649 2.9949 12.182 3.1357 11.8209 C 3.2629 11.4854 3.3398 11.133 3.3642 10.7749 C 3.3626 10.5461 3.2954 10.3226 3.1704 10.1312 C 3.0454 9.9398 2.8681 9.7886 2.6596 9.6957 C 2.1422 9.4246 1.5643 9.2906 0.9808 9.3066 C 0.524 9.3066 -0.8906 9.3783 -1.8263 10.3498 C -2.2502 10.8108 -2.5832 11.5201 -2.8501 12.4585 C -3.1534 13.7278 -3.2894 15.0315 -3.2547 16.3363 C -3.2613 17.2126 -3.2024 18.0881 -3.0786 18.9556 C -2.9782 19.6928 -2.7637 20.4099 -2.4428 21.0808 V 21.0973 C -1.7383 22.5491 0.1332 22.7533 1.1927 22.7533 C 2.2908 22.7296 3.3455 22.3193 4.1721 21.5942 C 4.9988 20.8691 5.5448 19.8754 5.7145 18.7872 H 6.6667 C 5.9952 22.0909 3.7715 23.631 0.8762 23.6669 Z;
                M 4.8762 23.6669 H 4.7689 C 3.9069 23.6709 3.0496 23.5386 2.2287 23.275 C 1.3795 22.9822 0.581 22.5585 -0.1381 22.0192 C -0.9085 21.4046 -1.5304 20.6234 -1.9573 19.7339 C -2.463 18.641 -2.7044 17.4438 -2.6618 16.2397 C -2.6656 15.2135 -2.4605 14.1973 -2.0591 13.2534 C -1.6637 12.3546 -1.1035 11.5382 -0.4078 10.8466 C 0.2868 10.1529 1.1169 9.6107 2.0305 9.2541 C 3.5465 8.6299 5.2198 8.4996 6.8137 8.8815 C 7.4118 9.0191 7.9879 9.2392 8.5256 9.5356 C 9.0307 9.8343 9.4661 10.238 9.8025 10.7197 C 10.1536 11.2303 10.3365 11.8383 10.3254 12.4585 C 10.3307 12.6769 10.2928 12.8943 10.2139 13.098 C 10.135 13.3017 10.0167 13.4877 9.8658 13.6453 C 9.5075 13.9651 9.0376 14.1299 8.5586 14.1035 C 8.1785 14.1 7.8034 14.0163 7.4577 13.8578 C 7.2845 13.7818 7.1387 13.6541 7.0403 13.4922 C 6.9418 13.3302 6.8954 13.1418 6.9073 12.9525 C 6.9176 12.5649 6.9949 12.182 7.1357 11.8209 C 7.2629 11.4854 7.3398 11.133 7.3642 10.7749 C 7.3626 10.5461 7.2954 10.3226 7.1704 10.1312 C 7.0454 9.9398 6.8681 9.7886 6.6596 9.6957 C 6.1422 9.4246 5.5643 9.2906 4.9808 9.3066 C 4.524 9.3066 3.1094 9.3783 2.1737 10.3498 C 1.7498 10.8108 1.4168 11.5201 1.1499 12.4585 C 0.8466 13.7278 0.7106 15.0315 0.7453 16.3363 C 0.7387 17.2126 0.7976 18.0881 0.9214 18.9556 C 1.0218 19.6928 1.2363 20.4099 1.5572 21.0808 V 21.0973 C 2.2617 22.5491 4.1332 22.7533 5.1927 22.7533 C 6.2908 22.7296 7.3455 22.3193 8.1721 21.5942 C 8.9988 20.8691 9.5448 19.8754 9.7145 18.7872 H 10.6667 C 9.9952 22.0909 7.7715 23.631 4.8762 23.6669 Z;
                M 8.8762 23.6669 H 8.7689 C 7.9069 23.6709 7.0496 23.5386 6.2287 23.275 C 5.3795 22.9822 4.581 22.5585 3.8619 22.0192 C 3.0915 21.4046 2.4696 20.6234 2.0427 19.7339 C 1.537 18.641 1.2956 17.4438 1.3382 16.2397 C 1.3344 15.2135 1.5395 14.1973 1.9409 13.2534 C 2.3363 12.3546 2.8965 11.5382 3.5922 10.8466 C 4.2868 10.1529 5.1169 9.6107 6.0305 9.2541 C 7.5465 8.6299 9.2198 8.4996 10.8137 8.8815 C 11.4118 9.0191 11.9879 9.2392 12.5256 9.5356 C 13.0307 9.8343 13.4661 10.238 13.8025 10.7197 C 14.1536 11.2303 14.3365 11.8383 14.3254 12.4585 C 14.3307 12.6769 14.2928 12.8943 14.2139 13.098 C 14.135 13.3017 14.0167 13.4877 13.8658 13.6453 C 13.5075 13.9651 13.0376 14.1299 12.5586 14.1035 C 12.1785 14.1 11.8034 14.0163 11.4577 13.8578 C 11.2845 13.7818 11.1387 13.6541 11.0403 13.4922 C 10.9418 13.3302 10.8954 13.1418 10.9073 12.9525 C 10.9176 12.5649 10.9949 12.182 11.1357 11.8209 C 11.2629 11.4854 11.3398 11.133 11.3642 10.7749 C 11.3626 10.5461 11.2954 10.3226 11.1704 10.1312 C 11.0454 9.9398 10.8681 9.7886 10.6596 9.6957 C 10.1422 9.4246 9.5643 9.2906 8.9808 9.3066 C 8.524 9.3066 7.1094 9.3783 6.1737 10.3498 C 5.7498 10.8108 5.4168 11.5201 5.1499 12.4585 C 4.8466 13.7278 4.7106 15.0315 4.7453 16.3363 C 4.7387 17.2126 4.7976 18.0881 4.9214 18.9556 C 5.0218 19.6928 5.2363 20.4099 5.5572 21.0808 V 21.0973 C 6.2617 22.5491 8.1332 22.7533 9.1927 22.7533 C 10.2908 22.7296 11.3455 22.3193 12.1721 21.5942 C 12.9988 20.8691 13.5448 19.8754 13.7145 18.7872 H 14.6667 C 13.9952 22.0909 11.7715 23.631 8.8762 23.6669 Z;
                M 14.8762 23.6669 H 14.7689 C 13.9069 23.6709 13.0496 23.5386 12.2287 23.275 C 11.3795 22.9822 10.581 22.5585 9.8619 22.0192 C 9.0915 21.4046 8.4696 20.6234 8.0427 19.7339 C 7.537 18.641 7.2956 17.4438 7.3382 16.2397 C 7.3344 15.2135 7.5395 14.1973 7.9409 13.2534 C 8.3363 12.3546 8.8965 11.5382 9.5922 10.8466 C 10.2868 10.1529 11.1169 9.6107 12.0305 9.2541 C 13.5465 8.6299 15.2198 8.4996 16.8137 8.8815 C 17.4118 9.0191 17.9879 9.2392 18.5256 9.5356 C 19.0307 9.8343 19.4661 10.238 19.8025 10.7197 C 20.1536 11.2303 20.3365 11.8383 20.3254 12.4585 C 20.3307 12.6769 20.2928 12.8943 20.2139 13.098 C 20.135 13.3017 20.0167 13.4877 19.8658 13.6453 C 19.5075 13.9651 19.0376 14.1299 18.5586 14.1035 C 18.1785 14.1 17.8034 14.0163 17.4577 13.8578 C 17.2845 13.7818 17.1387 13.6541 17.0403 13.4922 C 16.9418 13.3302 16.8954 13.1418 16.9073 12.9525 C 16.9176 12.5649 16.9949 12.182 17.1357 11.8209 C 17.2629 11.4854 17.3398 11.133 17.3642 10.7749 C 17.3626 10.5461 17.2954 10.3226 17.1704 10.1312 C 17.0454 9.9398 16.8681 9.7886 16.6596 9.6957 C 16.1422 9.4246 15.5643 9.2906 14.9808 9.3066 C 14.524 9.3066 13.1094 9.3783 12.1737 10.3498 C 11.7498 10.8108 11.4168 11.5201 11.1499 12.4585 C 10.8466 13.7278 10.7106 15.0315 10.7453 16.3363 C 10.7387 17.2126 10.7976 18.0881 10.9214 18.9556 C 11.0218 19.6928 11.2363 20.4099 11.5572 21.0808 V 21.0973 C 12.2617 22.5491 14.1332 22.7533 15.1927 22.7533 C 16.2908 22.7296 17.3455 22.3193 18.1721 21.5942 C 18.9988 20.8691 19.5448 19.8754 19.7145 18.7872 H 20.6667 C 19.9952 22.0909 17.7715 23.631 14.8762 23.6669 Z;
                M16.8762 23.6669H16.7689C15.9069 23.6709 15.0496 23.5386 14.2287 23.275C13.3795 22.9822 12.581 22.5585 11.8619 22.0192C11.0915 21.4046 10.4696 20.6234 10.0427 19.7339C9.53699 18.641 9.2956 17.4438 9.33817 16.2397C9.33443 15.2135 9.53951 14.1973 9.94089 13.2534C10.3363 12.3546 10.8965 11.5382 11.5922 10.8466C12.2868 10.1529 13.1169 9.61067 14.0305 9.25412C15.5465 8.62994 17.2198 8.49959 18.8137 8.88152C19.4118 9.0191 19.9879 9.23924 20.5256 9.53564C21.0307 9.83429 21.4661 10.238 21.8025 10.7197C22.1536 11.2303 22.3365 11.8383 22.3254 12.4585C22.3307 12.6769 22.2928 12.8943 22.2139 13.098C22.135 13.3017 22.0167 13.4877 21.8658 13.6453C21.5075 13.9651 21.0376 14.1299 20.5586 14.1035C20.1785 14.1 19.8034 14.0163 19.4577 13.8578C19.2845 13.7818 19.1387 13.6541 19.0403 13.4922C18.9418 13.3302 18.8954 13.1418 18.9073 12.9525C18.9176 12.5649 18.9949 12.182 19.1357 11.8209C19.2629 11.4854 19.3398 11.133 19.3642 10.7749C19.3626 10.5461 19.2954 10.3226 19.1704 10.1312C19.0454 9.93982 18.8681 9.78861 18.6596 9.69572C18.1422 9.42459 17.5643 9.29063 16.9808 9.30656C16.524 9.30656 15.1094 9.37832 14.1737 10.3498C13.7498 10.8108 13.4168 11.5201 13.1499 12.4585C12.8466 13.7278 12.7106 15.0315 12.7453 16.3363C12.7387 17.2126 12.7976 18.0881 12.9214 18.9556C13.0218 19.6928 13.2363 20.4099 13.5572 21.0808V21.0973C14.2617 22.5491 16.1332 22.7533 17.1927 22.7533C18.2908 22.7296 19.3455 22.3193 20.1721 21.5942C20.9988 20.8691 21.5448 19.8754 21.7145 18.7872H22.6667C21.9952 22.0909 19.7715 23.631 16.8762 23.6669Z"
                dur="4.5s"
                begin="0s"
                repeatCount="indefinite"
              />
            </path>
            <animate
              attributeName="visibility"
              values="visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;
                visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;
                visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;
                visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;hidden;hidden;hidden;hidden;
                visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible;visible"
              keyTimes="0;0.1;0.1;0.1;0.1;0.1;0.1;0.1;0.1;0.1;0.2;0.2;0.2;0.2;0.2;0.2;0.2;0.2;0.2;0.2;0.3;0.3;0.3;0.3;0.3;0.3;0.3;0.3;0.3;0.3;0.4;0.4;0.4;0.4;0.4;0.4;0.4;0.4;0.4;0.4;
                0.5;0.5;0.5;0.5;0.5;0.5;0.5;0.5;0.5;0.5;0.6;0.6;0.6;0.6;0.6;0.6;0.6;0.6;0.6;0.6;0.7;0.7;0.7;0.7;0.7;0.7;0.7;0.7;0.7;0.7;0.8;0.8;0.8;0.8;0.8;0.875;
                0.88636;0.89773;0.90909;0.92045;0.93182;0.9432;0.95455;0.96591;0.97727;0.98864;0.99999;1"
              dur="4.5s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
        </StyledLinearFlowContainer>
      )
    case 'z-flow':
      return (
        <StyledTransitionContainer>
          <svg
            width={size / 24}
            height={size / 24}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="6" cy="6" r="6" fill={chooseColor()} />
            <animate
              attributeName="visibility"
              values={frameByFrame(1, 8)}
              dur="0.8s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 12}
            height={size / 12}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="12" cy="12" r="12" fill={chooseColor()} />
            <animate
              attributeName="visibility"
              values={frameByFrame(2, 8)}
              dur="0.8s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 4}
            height={size / 4}
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="36" cy="36" r="36" fill={chooseColor()} fill-opacity="0.8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(3, 8)}
              dur="0.8s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 2}
            height={size / 2}
            viewBox="0 0 144 144"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="72" cy="72" r="72" fill={chooseColor()} fill-opacity="0.4" />
            <animate
              attributeName="visibility"
              values={frameByFrame(4, 8)}
              dur="0.8s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size}
            height={size}
            viewBox="0 0 288 288"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="144" cy="144" r="144" fill={chooseColor()} fill-opacity="0.1" />
            <animate
              attributeName="visibility"
              values={frameByFrame(5, 8)}
              dur="0.8s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 2}
            height={size / 2}
            viewBox="0 0 144 144"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="72" cy="72" r="72" fill={chooseColor()} fill-opacity="0.4" />
            <animate
              attributeName="visibility"
              values={frameByFrame(6, 8)}
              dur="0.8s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 4}
            height={size / 4}
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="36" cy="36" r="36" fill={chooseColor()} fill-opacity="0.8" />
            <animate
              attributeName="visibility"
              values={frameByFrame(7, 8)}
              dur="0.8s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
          <svg
            width={size / 12}
            height={size / 12}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute' }}
          >
            <circle cx="12" cy="12" r="12" fill={chooseColor()} />
            <animate
              attributeName="visibility"
              values={frameByFrame(8, 8)}
              dur="0.8s"
              begin="0s"
              repeatCount="indefinite"
            />
          </svg>
        </StyledTransitionContainer>
      )
    default:
      return null
  }
}

export default Transition
