import styled from '@mui/material/styles/styled'

export const StyledStorybookTransitionContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(10),
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  gap: 1,
}))

export const StyledTransitionContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  gap: 2,
}))

export const StyledLinearFlowContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  gap: 2,
}))
