import { Button as MuiButton, buttonClasses as MuiButtonClasses, styled } from '@mui/material'

export const StyledButton = styled(MuiButton)(({ theme }) => ({
  [`&.${MuiButtonClasses.root}`]: {
    borderRadius: 8,
  },

  //  Disabled
  [`&.${MuiButtonClasses.disabled}`]: {
    color: theme.palette.neutral.tones[60],
  },

  //  Medium size
  [`&.${MuiButtonClasses.sizeMedium}`]: {
    padding: theme.spacing(4, 8),
  },

  //  Large size (set the same as medium since the DS doesn't have a large size)
  [`&.${MuiButtonClasses.sizeLarge}`]: {
    padding: theme.spacing(6, 12),
  },

  //  Small size
  [`&.${MuiButtonClasses.sizeSmall}`]: {
    padding: theme.spacing(3, 8),
  },

  [`&.${MuiButtonClasses.containedPrimary}`]: {
    '&:hover': {
      backgroundColor: theme.palette.primary.tones[50],
    },
    [`&.${MuiButtonClasses.outlined}`]: {
      border: `1px solid ${theme.palette.neutral.tones[60]}`,
      '&:hover': {
        backgroundColor: theme.palette.primary.tones[95],
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.tones[90],
      },
      '&:disabled': {
        border: `1px solid ${theme.palette.neutral.tones[90]}`,
      },
    },
  },

  //  Text Variant
  [`&.${MuiButtonClasses.text}`]: {
    '&:hover': {
      backgroundColor: theme.palette.primary.tones[95],
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.tones[90],
    },
    '&:disabled': {
      backgroundColor: theme.palette.background[3],
      color: theme.palette.neutral.tones[60],
    },
  },

  ['&.MuiButton-containedSuccess']: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,

    '&:hover': {
      backgroundColor: theme.palette.success.tones[70],
    },
    '&:disabled': {
      color: theme.palette.neutral.tones[60],
      backgroundColor: theme.palette.background[3],
    },

    //  Outlined Success Variant
    ['&.MuiButton-outlinedSuccess']: {
      border: `1px solid ${theme.palette.neutral.tones[60]}`,
      color: theme.palette.success.tones[40],
      '&:hover': {
        backgroundColor: theme.palette.success.tones[99],
      },
      '&:focus': {
        borderColor: theme.palette.success.main,
      },
      '&:disabled': {
        color: theme.palette.neutral.tones[60],
        borderColor: theme.palette.neutral.tones[90],
      },
    },

    //  Text Variant
    ['&.MuiButton-textSuccess']: {
      color: theme.palette.success.tones[40],
      '&:hover': {
        backgroundColor: theme.palette.success.tones[99],
      },
    },
  },

  ['&.MuiButton-containedSecondary']: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,

    '&:hover': {
      backgroundColor: theme.palette.success.tones[70],
    },
    '&:disabled': {
      color: theme.palette.neutral.tones[60],
      backgroundColor: theme.palette.background[3],
    },
  },

  //  Outlined Variant
  [`&.${MuiButtonClasses.outlined}`]: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.neutral.tones[60]}`,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.tones[95],
      color: theme.palette.neutral.main,

      a: {
        color: theme.palette.neutral.main,
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.tones[90],
      color: theme.palette.neutral.main,

      a: {
        color: theme.palette.neutral.main,
      },
    },
    '&:disabled': {
      border: `1px solid ${theme.palette.neutral.tones[90]}`,
    },
  },
}))

export const StyledChildrenWrapper = styled('div', {
  name: 'ChildrenWrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4),
}))
