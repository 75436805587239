import config from '@configs/index'
import { useSite } from '@foundation/hooks/useSite'
import { Attachment, ProductImageUsage } from '@typesApp/product'
import dynamic from 'next/dynamic'
import { ParsedQuery } from 'query-string'
import React, { MouseEvent, ReactEventHandler, TouchEvent, useEffect, useRef, useState } from 'react'
import { Image } from './Image'
import { addAkamaiParameters, generateProductImagePath, generateSrcSet, isVideoURL } from './helpers'

const VideoPlayer = dynamic(
  () => import('@components/Cms/CmsComponents/CmsCommonMedia/VideoPlayer').then(m => m.VideoPlayer),
  { ssr: false }
)

type ProductImage = {
  width?: number
  alt?: string
  attachments?: Attachment[]
  type?: string
  srcSetMap?: Record<number, string>
  sequence?: string
  usage?: ProductImageUsage
  isFramesProduct?: boolean
  isAccessoriesProduct?: boolean
  backgroundColor?: string
  attr?: ParsedQuery<string>
  lazy?: boolean
  preload?: boolean
  prefetch?: boolean
  fetchpriority?: 'auto' | 'high' | 'low'
  onClick?: () => void
  style?: React.CSSProperties
  className?: string
  controls?: boolean
  shouldLoad?: boolean
  onMouseMove?: (e: MouseEvent<HTMLImageElement>) => void
  onTouchMove?: (e: TouchEvent<HTMLImageElement>) => void
  onLoad?: ReactEventHandler<HTMLImageElement>
  crossOrigin?: React.MediaHTMLAttributes<HTMLImageElement>['crossOrigin']
  fallback?: {
    usage: ProductImageUsage
    sequence?: string
  }
}

export const ProductMedia: React.FC<ProductImage> = ({
  width,
  attachments,
  srcSetMap,
  alt,
  usage,
  sequence,
  isFramesProduct,
  isAccessoriesProduct,
  backgroundColor,
  lazy = true,
  attr,
  preload = false,
  prefetch = false,
  fetchpriority = 'auto',
  style,
  className,
  controls,
  shouldLoad,
  onLoad,
  ...rest
}) => {
  const { mySite: site } = useSite()
  const damDomain: string = site?.xStoreCfg?.['damDomain'] || config.defaultDamDomain
  const baseURL = generateProductImagePath(
    damDomain,
    attachments,
    usage,
    sequence,
    isAccessoriesProduct,
    isFramesProduct
  )
  const isVideo = isVideoURL(baseURL)
  const src = addAkamaiParameters(
    baseURL,
    width || Number(config.productImageAkamaiDefaultWidth),
    backgroundColor,
    attr
  )
  const srcset = !isVideo ? generateSrcSet(baseURL, srcSetMap, backgroundColor, attr) : undefined

  const [isVisible, setIsVisible] = useState(false)
  const tileRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    )

    const currentTileRef = tileRef.current

    if (currentTileRef) {
      observer.observe(currentTileRef)
    }

    return () => {
      if (currentTileRef) {
        observer.unobserve(currentTileRef)
      }
    }
  }, [])

  if (isVideo) {
    return (
      <div ref={tileRef} className="media-wrapper is-video">
        <VideoPlayer
          url={`${src}#t=0.1`}
          width="100%"
          height="100%"
          style={{ position: 'relative' }}
          controls={controls}
          playsinline
          shouldLoad={isVisible}
          isInView={true}
          {...rest}
          fallback={undefined}
        />
      </div>
    )
  }

  return (
    <div ref={tileRef} className="media-wrapper">
      <Image
        key={src}
        width={config.productImageRatioWidth ? Number(config.productImageRatioWidth) : undefined}
        height={config.productImageRatioHeight ? Number(config.productImageRatioHeight) : undefined}
        alt={alt}
        src={src}
        srcSet={srcset}
        lazy={!isVisible ? lazy : false}
        preload={isVisible ? true : preload}
        prefetch={isVisible ? true : prefetch}
        fetchPriority={isVisible ? 'high' : fetchpriority}
        style={style}
        className={className}
        shouldLoad={isVisible || shouldLoad}
        onLoad={onLoad}
        srcSetMap={srcSetMap}
        {...rest}
      />
    </div>
  )
}
