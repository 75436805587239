import { gridColumns } from '../../../constants/ui'
import styled from '@mui/material/styles/styled'

// Size: px
export const gutter = {
  xs: 16,
  sm: 32,
  md: 32,
  lg: 32,
  xl: 32,
}
// Size: px
export const subGutter = {
  xs: [16, 40],
  sm: [16, 40],
  md: [16, 50],
  lg: [16, 50],
  xl: [16, 50],
}
// Size: px
const outsideGutter = {
  xs: 16,
  sm: 32,
  md: 64,
  lg: 64,
  xl: 64,
}

export const setCommonStyle = (size, gap, container, subcontainer, noVertPadding = false) => {
  return {
    gridTemplateColumns: `repeat(${gridColumns[size]}, 1fr)`,
    gridColumnGap: (gap !== null && gap) || gap === 0 ? `${gap}px` : `${gutter[size]}px`,
    gridRowGap: (gap !== null && gap) || gap === 0 ? `${gap}px` : `${gutter[size]}px`,
    gridColumn: `span ${gridColumns[size]}`,
    padding: container
      ? `${outsideGutter[size]}px`
      : subcontainer
        ? `${subGutter[size][0]}px ${subGutter[size][0]}px ${subGutter[size][1]}px`
        : 0,
    paddingBottom: container && noVertPadding ? 0 : 'auto',
    paddingTop: container && noVertPadding ? 0 : 'auto',
  }
}

/**
 * Grid container component based on css grid
 * @param {1 | 0} gap param to set gap container
 */

export const GridContainer = styled('div', {
  name: 'GridContainer ',
  shouldForwardProp: prop =>
    prop !== 'subcontainer' && prop !== 'gap' && prop !== 'container' && prop !== 'columns' && prop !== 'noVertPadding',
})<{
  columns?: number
  container?: boolean
  gap?: 0 | 1 | 4 | 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 88 | 96
  noVertPadding?: boolean
  subcontainer?: boolean
}>(({ columns, gap, theme }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns || gridColumns['xs']}, 1fr)`,
  gridTemplateRows: '1fr',
  gridColumnGap: gap === 1 ? '1rem' : 0,
  gridRowGap: gap === 1 ? '1rem' : 0,
  gridColumn: `span ${columns || gridColumns['xs']}`,
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `repeat(${columns || gridColumns['md']}, 1fr)`,
    gridColumn: `span ${columns || gridColumns['md']}`,
    gridColumnGap: gap === 1 ? '1.5rem' : 0,
    gridRowGap: gap === 1 ? '1.5rem' : 0,
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: `repeat(${columns || gridColumns['lg']}, 1fr)`,
    gridColumn: `span ${columns || gridColumns['lg']}`,
    gridColumnGap: gap === 1 ? '2rem' : 0,
    gridRowGap: gap === 1 ? '2rem' : 0,
  },
  width: '100%',
}))

export default GridContainer
