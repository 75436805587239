import IconButtonMaterial from '@mui/material/IconButton'
import styled from '@mui/material/styles/styled'

export const IconButton = styled(IconButtonMaterial)({
  padding: 0,
  pointerEvents: 'auto',
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

export const IconLoaderWrapper = styled('div')({
  padding: 0,
})
